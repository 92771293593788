import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../components/Table';
import Loading from '../../../components/Loading';
import './index.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    ORDER_HISTORY_LOG_COLUMN,
    ACTION_TYPE_LOG,
    INVOICE_HISTORY_LOG_COLUMN
} from '../../../utils/constant';
import { fetchHistoryLog, setPageSize } from '../../../store/historyLogSlice';
import { fetchUnits } from "../../../store/unitSlice";
import { fetchInvoiceCode } from "../../../store/invoiceSlice";
import { fetchAllOrder } from "../../../store/ordersSlice";
import moment from "moment";
import Paginate from '../../../components/Paginate';
import { Helmet } from 'react-helmet';
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import Select, { components } from "react-select";
import FaAngleDown from "@mui/icons-material/ArrowDropDown";
import { getAllUsers } from '../../../store/teamSlice';
import Button from "../../../components/Buttons/BaseButton";
import ModalRevenue from '../../../components/ModalRevenue';
import ModalInvoiceChange from '../../../components/ModalInvoiceChange';

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <FaAngleDown />
        </components.DropdownIndicator>
    );
};

function OrderLogs() {
    const { t } = useTranslation();
    const firstPage = 1;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [selectedTab, setSelectedTab] = useState(useSelector((state) => state.historyLog.selectedTab));
    const [searchText, setSearchText] = useState(null);
    const error = useSelector((state) => state.historyLog.error)

    const historyLogs = useSelector((state) => state.historyLog.historyLogs);
    const [loading, setLoading] = useState(true);
    const page = useSelector((state) => state.historyLog.page);
    const searchOption = useSelector((state) => state.historyLog.search_option);
    const total = useSelector((state) => state.historyLog.total);
    const from = useSelector((state) => state.historyLog.from);
    const to = useSelector((state) => state.historyLog.to);
    const pageSize = useSelector((state) => state.historyLog.limit);
    const [limit, setLimit] = useState(pageSize);
    const [searchDone, setSearchDone] = useState(false);
    const [searchOrderText, setSearchOrderText] = useState([]);
    const [listAction, setListAction] = useState([]);
    const [startCreate, setStartCreate] = useState(null);
    const [endCreate, setEndCreate] = useState(null);
    const [isFirstLoad, setIsFirstLoad] = useState(false);
    const [listSelects, setListSelects] = useState([]);
    const [listSaler, setListSaler] = useState([]);
    const [optionSaler, setOptionSaler] = useState([]);
    const [listUnit, setListUnit] = useState([]);
    const unitsOriginal = useSelector((state) => state.units.data);
    const [isShowModalRevenue, setIsShowModalRevenue] = useState(false);
    const [dataDetailRevenue, setDataDetailRevenue] = useState({});
    const [searchInvoiceText, setSearchInvoiceText] = useState([]);
    const [listInvoiceCode, setListInvoiceCode] = useState([]);
    const [listOrder, setListOrder] = useState([]);
    const [isShowModalInvoice, setIsShowModalInvoice] = useState(false);
    const [dataDetailInvoice, setDataDetailInvoice] = useState({});
    const menuPortalTarget = document.body;
    const customStyles = {
        menuPortal: (base) => ({ ...base, zIndex: 99999 }),
        option: (style) => ({ ...style, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflowX: 'hidden' }),
        control: base => ({
            ...base,
            height: 38,
            minHeight: 38,
        }),
        indicatorSeparator: (base, state) => ({
            ...base,
            marginTop: '6px',
            marginBottom: '10px'
        }),
        valueContainer: (base, state) => ({
            ...base,
            marginTop: '-2px',
            marginBottom: '0px'
        }),
    };
    const rangeContact = {
        "Today Only": [moment(), moment()],
        "Yesterday Only": [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last Week Only": [
            moment().subtract(1, "weeks").startOf("isoWeek"),
            moment().subtract(1, "weeks").endOf("isoWeek"),
        ],
        "This Week Only": [moment().startOf("isoWeek"), moment().endOf("isoWeek")],
        "Last Month Only": [
            moment().subtract(1, "months").startOf("month"),
            moment().subtract(1, "months").endOf("month"),
        ],
        "This Month Only": [moment().startOf("month"), moment().endOf("month")],
    };

    const [currentPage, setCurrentPage] = useState(page);

    const handlePageClick = (page) => {
        setCurrentPage(page);
        navigate(`?page=${page}`);
        setLoading(true);
        dispatch(fetchHistoryLog(page, selectedTab))
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };


    useEffect(() => {
        if (searchOption?.text.length != 0) {
            setSearchText(...searchOption?.text);
        }
        setSearchDone(true);
        setIsFirstLoad(true);
        setCurrentPage(page);
        navigate(`?page=${page}`);
        dispatch(fetchHistoryLog(page, selectedTab))
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
        dispatch(getAllUsers()).then((response) => {
            let listUser = response.payload.data;
            setOptionSaler([
                ...listUser.map((user) => ({
                    value: user.id,
                    label: user.email.substring(0, user.email.indexOf('@')),
                })),
            ])
            setLoading(false)
        })
            .catch(() => setLoading(false));
        dispatch(fetchUnits());

        dispatch(fetchInvoiceCode()).then((response) => {
            let listInvoice = response.payload.data;
            setListInvoiceCode([
                ...listInvoice.map((invoice) => ({
                    value: invoice.id,
                    label: invoice.invoice_code,
                })),
            ])
            setLoading(false)
        })
            .catch(() => setLoading(false));
        dispatch(fetchAllOrder()).then((response) => {
            let listOrder = response.payload.data;
            setListOrder([
                ...listOrder.map((order) => ({
                    value: order.id,
                    label: order.name,
                })),
            ])
            setLoading(false)
        })
            .catch(() => setLoading(false));
    }, [dispatch]);

    useEffect(() => {
        setData(historyLogs);
        setListSelects(ACTION_TYPE_LOG);
    }, [historyLogs]);

    const handleTabChange = (tab) => {
        setSelectedTab(tab);
        setSearchInvoiceText([]);
        setSearchOrderText([]);
        setStartCreate(null);
        setEndCreate(null);
        setListAction([]);
        setListSaler([]);
        setListUnit([]);
        dispatch(fetchHistoryLog(1, tab))
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
        setCurrentPage(firstPage);
        navigate(`?page=${firstPage}`);
    };

    const pluck = (arr, key) => arr.map(i => i[key]);
    const handleSearch = (tab) => {
        let optionSearch = {
            'invoice_id': pluck(searchInvoiceText, 'value'),
            'order_id': pluck(searchOrderText, 'value'),
            'start_date': startCreate,
            'end_date': endCreate,
            'action_type': pluck(listAction, 'value'),
            'sale': pluck(listSaler, 'value'),
            'unit': pluck(listUnit, 'value')
        };

        setLoading(true);
        dispatch(fetchHistoryLog(1, tab, optionSearch))
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
        setCurrentPage(firstPage);
        navigate(`?page=${firstPage}`);
    };


    const applyCallbackCreateDate = (startDate, endDate) => {
        setStartCreate(startDate);
        setEndCreate(endDate);
    };


    useEffect(() => {
        if (searchDone) {
            setLoading(true);
            if (isFirstLoad) {
                setIsFirstLoad(false);
            } else {
                setCurrentPage(firstPage);
                navigate(`?page=${firstPage}`);
                dispatch(fetchHistoryLog(1, selectedTab))
                    .then(() => setLoading(false))
                    .catch(() => setLoading(false));
            }
        }
    }, [searchText, searchDone]);
    const parseMonth = (month) => {
        const [monthNumber, year] = month.split('-');
        return new Date(year, monthNumber - 1);
    };
    const handleOpenRevenueModal = (row) => {
        if (!row["old_value"]?.revenue_distributions || !row["new_value"]?.revenue_distributions) return;
        setIsShowModalRevenue(true);
        // Pluck months from old and new revenue data
        const oldMonth = pluck(row["old_value"]?.revenue_distributions, 'month');
        const newMonth = pluck(row["new_value"]?.revenue_distributions, 'month');

        // Combine and remove duplicates using Set
        const uniqueMonths = [...new Set([...oldMonth, ...newMonth])];

        // Sort months chronologically
        const sortedMonths = uniqueMonths.sort((a, b) => {
            return parseMonth(a) - parseMonth(b);
        });
        const data = {
            old_revenue: row["old_value"]?.revenue_distributions,
            new_revenue: row["new_value"]?.revenue_distributions,
            old_total: row["old_value"]?.total_revenue,
            new_total: row["new_value"]?.total_revenue,
            months: sortedMonths,
            time: row.created_at,
            order_name: row["new_value"].name,
        }
        setDataDetailRevenue(data);
    }
    const handleOpenInvoiceModal = (row) => {
        if (!row) return;
        setIsShowModalInvoice(true);

        setDataDetailInvoice(row);
    }
    const [columns, setColumns] = useState(ORDER_HISTORY_LOG_COLUMN);
    const [columnInvoice, setColumnInvoice] = useState(INVOICE_HISTORY_LOG_COLUMN);

    return (
        <div className="tab-container container-order_log">
            <Helmet>
                <title>{t("history_log")}</title>
                <meta name="description" content="Description of History log Page" />
            </Helmet>
            {loading && <Loading />}
            <div className="d-flex" style={{ position: "relative" }}>
                <p className="bread-crumb">
                    {t("history_log")}
                </p>
            </div>

            <div className="header-items">
                <div className="tabs tab tab_log">
                    <div
                        className={`${selectedTab === "order" ? "selected" : ""}`}
                        onClick={() => handleTabChange("order")}
                    >
                        {t('order')}
                    </div>
                    <div
                        className={`tab-unsassign-contact ${selectedTab === "invoice" ? "selected" : ""
                            }`}
                        onClick={() => handleTabChange("invoice")}
                    >
                        {t('invoice')}
                    </div>

                </div>
            </div>
            <div className='search-log'>
                <div className="tab-container">
                    <div className="d-flex" style={{ width: "100%", display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "16px" }}>
                        {selectedTab === "invoice" && (
                            <div className="input-body">
                                <div className="form-group">
                                    <label>Invoice code/name</label>
                                    <Select
                                        value={searchInvoiceText}
                                        onChange={(value) => {
                                            setSearchInvoiceText(value)
                                        }}
                                        options={listInvoiceCode}
                                        components={{ DropdownIndicator }}
                                        placeholder={"Select invoice code"}
                                        styles={customStyles}
                                        menuPortalTarget={menuPortalTarget}
                                        isSearchable={true}
                                        isMulti={true}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="input-body">
                            <div className="form-group">
                                <label>Order</label>
                                <Select
                                    value={searchOrderText}
                                    onChange={(value) => {
                                        setSearchOrderText(value)
                                    }}
                                    options={listOrder}
                                    components={{ DropdownIndicator }}
                                    placeholder={"Select invoice code"}
                                    styles={customStyles}
                                    menuPortalTarget={menuPortalTarget}
                                    isSearchable={true}
                                    isMulti={true}
                                />
                            </div>
                        </div>
                        <div className='input-body'>
                            <div className="form-group">
                                <label>Time</label>
                                <DateTimeRangeContainer
                                    ranges={rangeContact}
                                    start={startCreate ?? moment()}
                                    end={endCreate ?? moment()}
                                    local={{
                                        format: "YYYY/MM/DD",
                                        sundayFirst: false,
                                    }}
                                    applyCallback={applyCallbackCreateDate}
                                >
                                    <input
                                        value={
                                            (startCreate != null && endCreate != null) ? startCreate?.format("YYYY/MM/DD") + " ~ " + endCreate?.format("YYYY/MM/DD") : null
                                        }
                                        className='input-search'
                                    />

                                </DateTimeRangeContainer>
                            </div>
                        </div>
                        <div className="input-body">
                            <div className="form-group">
                                <label>Action type</label>
                                <Select
                                    value={listAction}
                                    onChange={(value) => {
                                        setListAction(value)
                                    }}
                                    options={listSelects}
                                    components={{ DropdownIndicator }}
                                    placeholder={"Select action type"}
                                    styles={customStyles}
                                    menuPortalTarget={menuPortalTarget}
                                    isSearchable={true}
                                    isMulti={true}
                                />
                            </div>
                        </div>
                        <div className="input-body">
                            <div className="form-group">
                                <label>Sale</label>
                                <Select
                                    value={listSaler}
                                    onChange={(selectedOption) => {
                                        setListSaler(selectedOption)
                                    }}
                                    options={optionSaler}
                                    components={{ DropdownIndicator }}
                                    placeholder={"Select sale"}
                                    styles={customStyles}
                                    menuPortalTarget={menuPortalTarget}
                                    isSearchable={true}
                                    isMulti={true}
                                />
                            </div>
                        </div>
                        <div className="input-body">
                            <div className="form-group">
                                <label>Unit</label>
                                <Select
                                    value={listUnit}
                                    onChange={(value) => {
                                        setListUnit(value)
                                    }}
                                    options={unitsOriginal?.map((unit) => ({
                                        value: unit.id,
                                        label: unit.name,
                                    }))}
                                    components={{ DropdownIndicator }}
                                    placeholder={"Select unit"}
                                    styles={customStyles}
                                    menuPortalTarget={menuPortalTarget}
                                    isSearchable={true}
                                    isMulti={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='button-search'>
                        <Button
                            backgroundColor="#5F89BA"
                            color="#FFFFFF"
                            text={t('search_log')}
                            style={{ marginRight: "10px" }}
                            onClick={() => handleSearch(selectedTab)}
                        />
                    </div>
                </div>
            </div>
            <div className='table-log'>
                {!loading && (
                    <Table
                        data={data}
                        columns={selectedTab === "order" ? columns : columnInvoice}
                        searchTerm={selectedTab}
                        isSticky={true}
                        disableCheckbox={true}
                        handleOpenRevenueModal={handleOpenRevenueModal}
                        handleOpenInvoiceModal={handleOpenInvoiceModal}
                    />
                )}
                {total !== 0 && !loading ? (
                    <Paginate
                        totalRecords={total}
                        from={from}
                        to={to}
                        limit={limit}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        onChangeLimit={(limit) => {
                            setLimit(limit);
                        }}
                        onChangePage={(page) => {
                            handlePageClick(page);
                        }}
                        onSetPageSize={() => {
                            let newPage = page > Math.ceil(total / limit) ? Math.ceil(total / limit) : page;
                            dispatch(setPageSize(limit));
                            handlePageClick(newPage);
                        }}
                    />
                ) : null}
            </div>
            {isShowModalRevenue && (
                <ModalRevenue
                    isOpen={isShowModalRevenue}
                    data={dataDetailRevenue}
                    onClose={() => setIsShowModalRevenue(false)}
                />
            )}
            {isShowModalInvoice && (
                <ModalInvoiceChange
                    isOpen={isShowModalInvoice}
                    data={dataDetailInvoice}
                    onClose={() => setIsShowModalInvoice(false)}
                />
            )}
        </div>
    );
}

export default OrderLogs;