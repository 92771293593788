import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState, useMemo } from "react";
import Loading from "../Loading";
import moment from 'moment';
import { formatNumberWithNoCommas, formatNumberWithCommasAndDot } from '../../utils/helper';
import IncrementIcon from "./../../assets/images/increment.svg";
import DecrementIcon from "./../../assets/images/decrement.svg";

export default function ModalInvoiceChange({ isOpen, onClose, data }) {
    ;
    const [loading, setLoading] = useState(false);
    const [dataInvoice, setDataInvoice] = useState(data);

    // Derived state for changes
    const changes = useMemo(() => {
        const hasData = Object.keys(dataInvoice?.old_value).length !== 0 && Object.keys(dataInvoice?.new_value).length !== 0;
        if (!hasData) return {};

        const oldValue = dataInvoice.old_value;
        const newValue = dataInvoice.new_value;

        const formatDate = (date) => (date ? moment(date).format('YYYY/MM/DD') : '-');

        return {
            invoice_amount_change: oldValue.invoice_amount_after_tax !== newValue.invoice_amount_after_tax,
            received_amount_change: oldValue.received_amount !== newValue.received_amount,
            tax_change: oldValue.tax !== newValue.tax,
            status_change: oldValue.status_id !== newValue.status_id,
            create_date_change: formatDate(oldValue.create_date) !== formatDate(newValue.create_date),
            due_date_change: formatDate(oldValue.due_date) !== formatDate(newValue.due_date),
            payment_date_change: formatDate(oldValue.payment_date) !== formatDate(newValue.payment_date),
        };
    }, [dataInvoice]);

    useEffect(() => {
        setDataInvoice(data);
    }, [data]);

    const getClassForChange = (isChanged, oldValue, newValue) => {
        if (!isChanged) return '';
        return newValue < oldValue ? 'negative' : 'positive';
    };
    const getIconAmount = (classChange, isNumeric) => {
        if (classChange === '' || isNumeric === undefined) return;
        if (classChange === 'positive') {
            return (
                <img src={IncrementIcon} alt="increment" />
            );
        } else {
            return (
                <img src={DecrementIcon} alt="decrement" />
            );
        }
    };
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="scroll-dialog-title" style={{ padding: "0 12px" }}>
                <button
                    onClick={onClose}
                    style={{
                        float: "right",
                        fontSize: "20px",
                        background: "transparent",
                        border: "none",
                    }}
                    aria-label="Close modal"
                >
                    &times;
                </button>
            </DialogTitle>
            <DialogContent dividers style={{ borderTop: "unset", padding: "0 40px 16px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    <p><b>{dataInvoice?.old_value?.invoice_code ?? dataInvoice?.new_value?.invoice_code}</b></p>
                    <span>{moment(dataInvoice?.time).format('DD/MM/YYYY HH:mm')}</span>
                </div>
                <div className="invoice-change">
                    {loading && <Loading />}
                    <p>The <b>updated fields</b> are <span className='underline'>underlined</span></p>
                    <div className="form-group">
                        <table className='table-revenue' style={{ marginBottom: "10px", width: "100%" }}>
                            <thead>
                                <tr>
                                    <td></td>
                                    <td>Before</td>
                                    <td><b>After</b></td>
                                </tr>
                            </thead>
                            <tbody>
                                {[
                                    { label: "Invoice Amount", key: "invoice_amount_after_tax", isNumeric: true },
                                    { label: "Tax", key: "tax", suffix: "%" },
                                    { label: "Received Amount", key: "received_amount", isNumeric: true },
                                    { label: "Status", key: "status.name" },
                                    { label: "Create Date", key: "create_date", isDate: true },
                                    { label: "Due Date", key: "due_date", isDate: true },
                                    { label: "Payment Date", key: "payment_date", isDate: true },
                                ].map(({ label, key, isNumeric, isDate, suffix }) => {
                                    const oldValue = key.split('.').reduce((obj, prop) => obj?.[prop], dataInvoice?.old_value);
                                    const newValue = key.split('.').reduce((obj, prop) => obj?.[prop], dataInvoice?.new_value);
                                    const oldFormatted = isDate
                                        ? ((oldValue && !isNaN(Date.parse(oldValue))) ? moment(oldValue).format('YYYY/MM/DD') : '-')
                                        : (isNumeric && oldValue)
                                            ? formatNumberWithCommasAndDot(formatNumberWithNoCommas(oldValue))
                                            : oldValue ?? '-';

                                    const newFormatted = isDate
                                        ? ((newValue && !isNaN(Date.parse(newValue))) ? moment(newValue).format('YYYY/MM/DD') : '-')
                                        : (isNumeric && newValue)
                                            ? formatNumberWithCommasAndDot(formatNumberWithNoCommas(newValue))
                                            : newValue ?? '-';

                                    const isChanged = changes[`${label.toLowerCase().replace(' ', '_')}_change`];
                                    const classChange = getClassForChange(isChanged, oldValue, newValue);
                                    return (
                                        <tr key={label}>
                                            <td className={isChanged ? 'underline' : ''}>{label}</td>
                                            <td>{oldFormatted}{oldValue && suffix ? suffix : ''}</td>
                                            <td className={classChange}>
                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                    {getIconAmount(classChange, isNumeric)} {newFormatted}{newValue && suffix ? suffix : ''}
                                                </div>

                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </DialogContent>
        </Dialog >
    );
}
