const ja = {
  translation: {
    homepage: 'ホームページ',
    about: '概要',
    login: 'ログイン',
    logout: 'ログアウト',
    dashboard: 'ダッシュボード',
    profile: 'プロフィール',
    english: '英語',
    vietnamese: 'ベトナム語',
    japanese: '日本語',
    contact_menu: '連絡先',
    contact_form: "連絡フォーム",
    sale: 'セールス',
    finance: 'ファイナンス',
    report: 'レポート',
    setting: '設定',
    list: 'リスト',
    deal: '取引',
    task: 'タスク',
    order: '注文 ',
    invoice: '請求書',
    revenue: '収益',
    team: 'チーム',
    target: '目標',
    dashboard_content: {
      personal_info: '個人情報',
      my_task: '私のタスク',
      project_under_negotiation: '交渉中のプロジェクト',
      total: 'トータル'
    },
    name: '名前',
    type: "タイプ",
    result: "結果",
    contact: "連絡",
    deadline: "デッドライン",
    quotation: "見積もり",
    status: "ステータス",
    estimate_cost: "コスト見積もり",
    expect_start_date: "開始予定日",
    create: 'クリエイト',
    import: 'インポート',
    add_list: 'リストに追加する',
    all_contacts: 'すべての連絡先',
    blacklist: 'ブラックリスト',
    my_contact: '私の連絡先',
    unassigned_contacts: '未割り当ての連絡先',
    phone: '電話',
    contact_form_url: 'お問い合わせフォームのURL',
    unit: '単位',
    stage: 'ステージ',
    created_date: '作成日',
    update_date: '更新日',
    last_updated: '最終更新',
    showing: '表示中',
    to: '〜へ',
    of: '総数の中で',
    entry: '項目',
    entries: '項目',
    message: {
      contact_create_success: '連絡先が正常に作成されました',
      contact_edit_success: '連絡先が正常に編集されました',
      contact_import_success: 'C連絡先が正常にインポートされました',
      error_import_contact: '連絡先のインポート中にエラーが発生しました',
      remove_contact_success: '連絡先から正常に削除されました',
      move_blacklist_success: 'ブラックリストに正常に移動しました',
      remove_blacklist_success: 'ブラックリストから正常に削除されました',
      stage_update_success: '連絡先のステージが正常に更新されました',
      error_update_stage: '連絡先のステージの更新中にエラーが発生しました',
      filter_remove_success: 'フィルターが正常に削除されました',
      error_remove_filter: 'フィルターの削除中にエラーが発生しました',
      filter_create_success: 'フィルターが正常に作成されました',
      error_create_filter: 'フィルターの作成中にエラーが発生しました',
      filter_update_success: 'フィルターが正常に更新されました',
      error_update_filter: 'フィルターの更新中にエラーが発生しました',
      add_contact_list_success: '連絡先をリストに正常に追加しました',
      update_pic_success: 'PICを正常に更新しました',
      export_contact_success: '連絡先が正常にエクスポートされました',
      export_contact_failed: '連絡先のエクスポートに失敗しました.'
    },
    action: 'アクション',
    stage_filter: 'ステージ',
    unit_filter: '単位',
    country: '国々',
    city: '都市',
    my_filter: '私のフィルター',
    search: '検索...',
    edit: 'エディット',
    export: 'エクスポート',
    remove_blacklist: 'ブラックリストから削除する',
    update_PIC: 'PICを更新する',
    delete: '削除',
    move_blacklist: 'ブラックリストに移動する',
    export_contact: '連絡先をエクスポートする',
    close: 'クローズ',
    contact_selected: '連絡先が選択されました',
    contacts_selected: '複数の連絡先が選択されました',
    select_all: "すべてのページの連絡先を選択します。合計 {{ contactCount }} 件の連絡先があります",
    confirm_delete_contact: '連絡先削除の確認',
    confirm_move_blacklist: 'ブラックリストに移動する確認',
    confirm_remove_blacklist: 'ブラックリストから削除する確認',
    title_confirm_delete: '削除してもよろしいですか',
    this_contact: 'この連絡先',
    these_contacts: 'これらの連絡先',
    from_blacklist: 'ブラックリストから',
    from_old_list: '古いリストからブラックリストに移動する',
    add_contact_list: '連絡先をリストに追加する',
    add_existing: '既存の追加',
    create_new: '新規作成',
    list_name: 'リスト名',
    source: 'ソース',
    add: '追加する',
    cancel: 'キャンセル',
    select_all_contact: 'すべてのページの連絡先を選択する',
    message_error_list_name: 'リスト名のフィールドは必須です',
    create_contact: '連絡先を作成する',
    address: '住所',
    industry: '業界',
    channel: 'チャネル',
    marketer: 'マーケター',
    saler: 'セールス担当者',
    company_classification: '会社分類',
    company: '会社',
    individual: '個人',
    update: '更新',
    edit_contact: '連絡先を編集する',
    file: 'ファイル',
    button_download: 'サンプルファイルをダウンロードする',
    add_blacklist: 'ブラックリストに追加する',
    size: 'サイズ',
    order_date: '注文日',
    total: '合計',
    order_file: '注文書類',
    estimate_file: '見積もり書類',
    expected_start: '開始予定',
    expected_end: '終了予定',
    get_contact: '連絡先を取得する',
    assign_me: '自分に割り当てる',
    save: 'セーブ',
    input_number: '番号を入力する',
    contact_point: '連絡ポイント',
    expected_revenue: '予想収益',
    all_deal: 'すべての取引',
    my_deal: '私の取引',
    total_expected_revenue: '総予想収益',
    all_task: 'すべてのタスク',
    my_task: '私のタスク',
    all: 'すべて',
    revenue_distribution_needed: '収益分配が必要',
    invoice_creation_needed: '請求書作成が必要',
    order_file_needed: '注文書類が必要',
    estimate_file_needed: '見積もり書類が必要',
    invoice_name: '請求書名',
    order_name: '注文名',
    invoice_amount_pre: '請求書金額（税抜き)',
    tax: '税金',
    invoice_amount_after: '請求書金額（税込み)',
    received_amount: '受領金額',
    currency: '通貨',
    note: '備考',
    payment_date: '支払日',
    due_date: '期日',
    create_date: '作成日',
    total_received: '合計受領額',
    current_month: '今月',
    overdue: '遅延',
    all_invoice: 'すべての請求書',
    payment_status: '支払状況',
    order_revenue: '注文収益',
    revenue_distribution: '収益分配',
    invoice_revenue: '請求書収益',
    last_month: '過去3ヶ月',
    next_month: '次の3ヶ月',
    target_revenue: "目標",
    actual_revenue: "実際の収益",
    actual_target: "実際/目標 (%)",
    revenue_per_contact: "1件あたりの収益",
    expected: "予想",
    distribution: '配布',
    total_revenue: '合計(JPY)',
    export_revenue: '収益を出力する',
    amount_pre_tax: '金額 (税抜き)',
    amount_after_tax: '金額 (税込み)',
    receive_amount: '受領金額',
    title_report: 'レポートを表示するにはリストと日付を選択してください',
    team_name: 'チーム名',
    team_manager: 'チームマネージャー',
    members: 'メンバー',
    company_unit: '会社/部門',
    target_by_unit: "{{ unit }} の目標 ",
    total_first_quater: '第1四半期の合計',
    total_second_quater: '第2四半期の合計',
    total_third_quater: '第3四半期の合計',
    total_fourth_quater: '第4四半期の合計',
    import_modal: 'インポートダイアログ',
    system_field: 'システム項目',
    deal_create_success: "取引が正常に作成されました",
    deal_create_fail: "取引の作成に失敗しました",
    deal_update_success: "取引が正常に更新されました",
    deal_delete_success: "取引が正常に削除されました",
    expected_close_date: '予想終了日',
    edit_deal: '取引を編集する',
    create_deal: '取引を作成する',
    owner: '所有者',
    confirm_delete: '削除確認',
    edit_contact_point: '連絡先を編集する',
    add_contact_point: '連絡先を追加する',
    full_name: "氏名",
    job_position: "職位",
    private: '非公開',
    share_with: '〜と共有する',
    people: '人々',
    add_people: '人々を追加する',
    history_log: '履歴ログ',
    meeting: '会議',
    back_list: 'リストに戻る',
    this_deal: 'この取引',
    these_deal: 'これらの取引',
    notification: 'お知らせ',
    deal_owner: '取引オーナー',
    add_contact: '連絡先を追加する',
    confirm_detach: '切り離し確認',
    title_confirm_detach: 'これを切り離してもよろしいですか',
    forecast_start_date: '予想開始日',
    forecast_end_date: '予想終了日',
    total_price: '総価格',
    expected_start_date: '開始予定日',
    expected_end_date: '開始予定日',
    create_quotation: '見積もりを作成する',
    quotation_info: "見積もり情報",
    sender: "送信者",
    proposal_name: "提案名",
    related_information: "関連情報",
    project_type: "プロジェクトタイプ",
    budget: "予算 (人月)",
    odc_detail: "ODCの詳細",
    project_type_advice: "Tプロジェクトタイプのアドバイス",
    desired_project_type: "希望するプロジェクトタイプ",
    technology: "技術",
    technology_select: "技術の選択",
    technology_advice: "技術アドバイス",
    file_upload: "ファイルアップロード",
    project_profession: "プロジェクト専門",
    sale_channel: "販売チャネル",
    application_type: "アプリケーションタイプ",
    estimation_cost: 'コスト見積もり',
    cost: '費用',
    receive_date: '受領日',
    estimator: '見積者',
    link_proposal: '提案リンク',
    cancel_reason: 'キャンセル理由',
    cost_adjustment_reasons: 'コスト調整の理由',
    create_order: '注文を作成する',
    upload_later: "後でアップロード",
    internal_project: "社内プロジェクト",
    product: "製品",
    exchange_rate_order: "為替レート - 注文日 (VND)",
    exchange_rate_payment: "為替レート - 支払い成功 (VND)",
    item_list: "アイテムリスト",
    add_item: "アイテムを追加する",
    quantity: "数量",
    unit_price: "単価",
    title_add_item: '項目を追加してください',
    title_revenue_distribution: '予想日を選択してください',
    invoice_list: "請求書リスト",
    title_invoice_list: "請求書を追加してください",
    add_invoice: "請求書を追加する",
    regenerate_invoice: "請求書を再生成する",
    confirm_regenerate_invoice: "請求書の再生成を確認する",
    content_confirm_invoice_1: "請求書の再生成は現在のすべての請求書を削除します.",
    content_confirm_invoice_2: "請求書を再生成してもよろしいですか？",
    help: 'サポート',
    order_title_revenue: '(選択した月の注文を非表示にするにはチェックを外してください)',
    deal_note: '取引メモ',
    invoice_code: '請求書コード',
    search_activity: 'アクティビティを検索',
    show_detail: '詳細を表示',
    updated_deal: "${user}が取引${deal}を更新しました.",
    updated_am: "${user}がAMを${from}から${to}に更新しました.",
    updated_saler: "${user}が営業担当を${from}から${to}に更新しました.",
    updated_marketer: " ${user}がマーケターを${from}から${to}に更新しました.",
    contact_updated_information: "${user}が連絡先情報を更新しました.",
    added_task: "${user}がタスク${task}を追加しました.",
    updated_note: "${user}がメモ${note}を更新しました.",
    added_note: "${user}がメモ${note}を追加しました.",
    updated_task: "${user}がタスク${task}を更新しました.",
    contact_updated_stage: "{user}がリスト${list}のステージを${from}から${to}に更新しました.",
    added_contact_to_list: "${user}がこの連絡先をリスト${list}に追加しました",
    contact_created: "${user}がこの連絡先を作成しました.",
    contact_point_created: "${user}がコンタクトポイント${contact_point}を作成しました.",
    added_deal: "${user}が取引${deal}を追加しました.",
    added_existing_contact_points: "${user}が既存のコンタクトポイント${contact_point}を追加しました.",
    deleted_note: "${user}がメモ${note}を削除しました.",
    detached_deal: "${user}が取引${deal}から切り離しました.",
    contact_deleted: "${user}がこの連絡先を削除しました.",
    deleted_deal: "${user}がこの取引を削除しました.",
    deleted_task: "${user}がタスク${task}を削除しました.",
    moved_contact_to_blacklist: "${user}がこの連絡先をブラックリストに移動しました.",
    removed_contact_from_list: "${user}がこの連絡先をリスト${list}から削除しました.",
    removed_contact_from_blacklist: "${user}がこの連絡先をブラックリストから削除しました.",
    task_created: "${user}がこのタスクを作成しました.",
    updated_task_type: "${user}が${field}を${from}から${to}に変更しました.",
    updated_task_description: "${user}がタスクの説明を更新しました.",
    added_task_comment: "${user}がコメントを追加しました.",
    auto_created_task: "システムがこのタスクを作成しました.",
    updated_task_result: "${user}が${field}を${from}から${to}に変更しました.",
    updated_task_name: "${user}がこのタスクを${from}から${to}に${action}しました.",
    detached_contact_points: "${user}がコンタクトポイント${contact_point}を切り離しました.",
    deal_created: "${user}がこの取引を作成しました.",
    added_contact: "${user}が連絡先${contact}を追加しました.",
    added_existing_contact_point_to_deal: "${user}が既存のコンタクトポイント${contact_point}を追加しました.",
    detached_contact_point_from_deal: "${user}がコンタクトポイント${contact_point}を切り離しました.",
    deal_updated_stage: "${user}がステージを${from}から${to}に更新しました.",
    added_items_to_deal: "${user}がリスト項目を追加しました.",
    updated_items_from_deal: "${user}がリスト項目を更新しました.",
    detached_contact: "${user}が連絡先${contact}から切り離しました.",
    added_order: "${user}が注文${order}を作成しました.",
    updated_order: "${user}が注文${order}を更新しました.",
    task_assigned: "${user}がこのタスクを${assigner}に割り当てました.",
    deleted_order: "${user}が注文${order}を削除しました.",
    updated_task_deadline: "${user}が${field}を${from}から${to}に変更しました.",
    task_edit_history: "タスク編集履歴",
    note_edit_history: "メモ編集履歴",
    deal_edit_history: "取引編集履歴",
    contact_edit_history: "連絡先編集履歴",
    order_edit_history: "注文編集履歴",
    list_item_edit_history: "リスト項目編集履歴",
    added_a: "を追加しました",
    meeting_title: "このタスクを完了するには、会議メモを追加する必要があります",
    assigned_to: "担当者",
    meeting_note: "会議ノート",
    quotation_title_1: "タスクの完了を見積もるまでお待ちください",
    quotation_title_2: "が見積もられています",
    add_comment: "コメントを追加",
    no_data: "データが見つかりません",
    title_no_contact_point: "この連絡先には連絡先ポイントがありません",
    add_new_point: "新しい連絡先ポイントを追加",
    edit_contact_form: "連絡先フォームを編集",
    exclude: "除外する",
    list_exclude: "リスト",
    time: '時間',
    actor: 'Actor',
    previous_or: '時刻の前 OR',
    updated_or: '更新しました OR',
    previous_dr: '時刻の前 DR',
    updated_dr: '更新しました DR',
    order_detail_log: '注文詳細',
    invoice_detail_log: '請求書詳細',
    unit_log: '単位',
    action_log: '行動',
    order_log: 'Order',
    order_invoice_log: 'Order',
    inv_amount_before: '請求額（前）',
    inv_amount_after: '請求額（後）',
    receive_amount_before: '受領額（前）',
    receive_amount_after: '受領額（後）',
    search_log: '検索',
    invoice_code_log: '請求書コード',
    note_log: '備考'
  },
};

export default ja;