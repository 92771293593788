/* eslint-disable max-len */
import { createSlice } from '@reduxjs/toolkit';
import { API } from '../utils/api';
import { makeURLSearchParams } from '../utils/helper';
import request from '../services/client';
import moment from "moment";
const initialState = {
    historyLogs: [],
    loading: false,
    page: 1,
    total: 0,
    from: 0,
    to: 0,
    limit: 50,
    last_page: 1,
    search_option: {
        lists: [],
        result: [],
        createDate: [],
        lastUpdated: [],
        text: [],
    },
    selectedTab: 'order',
    error: null,
};

const historyLogSlice = createSlice({
    name: 'historyLog',
    initialState,
    reducers: {
        getContactsStart: (state) => {
            state.loading = true;
        },
        getContactsSuccess: (state, action) => {
            state.loading = false;
            state.page = action.payload.data.current_page;
            state.total = action.payload.data.total;
            state.historyLogs = action.payload.data.data;
            state.from = action.payload.data.from;
            state.to = action.payload.data.to;
            state.last_page = action.payload.data.last_page;
        },
        getContactsFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        deleteOptionSearch: (state, action) => {
            const category = action.payload;

            state.search_option = {
                ...state.search_option,
                [category]: [],
            };
        },
        setLimit: (state, action) => {
            state.limit = action.payload
        },
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload
        },
    },
});

export const {
    getContactsStart,
    getContactsSuccess,
    getContactsFailure,
    deleteOptionSearch,
    setLimit,
    setSelectedTab
} = historyLogSlice.actions;

export const fetchHistoryLog = (page = 1, tab, searchOption) => async (dispatch, getState) => {
    const limit = getState().historyLog.limit;
    try {
        dispatch(getContactsStart());
        const params = {
            page: page,
            flag: tab,
            limit: limit,
        };
        if (searchOption) {

            const { invoice_id, order_id, action_type, start_date, end_date, sale, unit } = searchOption;
            if (order_id != null) {
                params.order_id = order_id.map(item => item).join(',').toLowerCase();
            }
            if (invoice_id?.length > 0) {
                params.invoice_id = invoice_id.map(item => item).join(',').toLowerCase();
            }
            if (action_type?.length > 0) {
                params.action_type = action_type.map(item => item).join(',').toLowerCase();
            }
            if (start_date && start_date != null) {
                params.start_date = moment(start_date).format('YYYY-MM-DD');
            }
            if (start_date && end_date != null) {
                params.end_date = moment(end_date).format('YYYY-MM-DD');
            };
            if (sale?.length > 0) {
                params.sale = sale.map(item => item).join(',').toLowerCase();
            }
            if (unit?.length > 0) {
                params.unit = unit.map(item => item).join(',').toLowerCase();
            }
        }     
        const query = makeURLSearchParams(params);
        const url = `${API.getLogOrder}${query}`;
        const response = await request(url);
        const responseJson = await response.json();
        dispatch(getContactsSuccess(responseJson));
    } catch (error) {
        dispatch(getContactsFailure(error.message));
    }
};

export const removeSearch = (category) => async (dispatch) => {
    try {
        dispatch(deleteOptionSearch(category));
    } catch (error) {
        //
    }
};

export const setPageSize = (data) => async (dispatch) => {
    try {
        dispatch(setLimit(data));
    } catch (error) {
        //
    }
};

export const setSelectTab = (data) => async (dispatch) => {
    try {
        dispatch(setSelectedTab(data));
    } catch (error) {
        //
    }
};

export default historyLogSlice;
