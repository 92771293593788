/* eslint-disable max-len */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "../utils/api";
import request from "../services/client";
import { makeURLSearchParams } from "../utils/helper";
import moment from "moment";

const initialState = {
  orders: [],
  status: [],
  loading: false,
  page: 1,
  total: 0,
  from: 0,
  to: 0,
  total_revenue: [],
  limit: 50,
  last_page: 1,
  search_option: {
    contact: [],
    deal: [],
    units: [],
    pics: [],
    order_date_start: [],
    order_date_end: [],
    text: [],
  },
  filterOrder: 1,
  error: [],
  notification: "",
  order_detail: {},
  selectedTab: 'all',
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    ordersStart: (state) => {
      state.loading = true;
    },
    createOrderStart: (state) => {
      state.loading = true;
    },
    createOrderSuccess: (state, action) => {
      state.loading = false;
      state.error = [];
      state.notification = "An Order is created Successfully!";
    },
    createOrderFailure: (state, action) => {
      state.loading = false;
      state.error = [action.payload];
    },
    updateOrderSuccess: (state, action) => {
      state.loading = false;
      state.error = [];
      state.notification = "An Order is updated Successfully!";
    },
    updateOrderFailure: (state, action) => {
      state.loading = false;
      state.error = [action.payload];
    },
    fetchStatusSuccess: (state, action) => {
      state.loading = false;
      state.status = action.payload.data;
    },
    fetchStatusFailure: (state, action) => {
      state.loading = false;
      state.error = [action.payload];
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    getOrdersSuccess: (state, action) => {
      state.loading = false;
      state.page = action.payload.data.current_page;
      state.total = action.payload.data.total;
      state.orders = action.payload.data.data;
      state.from = action.payload.data.from;
      state.to = action.payload.data.to;
      state.last_page = action.payload.data.last_page;
      state.total_revenue = action.payload.data.total_revenue;
    },
    getOrdersFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateOptionSearch: (state, action) => {
      const data = action.payload;
      const type = data.type;
      if (type === "text") {
        state.search_option = {
          ...state.search_option,
          text: data.option ? [data.option] : [],
        };
        return;
      } else if (type === "contact" || type === "deal") {
        state.search_option = {
          ...state.search_option,
          [type]: state.search_option[type]
            .map((item) => item.id)
            .includes(data.option.id)
            ? state.search_option[type].filter(
              (value) => value.id !== data.option.id
            )
            : [...state.search_option[type], data.option],
        };
        return;
      } else if (type === "units" || type === "pics") {
        state.search_option = {
          ...state.search_option,
          [type]: state.search_option[type].find(
            (item) => item.id === data.option.id
          )
            ? state.search_option[type].filter(
              (value) => value.id !== data.option.id
            )
            : [...state.search_option[type], data.option],
        };
      } else if (type === "orderDate") {
        state.search_option = {
          ...state.search_option,
          [type]: [data.option],
        };
        return;
      } else if (type === "filterOrder") {
        state.filterOrder = data.option;
        return;
      }
    },
    updateSearch: (state, action) => {
      const data = action.payload;
      state.search_option = { ...state.search_option, ...data };
    },
    getDetailOrdersSuccess: (state, action) => {
      state.loading = false;
      state.order_detail = action.payload;
    },
    resetOptionSearch: (state, action) => {
      state.search_option = action.payload;
      state.idFilter = 0;
      state.nameFilter = "";
    },
    deleteOptionSearch: (state, action) => {
      const category = action.payload;
      state.search_option = {
        ...state.search_option,
        [category]: [],
      };
    },
    deleteOptionSearchUnits: (state, action) => {
      const category = action.payload;

      state.search_option = {
        ...state.search_option,
        [category]: [],
      };
      state.isRemoveUnit = true;
    },
    deleteOrderStart: (state) => {
      state.loading = false;
    },
    deleteOrderSuccess: (state, action) => {
      state.loading = false;
      if (typeof action.payload === "object") {
        state.orders = state.orders.filter(
          (item) => !action.payload.includes(item.id)
        );
      } else {
        state.orders = state.orders.filter(
          (item) => item.id !== action.payload
        );
      }
    },
    deleteOrderFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetNotification: (state, action) => {
      state.loading = false;
      state.notification = "";
    },
    exchangeRateStart: (state, action) => {
      state.loading = true;
    },
    exchangeRateDone: (state, action) => {
      state.loading = false;
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    updateOptionSearchUnit: (state, action) => {
      state.search_option = {
        ...state.search_option,
        units: [action.payload.option]
      };
    }
  },
});

export const {
  ordersStart,
  createOrderStart,
  createOrderSuccess,
  createOrderFailure,
  updateOrderSuccess,
  updateOrderFailure,
  updateOptionSearch,
  updateSearch,
  getOrdersSuccess,
  getOrdersFailure,
  resetOptionSearch,
  deleteOptionSearch,
  setLimit,
  deleteOrderStart,
  deleteOrderSuccess,
  deleteOrderFailure,
  resetNotification,
  setSelectedTab,
  updateOptionSearchUnit,
  deleteOptionSearchUnits,
} = ordersSlice.actions;

export const fetchOrders =
  (page = 1, id) =>
    async (dispatch, getState) => {
      const searchOption = getState().orders.search_option;
      const limit = getState().orders.limit;
      // const filterOrder = getState().orders.filterOrder;
      try {
        dispatch(ordersStart());
        const params = {
          page: page,
          limit: limit,
          flag: id ?? 'all',
        };

        if (searchOption) {
          const { contact, deal, units, pics, orderDate, text } = searchOption;
          if (contact.length > 0) {
            params.contact_ids = contact
              .map((item) => item.id)
              .join(",")
              .toLowerCase();
          }
          if (deal.length > 0) {
            params.deal_ids = deal
              .map((deal) => deal.id)
              .join(",")
              .toLowerCase();
          }
          if (units.length > 0) {
            params.unit_ids = units
              .map((unit) => unit.id)
              .join(",")
              .toLowerCase();
          }
          if (pics?.length > 0) {
            params.user = pics
              .map((stage) => stage.id)
              .join(",")
              .toLowerCase();
          }
          if (orderDate?.length > 0) {
            params.order_date_start = orderDate[0].startDate;
            params.order_date_end = orderDate[0].endDate;
          }
          if (text?.length > 0) {
            params.search = text.join(",").toLowerCase();
          }
        }
        const query = makeURLSearchParams(params);
        const url = `${API.listOders}${query}`;
        const response = await request(url);
        const responseJson = await response.json();
        dispatch(getOrdersSuccess(responseJson));
      } catch (error) {
        dispatch(getOrdersFailure(error.message));
      }
    };

export const createOrder = createAsyncThunk(
  "orders/createOrder",
  async ({ order }, { dispatch }) => {
    try {
      const orderFormData = new FormData();
      order["file"] && orderFormData.append("file", order["file"]);
      order["name"] && orderFormData.append("name", order["name"]);
      order["contact"] && orderFormData.append("contact_id", order["contact"]);
      order["currency"] &&
        orderFormData.append("currency_id", order["currency"]);
      order["data_item"] &&
        orderFormData.append("data_item", JSON.stringify(order["data_item"]));
      order["data_revenue"] &&
        orderFormData.append(
          "data_revenue",
          JSON.stringify(order["data_revenue"])
        );
      order["deal"] && orderFormData.append("deal_id", order["deal"]);
      order["status"] && orderFormData.append("status_id", order["status"]);
      order["end_date"] &&
        orderFormData.append("end_date", moment(order["end_date"]));
      order["order_date"] &&
        orderFormData.append("order_date", moment(order["order_date"]));
      order["start_date"] &&
        orderFormData.append("start_date", moment(order["start_date"]));
      order["exchange_rate_order"] &&
        orderFormData.append(
          "exchange_rate_order",
          order["exchange_rate_order"]
        );
      order["exchange_rate_payment"] &&
        orderFormData.append(
          "exchange_rate_payment",
          order["exchange_rate_payment"]
        );
      order["product_id"] &&
        orderFormData.append(
          "product_id",
          order["product_id"]
        );
      const response = await request(`${API.createOrder}`, {
        method: "POST",
        body: orderFormData,
      });

      if (!response.ok) {
        throw new Error(`The file format is not correct for contact data.`);
      }
      const result = await response.json();
      dispatch(createOrderSuccess(result));
      return result;
    } catch (error) {
      throw error;
    }
  }
);

export const editOrder = createAsyncThunk(
  "orders/updateOrder",
  async ({ order, id }, { dispatch }) => {
    try {
      const orderFormData = new FormData();
      id && orderFormData.append("id", id);
      order["file"] && orderFormData.append("file", order["file"]);
      order["estimate_file"] && orderFormData.append("estimate_file", order["estimate_file"]);
      order["name"] && orderFormData.append("name", order["name"]);
      order["contact"] && orderFormData.append("contact_id", order["contact"]);
      order["currency"] &&
        orderFormData.append("currency_id", order["currency"]);
      order["data_item"] &&
        orderFormData.append("data_item", JSON.stringify(order["data_item"]));
      order["data_revenue"] &&
        orderFormData.append(
          "data_revenue",
          JSON.stringify(order["data_revenue"])
        );
      order["deal"] && orderFormData.append("deal_id", order["deal"]);
      order["status"] && orderFormData.append("status_id", order["status"]);
      order["end_date"] &&
        orderFormData.append("end_date", moment(order["end_date"]));
      order["order_date"] &&
        orderFormData.append(
          "order_date",
          moment(order["order_date"]).format("YYYY/MM/DD")
        );
      order["start_date"] &&
        orderFormData.append("start_date", moment(order["start_date"]));
      order["exchange_rate_order"] &&
        orderFormData.append(
          "exchange_rate_order",
          order["exchange_rate_order"]
        );
      order["exchange_rate_payment"] &&
        orderFormData.append(
          "exchange_rate_payment",
          order["exchange_rate_payment"]
        );
      order["product"] && orderFormData.append("product_id", order["product"]);
      order["data_invoice"] &&
        orderFormData.append(
          "data_invoice",
          JSON.stringify(order["data_invoice"])
        );
      orderFormData.append(
        "is_internal_project",
        order["internal_project"] == false ? 0 : 1
      );
      orderFormData.append(
        "upload_later",
        order["upload_later"] == false ? 0 : 1
      );
      order && orderFormData.append("_method", "put");
      const response = await request(`${API.editOrder}`, {
        method: "POST",
        body: orderFormData,
      });

      if (!response.ok) {
        throw new Error(`The file format is not correct for contact data.`);
      }
      const result = await response.json();
      dispatch(updateOrderSuccess(result));
      return result;
    } catch (error) {
      throw error;
    }
  }
);

export const downloadFile = (fileUrl) => async (dispatch) => {
  try {
    const defaultFileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
    const response = await request(`${API.downloadFileOrder}/` + fileUrl);

    if (response.ok) {
      const result = await response.blob().then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", defaultFileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
      return result;
    }
    const newError = new Error("Download File failed.");
    throw newError;
  } catch (error) { }
};

export const fetchStatus = () => async (dispatch) => {
  try {
    let url = `${API.statusOrders}`;
    const response = await request(url);
    return await response.json().then((res) => {
      if (res.status_code === 200) {
        return res.data;
      }
      return null;
    });
  } catch (error) {
    //
  }
};

export const fetchProduct = () => async (dispatch) => {
  try {
    let url = `${API.products}`;
    const response = await request(url);
    return await response.json().then((res) => {
      if (res.status_code === 200) {
        return res.data;
      }
      return null;
    });
  } catch (error) {
    //
  }
};

export const getAllOrderByContact = (contactId) => async () => {
  try {
    const url = `${API.getAllOrderByContact}${makeURLSearchParams({
      has_contact_id: contactId,
    })}`;
    const response = await request(url);

    if (response.ok) {
      return await response.json();
    }

    throw Error("Fetch data failed.");
  } catch (error) {
    throw error;
  }
};

export const getOrderById = (id) => async (dispatch, getState) => {
  try {
    let url = `${API.getOrderById}${makeURLSearchParams({ id })}`;
    const response = await request(url);
    const responseJson = await response.json();
    if (responseJson.status_code === 200) {
      const order = responseJson.data;
      return order;
    }
    return null;
  } catch (error) {
    //
  }
};

export const selectSearch = (option, type) => async (dispatch) => {
  const data = {
    option,
    type,
  };
  try {
    dispatch(updateOptionSearch(data));
  } catch (error) {
    //
  }
};

export const updateUnitForOrder = (option) => async (dispatch) => {
  const data = { option }
  try {
    dispatch(updateOptionSearchUnit(data));
  } catch (error) {
    //
  }
};

export const updateSearchUnitDate = (option) => async (dispatch) => {
  try {
    dispatch(updateSearch(option));
  } catch (error) {
    //
  }
};

export const clearSearch = (data) => async (dispatch) => {
  try {
    dispatch(resetOptionSearch(data));
  } catch (error) {
    //
  }
};

export const removeSearch = (category) => async (dispatch) => {
  try {
    dispatch(deleteOptionSearch(category));
  } catch (error) {
    //
  }
};

export const removeSearchUnits = (category) => async (dispatch) => {
  try {
    dispatch(deleteOptionSearchUnits(category));
  } catch (error) {
    //
  }
};

export const setPageSize = (data) => async (dispatch) => {
  try {
    dispatch(setLimit(data));
  } catch (error) {
    //
  }
};

export const deleteOrder = (userId, ids) => async (dispatch) => {
  try {
    dispatch(deleteOrderStart());
    const response = await request(`${API.deleteOrder}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ order_ids: ids, id: userId }),
    });
    const responseJson = await response.json();
    if (responseJson.status_code === 200) {
      dispatch(deleteOrderSuccess(ids));
      return true;
    } else {
      dispatch(deleteOrderFailure(responseJson.error));
      return false;
    }
  } catch (error) {
    dispatch(deleteOrderFailure(error.message));
    return false;
  }
};

export const getExchangeCurrencyRate = (currency) => async () => {
  try {
    const params = {
      from: currency,
      to: "",
    };
    const url = `${API.exchangerate}${makeURLSearchParams(params)}`;
    const response = await request(url);
    const responseJson = await response.json();
    if (responseJson.status_code === 200) {
      return responseJson;
    }

    throw Error("Fetch data failed.");
  } catch (error) {
    throw error;
  }
};

export const getOrdersByStatusNew = (contact_id) => async () => {
  try {
    let url = `${API.getOrdersByStatusNew}?contact_id=${contact_id}`;
    const response = await request(url);
    const responseJson = await response.json();
    if (responseJson.status_code === 200) {
      return responseJson;
    }
    return null;
  } catch (error) { }
};

export const getTotalRevenueByMonth = (search) => async () => {
  try {
    const url = `${API.getTotalRevenueByMonth}${makeURLSearchParams({
      start_date: search.start_date,
      end_date: search.end_date,
      unit_id: search.unit_id,
    })}`;
    const response = await request(url);
    const responseJson = await response.json();
    if (responseJson.status_code === 200) {
      return responseJson;
    }
    return null;
  } catch (error) { }
};

export const setSelectTab = (data) => async (dispatch) => {
  try {
    dispatch(setSelectedTab(data));
  } catch (error) {
    //
  }
};
export const fetchAllOrder = () => async (dispatch) => {
  try {
    let url = `${API.getAllOrder}`;
    const response = await request(url);
    const responseJson = await response.json();
    if (responseJson.status_code === 200) {
      return dispatch(getOrdersSuccess(responseJson));
    } else {
      dispatch(getOrdersFailure(responseJson.error));
      return false;
    }
  } catch (error) {
    console.log(error);
  }
}
export default ordersSlice;
