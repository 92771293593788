import { configureStore } from '@reduxjs/toolkit';
import authSlice from './auth/auth.slice';
import contactsSlice from '../store/contactsSlice';
import contactsListSlice from '../store/contactsListSlice';
import contactFormSlice from '../store/contactFormSlice ';
import importContactSlice from '../store/importContactSlice';
import listDetailSlice from '../store/listDetailSlice';
import reportContactSlice from '../store/reportContactSlice';
import notesSlice from '../store/notesSlide.js';
import contactDetailSlice from '../store/contactDetailSlice';
import tasksSlice from '../store/tasksSlice';
import unitSlice from '../store/unitSlice';
import teamsSlice from '../store/teamSlice';
import dealsSlice from '../store/dealsSlice';
import currencySlice from '../store/currencySlice';
import stageDealSlice from '../store/StageDealSlice';
import tasksListSlice from '../store/taskListSlice';
import historiesSlice from '../store/historiesSlice';
import ordersSlice from '../store/ordersSlice';
import proposalSlice from '../store/proposalSlice';
import userSlice from '../store/userSlice';
import statusOrderSlice from '../store/statusOrderSlice';
import meetingsSlice from '../store/meetingSlice';
import quotationSlice from '../store/quotationsSlice';
import invoiceSlice from '../store/invoiceSlice';
import statusInvoiceSlice from '../store/statusInvoiceSlice';
import dashboardSlice from '../store/dashboardSlice';
import channelsSlice from '../store/channelSlice';
import targetSlice from '../store/targetSlice';
import reportOrderSlice from '../store/reportOrderSlice.js';
import historyLogSlice from '../store/historyLogSlice.js';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    contacts: contactsSlice.reducer,
    contactList: contactsListSlice.reducer,
    contactForm: contactFormSlice.reducer,
    importContact: importContactSlice.reducer,
    listDetails: listDetailSlice.reducer,
    histories: historiesSlice.reducer,
    notes: notesSlice.reducer,
    report: reportContactSlice.reducer,
    contactDetail: contactDetailSlice.reducer,
    tasks: tasksSlice.reducer,
    units: unitSlice.reducer,
    teams: teamsSlice.reducer,
    deals: dealsSlice.reducer,
    currencies: currencySlice.reducer,
    stageDeals: stageDealSlice.reducer,
    taskList: tasksListSlice.reducer,
    statusOrders: statusOrderSlice.reducer,
    orders: ordersSlice.reducer,
    proposal: proposalSlice.reducer,
    users: userSlice.reducer,
    meetings: meetingsSlice.reducer,
    quotation: quotationSlice.reducer,
    invoices: invoiceSlice.reducer,
    statusInvoices: statusInvoiceSlice.reducer,
    dashboard: dashboardSlice.reducer,
    channels: channelsSlice.reducer,
    targets: targetSlice.reducer,
    reportOrder: reportOrderSlice.reducer,
    historyLog: historyLogSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
