import { createSlice } from '@reduxjs/toolkit';
import { API } from '../utils/api';
import request from '../services/client';
import { makeURLSearchParams, replaceMessage } from '../utils/helper';

const initialState = {
    histories: [],
    dealHistories: [],
    loading: false,
    search_option: {
        text: [],
    },
    error: [],
};

const historiesSlice = createSlice({
    name: 'histories',
    initialState,
    reducers: {
        getHistoriesStart: (state, action) => {
            state.loading = true;
        },
        getHistoriesSuccess: (state, action) => {
            state.loading = false;
            state.histories = [...action.payload.data];
        },
        getDealHistoriesSuccess: (state, action) => {
            state.loading = false;
            state.dealHistories = [...action.payload.data];
        },
        getHistoriesFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        resetHistories: (state) => {
            state.histories = [];
            state.dealHistories = [];
            state.loading = false;
            state.search_option = {text: []};
            state.error = [];
        },
        updateOptionSearch: (state, action) => {
            const option = action.payload.option;
            const type = action.payload.type;
            if (type === 'text') {
                state.search_option = {
                    ...state.search_option,
                    'text': option ? [option] : [],
                };
                state.histories = action.payload.histories;
                return;
            }
        },
        updateDealHistory: (state, action) => {
            state.dealHistories = action.payload.data;
        },
    }
});

export const {
    getHistoriesStart,
    getHistoriesSuccess,
    getHistoriesFailure,
    resetHistories,
    updateOptionSearch,
    getDealHistoriesSuccess,
    updateDealHistory,
} = historiesSlice.actions;

export const fetchHistories = (model_id, page = 1) => async (dispatch, getState) => {
    try {
        dispatch(getHistoriesStart());
        const query = makeURLSearchParams({
            page: page
        });

        let url = `${API.listHistories(model_id)}/${query}`;
        const response = await request(url);
        return await response.json().then((response) => {
            if (response.status_code === 200) {
                const data = response.data.map(item => {
                    item.isShow = true;
                    item["description"] = replaceMessage(item.message?.content, item.message.reference_values);
                    return item;
                });
                dispatch(getHistoriesSuccess({data}));
            } 
            return response;
        }, (error) => {
            dispatch(getHistoriesFailure(error))
        });
    } catch (error) {
        dispatch(getHistoriesFailure(error.message));
    }
}

export const fetchDealHistories = (dealID, page = 1) => async (dispatch, getState) => {
    try {
        dispatch(getHistoriesStart());
        const query = makeURLSearchParams({
            page: page
        });

        let url = `${API.listDealHistories(dealID)}/${query}`;
        const response = await request(url);
        return await response.json().then((response) => {
            if (response.status_code === 200) {
                const data = response.data.map(item => {
                    item.isShow = true;
                    item["description"] = replaceMessage(item.message?.content, item.message.reference_values);
                    return item;
                });
                dispatch(getDealHistoriesSuccess({data}));
            } 
            return response;
        }, (error) => {
            dispatch(getHistoriesFailure(error))
        });
    } catch (error) {
        dispatch(getHistoriesFailure(error.message));
    }
}

export const selectSearch = (option, type) => async (dispatch) => {
    const data = {
        option,
        type
    }
    try {
        dispatch(updateOptionSearch(data));
    } catch (error) {
        //
    }
};

export const getDetailHistoryById = (id) => async () => {
    try {
        let url = `${API.getDetailLogById}?id=${id}`;
        const response = await request(url);
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export default historiesSlice;