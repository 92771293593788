import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./index.css";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ModalNote from '../ModalCenter';
import {
    CREATE_PROPOSAL_FIELD,
    CREATE_QUOTATION_FIELD,
    DISABLED_STATUS_QUOTATION,
    EDIT_PROPOSAL_FIELD,
    HEADER_TABLE_PROPOSAL_QUOTATION,
    HEADER_TABLE_PROPOSAL_QUOTATION_LABO,
} from "../../utils/constant";
import moment from "moment";
import Loading from "../Loading";
import Select, { components } from 'react-select';
import FaAngleDown from '@mui/icons-material/ArrowDropDown';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import FileSymbol from './../../assets/images/fileSymbol.png';
import ArrowRightIcon from "@mui/icons-material/ArrowForwardIos";
import Table from "../Table";
import { createQuotation, fetchStatus, getQuotation, updateQuotation } from "../../store/quotationsSlice";
import { getInforProposal } from "../../store/proposalSlice";
import ModalProposal from "../ModalProposal";
import { formatNumber, formatNumberWithCommas, formatNumberWithCommasAndDot, formatNumberWithNoCommas, validateForm } from "../../utils/helper";
import TextField from '@mui/material/TextField';
import { getDealById } from "../../store/dealsSlice";
import { getTaskById } from "../../store/tasksSlice";
import Toast from "../ToastMessage";
import { Helmet } from "react-helmet";
import { fetchContactPoint } from "../../store/quotationsSlice";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import ArrowDownIconOutlined from "@mui/icons-material/KeyboardArrowDown";
import HTMLReactParser from 'html-react-parser';
import { fetchNotes, updateNote, deleteNote } from '../../store/notesSlide';
import { downloadFile } from '../../store/ordersSlice';
import Dropdown from '../../components/Dropdown';
import { authUserSelector } from '../../redux/auth/auth.selectors';
import Modal from '../../components/ModalBottom';
import ModalDelete from '../../components/ModalCenter';
import { trimedCkEditorText } from '../../utils/helper';

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <FaAngleDown />
        </components.DropdownIndicator>
    );
};

function Quotation({ isEdit = false }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [disable, setDisable] = useState(false);
    const [inputValues, setInputValues] = useState({});
    const [errors, setErrors] = useState({});
    const fileInputRef = useRef(null);
    const [createQuotationField, setCreateQuotationField] = useState(CREATE_QUOTATION_FIELD);
    const [createProposalField, setCreateProposalField] = useState(CREATE_PROPOSAL_FIELD);
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const param = useParams();
    const [columns, setColumns] = useState(HEADER_TABLE_PROPOSAL_QUOTATION);
    const proposalInfo = useSelector((state) => state.proposal);
    const user_info = JSON.parse(localStorage.getItem("user_info")).user;
    const [isOpenModalNote, setIsOpenModalNote] = useState(false);
    const [isOpenModalProposal, setIsOpenModalProposal] = useState({ isOpen: false, proposal: {} })
    const [isDisabledStatus, setIsDisabledStatus] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [searchParams] = useSearchParams();
    const dealId = searchParams.get("dealId");
    const taskId = searchParams.get("taskId");
    const orderId = searchParams.get("orderId");
    const [contactPointList, setContactPointList] = useState([]);
    const quotationId = param.id;
    const [quotation, setQuotation] = useState(null);
    const [task, setTask] = useState(null);
    const [deal, setDeal] = useState(null);
    const [listProposal, setListProposal] = useState(quotation?.proposal);
    const [toast, setToast] = useState(false);
    const [isShowProposal, setIsShowProposal] = useState(true);
    const [isOpenStartDate, setIsOpenStartDate] = useState(false);
    const [isOpenEndDate, setIsOpenEndDate] = useState(false);
    const [isOpenDeadline, setIsOpenDeadline] = useState(false);
    const [estimateCostRadio, setEstimateCostRadio] = useState(1);
    const [isDisableInputCost, setIsDisableInputCost] = useState(true);
    const [isModalNoteEditCost, setIsModalNoteEditCost] = useState(false);
    const [isEditCost, setIsEditCost] = useState(false);
    const [estimateCost, setEstimateCost] = useState(0);
    const [showMore, setShowMore] = useState(false);
    const notes = useSelector((state) => state.notes.notes);
    const authUser = useSelector(authUserSelector);
    const { state } = useLocation();

    const [selectedNoteEdit, setSelectedNoteEdit] = useState(null);
    const [selectedNoteDelete, setSelectedNoteDelete] = useState(null);
    const [isOpenEditModal, setIsOpenEditModal] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

    const customStyles = {
        menuPortal: (base) => ({ ...base, zIndex: 99999, }),
        control: (base) => ({ ...base, textAlign: 'left', border: "1px solid #b4c7de", fontSize: "14px", }),
        menuList: (base) => ({
            ...base,
            width: "100%",
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
        }),
        option: (base) => ({
            ...base,
            whiteSpace: 'normal',
            wordWrap: 'break-word'
        }),
    };
    const menuPortalTarget = document.body;

    useEffect(() => {
        if (state === "reEstimate") {
            setToast({ show: true, message: t('Task updated successfully') });
            setIsDisableInputCost(false);
        }
    }, [state]);

    useEffect(() => {
        createProposalField?.map(field => (
            field.map(item => {
                switch (item.name) {
                    case "sale_channel":
                        item.options = proposalInfo?.saleChannel.map(sale => ({ value: sale.id, label: sale.name }));
                        break;
                    case "application_type":
                        item.options = proposalInfo?.applicationType.map(type => ({ value: type.id, label: type.name }));
                        break;
                    case "technology_select":
                        item.options = proposalInfo?.technology.map(technology => ({ value: technology.id, label: technology.name }));
                        break;
                    case "project_profession":
                        item.options = proposalInfo?.projectProfession.map(project => ({ value: project.id, label: project.name }));
                        break;
                    default:
                        break;
                }
                return item;
            })
        ))
    }, [proposalInfo])

    useEffect(() => {
        setLoading(true);
        if (dealId) {
            dispatch(getInforProposal());
            getDataForOption();
            dispatch(fetchNotes(dealId, 'deal', '0'));
        } else if (isEdit && param?.id) {
            getDataForEdit();
        }
    }, []);

    useEffect(() => {
        if (deal && !isEdit) {
            getDataContactPoint(deal?.contacts[0]?.id);
            setInputValues({
                ...inputValues,
                name: deal?.name,
                deal: { value: deal?.id, label: deal?.name },
                contact: { value: deal?.contacts[0]?.id, label: deal?.contacts[0]?.name },
                currency: { value: deal?.currency?.id, label: deal.currency?.name },
                sender: user_info.name ? `${user_info?.name} (${user_info?.email})` : null,
                // contact_point_ids: contactPointList[0],
                technology: 1,
                project_type: 1,
                status: { value: 1, label: 'Estimating' },
                proposal_name: `${deal?.name} #1`
            })
            setSelectedOptions({
                ...selectedOptions,
                name: deal?.name,
                deal: { value: deal?.id, label: deal?.name },
                contact: { value: deal?.contacts[0]?.id, label: deal?.contacts[0]?.name },
                currency: { value: deal.currency?.id, label: deal.currency?.name },
                status: { value: 1, label: 'Estimating' },
                proposal_name: `${deal?.name} #1`,
                // contact_point_ids: contactPointList[0],
            });
        }
    }, [deal])

    useEffect(() => {
        if (deal && !isEdit) {
            if (!inputValues['contact_point_ids']) {
                setInputValues({
                    ...inputValues,
                    contact_point_ids: contactPointList[0],
                })
                setSelectedOptions({
                    ...selectedOptions,
                    contact_point_ids: contactPointList[0],
                });
            }
        }
    }, [contactPointList])

    const getDataContactPoint = async (contact) => {
        const contact_point_list = await dispatch(fetchContactPoint(contact));
        setContactPointList(contact_point_list?.data?.map(item => ({ value: item.id, label: item.full_name })) ?? [])
    }

    useEffect(() => {
        if (startDate !== null && endDate !== null) {
            if (startDate >= endDate) {
                setErrors({ ...errors, "Forecast End Date": `Its must be greater than Forecast Start Date` })
            } else {
                let newErr = { ...errors };
                delete newErr["Forecast End Date"];
                setErrors({ ...newErr });
            }
        }
    }, [endDate, startDate])

    useEffect(() => {
        if (Object.keys(errors).length !== 0) {
            const arr = Object.keys(errors);
            const key = arr[0];
            const element = document.getElementById(key);
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [errors])

    const getDataForOption = async () => {
        setLoading(true);
        const deal = await dispatch(getDealById(dealId));
        setDeal(deal);
        let dataFields;
        if (taskId) {
            const task = await dispatch(getTaskById(taskId));
            if (task.quotation_id !== null) {
                setToast({
                    show: true,
                    error: true,
                    message: 'error'
                });
                setIsDisabled(true);
                setTimeout(() => navigate('/dashboard'), 1000);
            }
            setTask(task);
            dataFields = handleCreateField(deal, task);
        } else {
            dataFields = handleCreateField(deal);
        }
        setCreateQuotationField(dataFields);
        setLoading(false);
    }

    const getDataForEdit = async () => {
        setLoading(true);
        if (taskId) {
            const task = await dispatch(getTaskById(taskId));
            setTask(task);
        }
        let quotation = await dispatch(getQuotation(quotationId));
        if (quotation?.result) {
            quotation = quotation.data
            setQuotation(quotation);
            if (quotation) {
                if (quotation?.proposal) {
                    let proposal = [...quotation?.proposal];
                    for (let index = 0; index < quotation?.proposal.length; index++) {
                        if (quotation.proposal[index].id === quotation.proposals[index]?.proposal_id) {
                            proposal[index] = { ...proposal[index], "cancellation_reasons": quotation.proposals[index].note, 'estimate_file': quotation.proposals?.media };
                        }
                    }
                    setListProposal(proposal);
                } else if (quotation?.proposal_labos) {
                    let proposal = [...quotation?.proposal_labos];
                    setListProposal(proposal);
                    setIsShowProposal(false);
                    setColumns(HEADER_TABLE_PROPOSAL_QUOTATION_LABO);
                    setEstimateCostRadio(2);
                    setIsDisableInputCost(false);
                }
                setEstimateCost(quotation.estimation_cost);
            }
            setDeal({ id: quotation?.deal?.id, name: quotation?.deal?.name });
            const statusData = await dispatch(fetchStatus());
            const dataFields = createQuotationField.map(field => (
                field.map((item) => {
                    if (item.name === "status") {
                        item.options = statusData?.map((status) => ({ value: status?.id, label: status?.name }));
                    }
                    return item;
                })
            ))
            setCreateQuotationField(dataFields);
            setCreateProposalField(EDIT_PROPOSAL_FIELD);
            setInputValues({
                ...inputValues,
                name: quotation.name,
                start_date: quotation?.start_date ? moment(quotation?.start_date) : null,
                end_date: quotation?.end_date ? moment(quotation?.end_date) : null,
                estimation_cost: formatNumber(quotation?.estimation_cost),
                sender: user_info.name ? `${user_info?.name} (${user_info?.email})` : null,
                contact_point_ids: quotation?.contact_point,
                deal: { value: quotation.deal?.id, label: quotation.deal?.name },
                currency: { value: quotation.currency?.id, label: quotation.currency?.name },
                status: { value: quotation.status?.id, label: quotation.status?.name },
                contact: { value: quotation.contact?.id, label: quotation.contact?.name },
            })
            setSelectedOptions({
                ...selectedOptions,
                deal: { value: quotation.deal?.id, label: quotation.deal?.name },
                currency: { value: quotation.currency?.id, label: quotation.currency?.name },
                status: { value: quotation.status?.id, label: quotation.status?.name },
                contact: { value: quotation.contact?.id, label: quotation.contact?.name },
                contact_point_ids: { value: quotation.contact_point?.id, label: quotation.contact_point?.name },
            });
            if (quotation.status_id == 4) {
                setIsDisabled(true);
            } else {
                setIsDisabledStatus(false);
            }
            setStartDate(quotation?.start_date ? moment(quotation?.start_date) : null);
            setEndDate(quotation?.end_date ? moment(quotation?.end_date) : null);
            dispatch(fetchNotes(quotation.deal?.id, 'deal', '0'));
        } else {
            setToast({
                show: true,
                error: true,
                message: quotation?.data.payload
            });
            setIsDisabled(true);
            setTimeout(() => navigate('/dashboard'), 1000);
        }
        setLoading(false);
    }

    const handleCallOption = async (deal, task, item) => {
        switch (item.name) {
            case "contact":
                if (task) {
                    item.options = [{ value: task.taskable?.id, label: task.taskable?.name }]
                } else {
                    item.options = deal?.contacts.map((contact) => ({
                        value: contact.id, label: contact.name
                    }))
                }
                break;
            default:
                break;
        }
    }

    const handleCreateField = (deal, task = null) => {
        const data = createQuotationField?.map(field => (
            field.map(item => {
                handleCallOption(deal, task, item);
                return item;
            })
        ))

        createProposalField?.map(field => (
            field.map(item => {
                handleCallOption(deal, task, item);
                return item;
            })
        ))

        return data;
    }

    const handleChangeSelect = async (selectedOption, field) => {
        setLoading(true);
        const newSelectedOptions = { ...selectedOptions };
        newSelectedOptions[field.name] = selectedOption;
        setSelectedOptions(newSelectedOptions);
        if (field.name === 'status' && selectedOption.value === 4) {
            setIsOpenModalNote(true);
        }
        if (field.name === "contact") {
            const contact_point_list = await dispatch(fetchContactPoint(selectedOption.value));
            setContactPointList(contact_point_list?.data?.map(item => ({ value: item.id, label: item.full_name })) ?? [])
        }
        setDisable(false);
        setLoading(false);
    };

    const handleChange = async (event, field) => {
        const label = field.name;
        if (field.type === "checkbox") {
            let options = inputValues[label] ?? [];
            if (event.target.checked) {
                setInputValues({
                    ...inputValues,
                    [label]: [...options, event.target.value]
                });
            } else {
                let list = options.filter((item) => item !== event.target.value);
                setInputValues({
                    ...inputValues,
                    [label]: list,
                });
            }
        } else if (field.label === "Number day") {
            let number = event.target.value;
            var commas = formatNumberWithCommas(number);
            setInputValues({
                ...inputValues,
                [label]: commas,
            });
        } else if (field.type === "number") {
            let number = event.target.value;
            var commas = formatNumberWithCommasAndDot(number);
            setInputValues({
                ...inputValues,
                [label]: commas,
            });
        } else if (label === "name") {
            setInputValues({
                ...inputValues,
                [label]: event?.target?.value ?? event,
                proposal_name: `${event?.target?.value ?? event} #1`
            })
        } else {
            setInputValues({
                ...inputValues,
                [label]: event?.target?.value ?? event,
            });
            if (label === "start_date") {
                setStartDate(event?.target?.value ?? event)
            } else if (label === "end_date") {
                setEndDate(event?.target?.value ?? event)
            }
        }
        if (label === "name") {
            let newErr = { ...errors };
            delete newErr[field.label];
            delete newErr["proposal_name"];
            setErrors({ ...newErr });
        } else {
            let newErr = { ...errors };
            delete newErr[field.label];
            setErrors({ ...newErr });
        }

        if (label === "estimation_cost" && estimateCost == formatNumberWithNoCommas(event?.target?.value)) {
            setIsEditCost(false);
        } else {
            setIsEditCost(true);
        }
        setDisable(false);
        setLoading(false);
    };

    const getValueForSelect = (field) => {
        if (field.type === "selectMulti") {
            if (field.name in selectedOptions) {
                return selectedOptions[field.name];
            } else {
                return null;
            }
        } else {
            if (field.name in selectedOptions) {
                return {
                    value: selectedOptions[field.name]?.value,
                    label: selectedOptions[field.name]?.label,
                };
            } else {
                return null;
            }
        }
    };

    const validateFile = (file, field) => {
        let error = null;
        file.forEach(element => {
            if (field?.max && (element.size / (1024 * 1024)) > field.max) {
                error = `The ${field.label} must not be greater than ${field.max}MB.`;
            }
            // else if (field.mimes.includes(element.type) === false) {
            //     error = `The ${field.label} must be a file of type: ${field.mimes_name.join(', ')}.`;
            // }   
        });
        return error;
    }

    const handleChangeFile = async (event, field) => {
        setLoading(true);
        try {
            const obj = event?.target?.files;
            const files = inputValues["file"] ? [...inputValues["file"]] : [];
            for (let i = 0; i < obj.length; i++) {
                files.push(obj[i])
            }
            let error = validateFile(files, field);
            let newErrors = { ...errors };
            if (files.length === 0) {
                return;
            }
            if (error) {
                newErrors = { ...errors, [field.label]: error };
            }
            setInputValues({
                ...inputValues,
                [field.name]: files,
            });
            setErrors({ ...newErrors });
            setLoading(false);
            event.target.value = null;
        } catch (error) {

        }
        setLoading(false);
    };

    const handleClickSelectFile = () => {
        fileInputRef.current?.click();
    }

    const removeUploadFile = (index, field) => {
        let inputFile = [...inputValues["file"]];
        inputFile.splice(index, 1);
        const newInput = { ...inputValues, file: inputFile };
        setInputValues(newInput);
        let error = validateFile(inputFile, field);
        let newErr = { ...errors };
        if (error) {
            setErrors({ ...errors, "File Upload": error });
        } else {
            delete newErr["File Upload"];
            setErrors(newErr);
        }
    }

    const handleCreateQuotation = async () => {
        setLoading(true);
        setDisable(true);
        if ("File Upload" in errors || "Expected End Date" in errors) {
            setLoading(false);
            setDisable(false);
            return;
        }
        let fieldValidate = [];
        let odcField;
        createQuotationField.forEach((element) => {
            element.forEach((item) => {
                fieldValidate.push(item);
            })
        });
        if (isShowProposal) {
            CREATE_PROPOSAL_FIELD.forEach((element) => {
                element.forEach((item) => {
                    if (item.label === "odc_detail") {
                        odcField = item;
                    } else {
                        fieldValidate.push(item);
                    }
                })
            })
            if (inputValues["project_type"] == 1) {
                fieldValidate = [...fieldValidate, odcField.numberMembers, odcField.numberDay];
                fieldValidate.splice(fieldValidate.findIndex((item) => item.label === "budget"), 1);
                fieldValidate.splice(fieldValidate.findIndex((item) => item.label === "project_type_advice"), 1);
            } else if (inputValues["project_type"] == 2) {
                fieldValidate.splice(fieldValidate.findIndex((item) => item.label === "project_type_advice"), 1);
            } else {
                fieldValidate.splice(fieldValidate.findIndex((item) => item.label === "budget"), 1);
            }
            if (inputValues["technology"] == 1) {
                fieldValidate.splice(fieldValidate.findIndex((item) => item.label === "technology_advice"), 1);
            } else {
                fieldValidate.splice(fieldValidate.findIndex((item) => item.label === "technology_select"), 1);
            }
        }

        const formIsValid = validateForm(fieldValidate, inputValues, setErrors, false, true);
        if (formIsValid) {
            let form;
            if (isShowProposal) {
                form = {
                    "name": inputValues["name"],
                    "status_id": inputValues["status"].value,
                    "deal_id": inputValues["deal"].value,
                    "contact_id": inputValues["contact"].value,
                    "currency_id": inputValues["currency"].value,
                    "start_date": inputValues["start_date"] ? moment(inputValues["start_date"]).format('DD-MM-YYYY') : null,
                    "end_date": inputValues["end_date"] ? moment(inputValues["end_date"]).format('DD-MM-YYYY') : null,
                    "saler[name]": user_info?.name,
                    "saler[email]": user_info?.email,
                    "contact_point[name]": inputValues["contact_point_ids"].label,
                    "contact_point[email]": `contact_${inputValues["contact_point_ids"].value}@gmail.com`,
                    "proposal_name": inputValues["proposal_name"],
                    "sale_channels": (inputValues["sale_channel"]?.map((item) => item.value)),
                    "desired_time": inputValues["deadline"] ? moment(inputValues["deadline"]).format('DD-MM-YYYY') : null,
                    "related_info": inputValues["related_information"],
                    "desired_types": (inputValues["desired_project_type"]),
                    "project_type": inputValues["project_type"],
                    "number_members": inputValues["project_type"] == 1 ? formatNumberWithNoCommas(inputValues["number_members"]) : null,
                    "number_day": inputValues["project_type"] == 1 ? formatNumberWithNoCommas(inputValues["number_day"]) : null,
                    "man_month": inputValues["project_type"] == 2 ? formatNumberWithNoCommas(inputValues["budget"]) : null,
                    "need_project_advice": inputValues["project_type"] == 3 ? inputValues["project_type_advice"] : null,
                    "technology_type": inputValues["technology"],
                    "need_tech_advice": inputValues["technology"] != 1 ? inputValues["technology_advice"] : null,
                    "programming_languages": inputValues["technology"] == 1 ? (inputValues["technology_select"]?.map((item) => item.value)) : null,
                    "application_types": (inputValues["application_type"]?.map((item) => item.value)),
                    "project_professions": (inputValues["project_profession"]?.map((item) => item.value)),
                    "attached_files[]": inputValues["file"],
                    "option_id": 1
                };
            } else {
                form = {
                    "name": inputValues["name"],
                    "status_id": inputValues["status"].value,
                    "deal_id": inputValues["deal"].value,
                    "contact_id": inputValues["contact"].value,
                    "currency_id": inputValues["currency"].value,
                    "start_date": inputValues["start_date"] ? moment(inputValues["start_date"]).format('DD-MM-YYYY') : null,
                    "end_date": inputValues["end_date"] ? moment(inputValues["end_date"]).format('DD-MM-YYYY') : null,
                    "estimation_cost": formatNumberWithNoCommas(inputValues["estimation_cost"]),
                    "option_id": 2
                };
            }
            if (taskId) {
                form.task_id = taskId;
            }
            let result = await dispatch(createQuotation(form));
            setLoading(false);
            if (result?.result && taskId) {
                navigate(`/contact/${task.taskable_id}?task=${taskId}`, { state: "quotation" });
            } else if (result?.result && deal) {
                navigate(`/deal/${deal?.id}`, { state: "quotation" });
            } else if (result?.result == false) {
                setToast({
                    show: true,
                    error: true,
                    message: result?.data.payload
                });
            }
        }
        setLoading(false);
        setDisable(false);
    }

    const handleUpdateQuotation = async (note = null) => {
        setLoading(true);
        if ("File Upload" in errors || "Expected End Date" in errors) {
            setLoading(false);
            setDisable(false);
            return;
        }

        if (inputValues["name"].toString().trim() === "") {
            setErrors({
                ...errors,
                Name: "Name field is required."
            })
            setLoading(false);
            return;
        }
        if (Object.keys(errors).length !== 0) {
            setLoading(false);
            return;
        }
        const data = {
            id: quotation?.id,
            name: inputValues["name"],
            status_id: inputValues["status"].value ?? inputValues["status"],
            start_date: inputValues["start_date"] ? moment(inputValues["start_date"]).format('DD-MM-YYYY') : null,
            end_date: inputValues["end_date"] ? moment(inputValues["end_date"]).format('DD-MM-YYYY') : null,
            option_id: estimateCostRadio,
            estimation_cost: formatNumberWithNoCommas(inputValues["estimation_cost"])
        }

        if (note) {
            data.note = note.note;
        }
        if (note) {
            data.note_estimate_cost = note.noteEstimate;
        }
        let result = await dispatch(updateQuotation(data));
        setLoading(false);
        if (result && taskId) {
            navigate(`/contact/${task.taskable_id}?task=${taskId}`, { state: { quotation: quotation?.name } });
        } else if (result && orderId) {
            navigate(`/orders/edit/${orderId}`, { state: { quotation: quotation?.name } });
        } else if (result && deal) {
            navigate(`/deal/${deal.id}`, { state: { quotation: quotation?.name } });
        }
        setLoading(false);
    }

    const handleCancel = () => {
        if (taskId) {
            navigate(`/contact/${task?.taskable_id}?task=${taskId}`);
        } else if (orderId) {
            navigate(`/orders/edit/${orderId}`);
        } else {
            navigate(`/deal/${deal?.id}`);
        }
    }

    const handleEditProposal = () => {
        setIsOpenModalProposal({ isOpen: false, proposal: {} });
        setToast({
            show: true,
            message: "Update proposal successed."
        });
    }

    const handleChangeEstimationCost = (event, field, index, idx) => {
        const label = field.name
        const newQuotationField = JSON.parse(JSON.stringify(createQuotationField));
        const fieldEstimationCost = newQuotationField[index][idx];

        newQuotationField[index][idx].disabled = !fieldEstimationCost.disabled;
        newQuotationField[index][idx].required = !fieldEstimationCost.required;

        if (event.target.value == 1 && inputValues[label]) {
            let newInputValues = { ...inputValues };
            delete newInputValues[label];
            setInputValues(newInputValues);
        }
        let newErr = { ...errors };
        delete newErr["Estimation Cost"];
        setErrors({ ...newErr });
        setIsShowProposal(!isShowProposal);
        setEstimateCostRadio(event.target.value);
        setCreateQuotationField(newQuotationField);
    }

    const handleClickCalender = (item, value) => {
        switch (item) {
            case "start_date":
                setIsOpenStartDate(value);
                break;
            case "end_date":
                setIsOpenEndDate(value);
                break;
            default:
                break;
        }
    }
    const scrollToTop = () => {
        window.scroll(0, 0);
    };

    const handleCloseModalCostAdjustmentReasons = () => {
        setIsModalNoteEditCost(false);
        setInputValues({
            ...inputValues,
            estimation_cost: formatNumber(estimateCost),
        })
        setIsEditCost(false);
        scrollToTop();
    };
    const handleShowMore = (action) => {
        setShowMore(action);
    }
    const downloadFileHandle = async (fileDownload) => {
        setLoading(true);
        await dispatch(downloadFile(fileDownload));
        setLoading(false);
    }
    const handleEditClick = (item) => {
        setSelectedNoteEdit(item);
        setIsOpenEditModal(true);
    };
    const handleEdit = async (note) => {
        setLoading(true);
        note['note'] = trimedCkEditorText(note['note']);
        let createSuccess = await dispatch(updateNote(note));
        if (createSuccess) {
            setToast({ show: true, message: t('Note updated successfully') });
        }
        setSelectedNoteEdit(null);
        setIsOpenEditModal(false);
        setLoading(false);
    };
    const arrowIcon = (
        <ArrowDownIconOutlined
            style={{
                marginRight: '0.2em',
                color: '#5f89ba',
            }}
        ></ArrowDownIconOutlined>
    );
    const handleDeleteClick = (item) => {
        setSelectedNoteDelete(item);
        setIsOpenDeleteModal(true);
    };

    const handleDelete = async (note) => {       
        setLoading(true);
        let createSuccess = await dispatch(deleteNote(note));
        if (createSuccess) {
            setToast({ show: true, message: t('Note removed successfully') });
        }
        setSelectedNoteDelete(null);
        setIsOpenDeleteModal(false);
        setLoading(false);
    };

    return (
        <div className="container-quotation">
            <Helmet>
                <title>{isEdit ? t('deal') : t('create_quotation')}</title>
                <meta name="description" content="Description of Deals Page" />
            </Helmet>
            <div className="tab-container create-page">
                {loading && <Loading />}
                <div className="d-flex" style={{ position: "relative" }}>
                    <p className="bread-crumb">{isEdit ? (<> <span
                        className="blue-text bread-crumb__link"
                        onClick={() => navigate("/deals")}
                    >
                        {t('deal')}
                    </span>
                        <ArrowRightIcon
                            style={{
                                fontSize: "14px",
                                marginLeft: "0.2em",
                                marginRight: "0.2em",
                                position: "relative",
                                bottom: "0.1em",
                            }}
                        ></ArrowRightIcon>
                        <span
                            className="blue-text bread-crumb__link"
                            onClick={() => navigate(`/deal/${deal?.id}`)}
                        >
                            {deal?.name}
                        </span>
                        <ArrowRightIcon
                            style={{
                                fontSize: "14px",
                                marginLeft: "0.2em",
                                marginRight: "0.2em",
                                position: "relative",
                                bottom: "0.1em",
                            }}
                        ></ArrowRightIcon>
                        {quotation?.name}
                    </>) : t("create_quotation")
                    }</p>
                </div>
                <div className="tab-container">
                    <div className="d-flex">
                        {createQuotationField?.map((field, index) => (
                            <div className="input-body" key={index}>
                                {field.map((item, idx) => (
                                    <div className="form-group" key={idx}
                                        style={errors[item.label] ? { paddingBottom: "5px" } : item.name === 'technology' ? { paddingBottom: "0px" } : { paddingBottom: "30px" }}
                                        id={item.label}
                                    >
                                        {!item.hideLabel && (
                                            <label>
                                                {t(item.label)}
                                                {item.required && <span style={{ color: "red" }}> *</span>}
                                            </label>
                                        )}
                                        {item.label === "estimation_cost" ? (
                                            <>
                                                <FormControl>
                                                    <RadioGroup
                                                        row
                                                        value={estimateCostRadio}
                                                        defaultValue={item.options[0]?.value}
                                                        sx={{ height: "40px", marginTop: "-10px" }}
                                                        onChange={(event) => handleChangeEstimationCost(event, item, index, idx)}
                                                    >
                                                        <FormControlLabel value={item.options[0]?.value} disabled={isEdit} control={<Radio />} sx={{ marginRight: '35px' }} label={item.options[0]?.label} />
                                                        <FormControlLabel value={item.options[1]?.value} disabled={isEdit} control={<Radio />} label={item.options[1]?.label + '(' + (deal?.currency?.name ?? quotation?.currency?.name) + ')'} />
                                                    </RadioGroup>
                                                </FormControl>
                                                <input
                                                    type="text"
                                                    style={{ height: "43px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: 'ellipsis' }}
                                                    className="form-control"
                                                    value={
                                                        inputValues[item.name] ??
                                                        ""
                                                    }
                                                    onChange={(event) => handleChange(event, item)}
                                                    disabled={isDisabled || (item.disabled && isDisableInputCost)}
                                                />
                                            </>
                                        ) : item.type === "select" ? (
                                            <Select
                                                value={getValueForSelect(item)}
                                                onChange={(selectedOption) => {
                                                    handleChangeSelect(selectedOption, item);
                                                    handleChange(selectedOption, item);
                                                }}
                                                isDisabled={isDisabled || item.disabled || (item.name === "status" && isDisabledStatus) || (item.name === "contact" && isEdit)}
                                                options={item.options}
                                                placeholder={`Select ${item.label.toLowerCase()}`}
                                                components={{ DropdownIndicator }}
                                                styles={customStyles}
                                                menuPortalTarget={menuPortalTarget}
                                                isOptionDisabled={(option) => {
                                                    return item.name === 'status' && isEdit && DISABLED_STATUS_QUOTATION.some(disabledOption => disabledOption.value === option.value)
                                                }}
                                            />
                                        ) : item.type === "date" ? (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    inputFormat="YYYY/MM/DD"
                                                    value={inputValues[item.name] ?? null}
                                                    open={(item.name === "start_date" && isOpenStartDate) ||
                                                        (item.name === "end_date" && isOpenEndDate)
                                                    }
                                                    onOpen={() => { handleClickCalender(item.name, true) }}
                                                    onClose={() => { handleClickCalender(item.name, false) }}
                                                    onChange={(newValue) => handleChange(newValue.$d, item)}
                                                    inputProps={{ readOnly: true }}
                                                    InputProps={{
                                                        sx: {
                                                            width: "100%",
                                                            height: "43px",
                                                            fontSize: "14px",
                                                            fontFamily: "Inter",
                                                            background: "white",
                                                        },
                                                    }}
                                                    renderInput={(params) => <TextField {...params} sx={{ input: { cursor: 'pointer' } }} {...params} onClick={() => { handleClickCalender(item.name, true) }} />}
                                                    disabled={isDisabled || (item.isDisabled === true && !inputValues["order_date"])}
                                                />
                                            </LocalizationProvider>
                                        ) : item.type === 'selectMulti' ? (<>
                                            <Select
                                                value={getValueForSelect(item)}
                                                onChange={(selectedOption) => {
                                                    handleChangeSelect(selectedOption, item);
                                                    handleChange(selectedOption, item);
                                                }}
                                                options={item.options}
                                                placeholder={`Select ${item.label.toLowerCase()}`}
                                                components={{ DropdownIndicator }}
                                                styles={customStyles}
                                                menuPortalTarget={menuPortalTarget}
                                                isMulti
                                                classNamePrefix="react-select"
                                            />
                                        </>) : (
                                            <input
                                                type={item.type}
                                                style={{ height: "43px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: 'ellipsis' }}
                                                className="form-control"
                                                value={
                                                    inputValues[item.name] ??
                                                    ""
                                                }
                                                onChange={(event) => handleChange(event, item)}
                                                disabled={isDisabled || item.disabled}
                                            />
                                        )}
                                        {errors[item.label] && (
                                            <span
                                                className="error-text"
                                                style={{
                                                    color: "red",
                                                    position: "relative",
                                                    bottom: "10px",
                                                    top: "5px"
                                                }}
                                            >
                                                {errors[item.label]}
                                            </span>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    <div className="deal-note_container" style={{ marginTop: "20px" }}>
                        <div className="d-flex">
                            {showMore === true ? (
                                <ArrowDownIconOutlined
                                    style={{ color: "#5f89ba" }}
                                    onClick={() =>
                                        handleShowMore(false)
                                    }
                                ></ArrowDownIconOutlined>
                            ) : (
                                <ArrowForwardIosOutlinedIcon
                                    style={{ color: "#5f89ba" }}
                                    onClick={() => {
                                        handleShowMore(true);
                                    }}
                                ></ArrowForwardIosOutlinedIcon>

                            )}
                            <p className="bread-crumb" style={{ fontSize: "16px" }}>{t("deal_note")}</p>
                        </div>
                        {showMore === true && (
                            notes?.length === 0 ? (
                                <p style={{ marginTop: '20px', textAlign: 'center', fontSize: '14px' }}>
                                    {t('no_data')}
                                </p>
                            ) : (
                                notes?.map((note, rowIndex) => (
                                    <>
                                        <div className="item">
                                            <div className="item-wrap" key={rowIndex} style={{ fontSize: '14px' }}>
                                                <div className="d-flex">
                                                    <p>
                                                        <span className="blue-text bold">{note?.user?.name}</span> added
                                                        a <span className="bold">Note</span>
                                                    </p>
                                                    <p style={{ marginLeft: 'auto' }}>
                                                        {moment(note?.created_at).format('YYYY/MM/DD - HH:mm')}
                                                    </p>
                                                    {note?.user?.id === authUser?.id && (
                                                        <Dropdown
                                                            options={['Edit', 'Delete']}
                                                            styles={{ height: '10px' }}
                                                            editFrom={true}
                                                            onChange={(value) => {
                                                                value === 'Edit' && handleEditClick(note);
                                                                value === 'Delete' && handleDeleteClick(note);
                                                            }}
                                                            arrowIcon={arrowIcon}
                                                        ></Dropdown>
                                                    )}
                                                </div>
                                                <div className="ck-content">{HTMLReactParser(note.note)}</div>
                                                {note?.file && (
                                                    <>
                                                        <hr style={{ margin: '16px 0' }} />
                                                        {note?.file && note.file.map((element) => (
                                                            <div className="d-flex" style={{ whiteSpace: "nowrap", overflow: "auto", paddingBlock: "10px", cursor: "pointer" }} key={element.split('/')[1]} onClick={() => downloadFileHandle(element)}>
                                                                <img src={FileSymbol} alt={"File"} />
                                                                <p className="file-name-title blue-text" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: 'ellipsis' }}>{element.split('/')[1]}</p>
                                                            </div>
                                                        ))}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ))
                            )
                        )}

                    </div>
                    {isShowProposal && (
                        <div className="quotation" style={{ marginTop: "20px" }}>
                            <div className="d-flex" style={{ justifyContent: "space-between", marginBottom: "20px" }}>
                                <p className="bread-crumb" style={{ fontSize: "16px" }}>{t("quotation_info")}</p>
                            </div>
                            <div className="d-flex">
                                {createProposalField.map((field, index) => (
                                    <div className="input-body" key={index}>
                                        {field.map((item, idx) => (
                                            <div className="form-group" key={idx}
                                                style={errors[item.label] ? { paddingBottom: "5px" } :
                                                    item.name === 'technology' || item.name === "budget" || item.name === "project_type" || item.name === "technology_select" ?
                                                        { paddingBottom: "0px" } : { paddingBottom: "30px" }}
                                                id={item.label}
                                            >
                                                {!item.hideLabel && (
                                                    <label>
                                                        {t(item.label)}
                                                        {item.required && <span style={{ color: "red" }}> *</span>}
                                                    </label>
                                                )}
                                                {item.label === "technology_advice" ? (
                                                    <>
                                                        {inputValues["technology"] != 1 ? (
                                                            <>
                                                                <textarea
                                                                    type="textarea"
                                                                    className="form-control"
                                                                    value={
                                                                        inputValues[item.label] ??
                                                                        inputValues[item.label.toLowerCase()] ??
                                                                        inputValues[item.name] ??
                                                                        ""
                                                                    }
                                                                    rows={4}
                                                                    cols={50}
                                                                    onChange={(event) => handleChange(event, item)}
                                                                />
                                                                {errors[item.label] && (
                                                                    <span
                                                                        className="error-text"
                                                                        style={{
                                                                            color: "red",
                                                                            position: "relative",
                                                                            bottom: "10px",
                                                                            top: "5px"
                                                                        }}
                                                                    >
                                                                        {errors[item.label]}
                                                                    </span>
                                                                )}
                                                            </>
                                                        ) : ""}
                                                    </>
                                                ) : item.label === "technology_select" ? (
                                                    <>
                                                        {inputValues["technology"] == 1 ? (
                                                            <>
                                                                <Select
                                                                    value={getValueForSelect(item)}
                                                                    onChange={(selectedOption) => {
                                                                        handleChangeSelect(selectedOption, item);
                                                                        handleChange(selectedOption, item);
                                                                    }}
                                                                    options={item.options}
                                                                    components={{ DropdownIndicator }}
                                                                    placeholder="Select technology"
                                                                    styles={customStyles}
                                                                    menuPortalTarget={menuPortalTarget}
                                                                    isMulti
                                                                    classNamePrefix="react-select"
                                                                />
                                                                {errors[item.label] && (
                                                                    <span
                                                                        className="error-text"
                                                                        style={{
                                                                            color: "red",
                                                                            position: "relative",
                                                                            bottom: "10px",
                                                                            top: "5px"
                                                                        }}
                                                                    >
                                                                        {errors[item.label]}
                                                                    </span>
                                                                )}
                                                            </>
                                                        ) : ""}
                                                    </>
                                                ) : item.label === "budget" ? (inputValues["project_type"] == 2 &&
                                                    <>
                                                        <label>
                                                            {t(item.label)}
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={"form-control"}
                                                            value={
                                                                inputValues[item.label] ??
                                                                inputValues[item.label.toLowerCase()] ??
                                                                inputValues[item.name] ??
                                                                ""
                                                            }
                                                            onChange={(event) => handleChange(event, item)}
                                                        />
                                                        {
                                                            errors[item.label] && (
                                                                <span
                                                                    className="error-text"
                                                                    style={{
                                                                        color: "red",
                                                                        position: "relative",
                                                                        bottom: "10px",
                                                                        top: "5px"
                                                                    }}
                                                                >
                                                                    {errors[item.label]}
                                                                </span>
                                                            )
                                                        }
                                                    </>
                                                ) : item.label === "odc_detail" ? inputValues["project_type"] == 1 && (
                                                    <div className='container-odc'>
                                                        <div className='number-members'>
                                                            <label>
                                                                {item.numberMembers.label}
                                                                <span style={{ color: "red" }}> *</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className={"form-control"}
                                                                value={
                                                                    inputValues[item.numberMembers.label] ??
                                                                    inputValues[item.numberMembers.label.toLowerCase()] ??
                                                                    inputValues[item.numberMembers.name] ??
                                                                    ""
                                                                }
                                                                onChange={(event) => handleChange(event, item.numberMembers)}
                                                            />
                                                            {errors[item.numberMembers.label] && (
                                                                <span
                                                                    className="error-text"
                                                                    style={{
                                                                        color: "red",
                                                                        position: "relative",
                                                                        bottom: "10px",
                                                                        top: "5px"
                                                                    }}
                                                                >
                                                                    {errors[item.numberMembers.label]}
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div className='number-day'>
                                                            <label>
                                                                {item.numberDay.label}
                                                                <span style={{ color: "red" }}> *</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className={"form-control"}
                                                                value={
                                                                    inputValues[item.numberDay.label] ??
                                                                    inputValues[item.numberDay.label.toLowerCase()] ??
                                                                    inputValues[item.numberDay.name] ??
                                                                    ""
                                                                }
                                                                onChange={(event) => handleChange(event, item.numberDay)}
                                                            />
                                                            {errors[item.numberDay.label] && (
                                                                <span
                                                                    className="error-text"
                                                                    style={{
                                                                        color: "red",
                                                                        position: "relative",
                                                                        bottom: "10px",
                                                                        top: "5px"
                                                                    }}
                                                                >
                                                                    {errors[item.numberDay.label]}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : item.label === "project_type_advice" ? inputValues["project_type"] == 3 && (
                                                    <>
                                                        <textarea
                                                            type="textarea"
                                                            className="form-control"
                                                            value={
                                                                inputValues[item.label] ??
                                                                inputValues[item.label.toLowerCase()] ??
                                                                inputValues[item.name] ??
                                                                ""
                                                            }
                                                            rows={4}
                                                            cols={50}
                                                            onChange={(event) => handleChange(event, item)}
                                                        />
                                                        {errors[item.label] && (
                                                            <span
                                                                className="error-text"
                                                                style={{
                                                                    color: "red",
                                                                    position: "relative",
                                                                    bottom: "10px",
                                                                    top: "5px"
                                                                }}
                                                            >
                                                                {errors[item.label]}
                                                            </span>
                                                        )}
                                                    </>
                                                ) : item.type === "textarea" ? (
                                                    <textarea
                                                        type="textarea"
                                                        className="form-control"
                                                        value={
                                                            inputValues[item.label] ??
                                                            inputValues[item.label.toLowerCase()] ??
                                                            inputValues[item.name] ??
                                                            ""
                                                        }
                                                        rows={4}
                                                        cols={50}
                                                        onChange={(event) => handleChange(event, item)}
                                                    />
                                                ) : item.type === "select" ? (
                                                    <Select
                                                        value={getValueForSelect(item)}
                                                        onChange={(selectedOption) => {
                                                            handleChangeSelect(selectedOption, item);
                                                            handleChange(selectedOption, item);
                                                        }}
                                                        options={item.name === "contact_point_ids" ? contactPointList : item.options}
                                                        placeholder={item.name === "contact" ? "Input to display the list" : `Select ${item.label.toLowerCase()}`}
                                                        components={{ DropdownIndicator }}
                                                        styles={customStyles}
                                                        menuPortalTarget={menuPortalTarget}
                                                        isDisabled={item.disabled}
                                                    />
                                                ) : item.type === "date" ? (
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            inputFormat="YYYY/MM/DD"
                                                            value={inputValues[item.name] ?? null}
                                                            open={isOpenDeadline}
                                                            onOpen={() => { setIsOpenDeadline(true) }}
                                                            onClose={() => { setIsOpenDeadline(false) }}
                                                            onChange={(newValue) => handleChange(newValue.$d, item)}
                                                            inputProps={{ readOnly: true }}
                                                            InputProps={item.name === "order_date" && !isEdit && !inputValues?.file ? {
                                                                sx: {
                                                                    width: "100%",
                                                                    height: "43px",
                                                                    fontSize: "14px",
                                                                    fontFamily: "Inter",
                                                                    background: "#f2f2f2",
                                                                },
                                                            } : {
                                                                sx: {
                                                                    width: "100%",
                                                                    height: "43px",
                                                                    fontSize: "14px",
                                                                    fontFamily: "Inter",
                                                                    background: "white",
                                                                },
                                                            }}
                                                            renderInput={(params) => <TextField {...params} sx={{ input: { cursor: 'pointer' } }} {...params} onClick={() => setIsOpenDeadline(true)} />}
                                                            disabled={item.isDisabled === true && !inputValues["order_date"]}
                                                            minDate={item.name === "order_date" ? false : moment().toDate()}
                                                        />
                                                    </LocalizationProvider>

                                                ) : item.type === "file" ? (<>
                                                    <button style={{ margin: 0, width: "70%", height: "43px" }} className="button btn-create" onClick={() => handleClickSelectFile()}>Select File</button>
                                                    <input
                                                        ref={fileInputRef}
                                                        style={{ display: "none" }}
                                                        type={item.type}
                                                        className="form-control"
                                                        // accept={item.mimes}
                                                        onChange={(event) => handleChangeFile(event, item)}
                                                        max={10}
                                                        multiple={true}
                                                    />
                                                    {inputValues["file"] && inputValues["file"].length !== 0 &&
                                                        inputValues["file"].map((element, index) => (
                                                            <div className="d-flex" style={{ whiteSpace: "nowrap", overflow: "auto", paddingBlock: "10px" }} key={element.name}>
                                                                <img src={FileSymbol} alt={"File"} />
                                                                <p className="file-name-title blue-text" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: 'ellipsis' }} >{element.name}</p>
                                                                <CancelRoundedIcon className="close-round" onClick={() => removeUploadFile(index, item)} />

                                                            </div>
                                                        ))
                                                    }
                                                </>
                                                ) : item.type === "checkbox" ? (
                                                    <FormControl>
                                                        <FormGroup
                                                            value={inputValues[item.name]}
                                                            onChange={(event) => handleChange(event, item)}
                                                            sx={{ marginTop: "-10px", height: "40px", whiteSpace: 'nowrap' }}
                                                        >
                                                            {item.options.map((item) => (
                                                                <FormControlLabel value={item.value} key={item.label} control={<Checkbox />} label={item.label} />
                                                            ))}
                                                        </FormGroup>
                                                    </FormControl>
                                                ) : item.type === "radio" ? (
                                                    <FormControl>
                                                        <RadioGroup
                                                            row
                                                            value={inputValues[item.label]}
                                                            defaultValue={item.options[0]?.value}
                                                            sx={{ height: "40px", marginTop: "-10px" }}
                                                            onChange={(event) => handleChange(event, item)}
                                                        >
                                                            {item?.options?.map((option, index) =>
                                                                <FormControlLabel value={option?.value} control={<Radio />} sx={{ marginRight: '35px' }} label={option.label} />
                                                            )}
                                                        </RadioGroup>
                                                    </FormControl>
                                                ) : item.type === "selectMulti" ? (
                                                    <Select
                                                        value={getValueForSelect(item)}
                                                        onChange={(selectedOption) => {
                                                            handleChangeSelect(selectedOption, item);
                                                            handleChange(selectedOption, item);
                                                        }}
                                                        options={item.options}
                                                        placeholder={item.name === "contact" ? "Input to display the list" : `Select ${item.label.toLowerCase()}`}
                                                        components={{ DropdownIndicator }}
                                                        styles={customStyles}
                                                        menuPortalTarget={menuPortalTarget}
                                                        isMulti
                                                        classNamePrefix="react-select"
                                                    />
                                                ) : (
                                                    <input
                                                        type={item.type}
                                                        style={{ height: "43px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: 'ellipsis' }}
                                                        className="form-control"
                                                        value={
                                                            inputValues[item.name] ??
                                                            ""
                                                        }
                                                        onChange={(event) => handleChange(event, item)}
                                                        disabled={item.disabled}
                                                    />
                                                )}
                                                {errors[item.label] && !item?.hideError && (
                                                    <span
                                                        className="error-text"
                                                        style={{
                                                            color: "red",
                                                            position: "relative",
                                                            bottom: "10px",
                                                            top: "5px"
                                                        }}
                                                    >
                                                        {errors[item.label]}
                                                    </span>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {isEdit && quotation && (<div className="input-table">
                        <div className="d-flex" style={{ justifyContent: "space-between", marginBottom: "20px" }}>
                            <p className="bread-crumb" style={{ fontSize: "16px" }}>{t("Proposal")}</p>
                        </div>
                        <div className="proposal-container">
                            <Table
                                disableCheckbox
                                data={listProposal ?? []}
                                columns={columns}
                                handleRowClick={(row) => setIsOpenModalProposal({ isOpen: true, proposal: row })}
                                setLoading={setLoading}
                            />
                        </div>

                    </div>)}
                    <div className="buttons">
                        {isEdit && isEditCost ? <button
                            className="button btn-create"
                            onClick={() => setIsModalNoteEditCost(true)}
                            disabled={isDisabled}
                        >
                            Save
                        </button> : isEdit ? <button
                            className="button btn-create"
                            onClick={() => handleUpdateQuotation()}
                            disabled={isDisabled}
                        >
                            Save
                        </button> : (
                            <button
                                className="button btn-create"
                                onClick={() => handleCreateQuotation()}
                                disabled={disable}
                            >
                                {t('create')}
                            </button>
                        )}
                        <button className="button btn-cancel" onClick={() => handleCancel()}>
                            {t('cancel')}
                        </button>
                    </div>
                </div>
            </div>
            {selectedNoteEdit && (
                <Modal
                    title="Note"
                    initialValues={selectedNoteEdit}
                    isOpen={isOpenEditModal}
                    onClose={() => {
                        setIsOpenEditModal(false);
                        setSelectedNoteEdit(null);
                    }}
                    onLoadingEnd={() => {
                        setLoading(false);
                    }}
                    onSubmit={handleEdit}
                />
            )}
            {selectedNoteDelete && (
                <ModalDelete
                    isOpen={isOpenDeleteModal}
                    title={'Confirmation Delete'}
                    onClose={() => {
                        setIsOpenDeleteModal(false);
                        setSelectedNoteDelete(null);
                    }}
                    name='note'
                    useConfirm={true}
                    type={'filter'}
                    onConfirm={() => handleDelete(selectedNoteDelete)}
                />
            )}
            {isOpenModalNote && !loading && (
                <ModalNote
                    title="Note"
                    isOpen={isOpenModalNote}
                    note={true}
                    onConfirm={(note) => handleUpdateQuotation(note)}
                    onClose={() => {
                        setIsOpenModalNote(false);
                        setInputValues({ ...inputValues, status: quotation.status_id });
                        setSelectedOptions({ ...selectedOptions, status: { value: quotation.status.id, label: quotation.status.name } });
                    }}
                    onLoadingEnd={() => {
                        setLoading(false);
                    }}
                />
            )}
            {isOpenModalProposal.isOpen && (
                <ModalProposal
                    isOpen={isOpenModalProposal.isOpen}
                    onClose={() => setIsOpenModalProposal({ isOpen: false, proposal: {} })}
                    proposalId={isOpenModalProposal.proposal?.id}
                    title={isOpenModalProposal.proposal?.name}
                    onEdit={() => handleEditProposal()}
                />
            )}
            {toast.show && (
                <Toast
                    message={toast.message}
                    duration={3000}
                    error={toast.error}
                    onClose={() => {
                        setToast({ show: false });
                    }}
                />
            )}
            {isModalNoteEditCost && (
                <ModalNote
                    title="Cost Adjustment Reasons"
                    isOpen={isModalNoteEditCost}
                    noteEstimateCost={true}
                    onConfirm={(note) => handleUpdateQuotation(note)}
                    onClose={handleCloseModalCostAdjustmentReasons}
                    onLoadingEnd={() => {
                        setLoading(false);
                    }}
                />
            )}
        </div>
    );
}

export default Quotation;
