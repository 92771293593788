// const host = process.env.REACT_APP_API_URL;
const host = "https://crm.deha.vn:90/api/";
const hostProposal = process.env.REACT_APP_HOST_PROPOSAL;
const hostMattermost = 'https://talk.deha-soft.com/'

const API = {
  'listContacts': host + 'contact',
  'createContact': host + 'contact',
  'updateContact': host + 'contact',
  'getContactById': host + 'getContactById',
  'getContactsByDealId': host + 'contact/getContactsByDealId',
  'urlLoginGoogle': host + 'auth/google/url',
  'callbackLoginGoogle': host + 'auth/google/callback',
  'importCsv': host + 'importContactByCsv',
  'importCsvToBlacklist': host + 'importContactToBlacklist',
  'contactList': host + 'contactList',
  'allContactList': host + 'allContactList',
  'createContactList': host + 'contactList',
  'updateContactForm': (formId) => host + `contactForm/${formId}/result`,
  'getContactFormEndpoint': host + 'contactForm/getContact',
  'users': host + 'user',
  'listContactForm': host + 'contactForm',
  'getContactList': host + 'contactList',
  'getContactListDetail': host + 'contactList/contacts',
  'storeFilter': host + 'filter/store',
  'updateFilter': host + 'filter/update',
  'listFilter': host + 'filter',
  'showFilter': host + 'filter/show',
  'deleteFilter': host + 'filter/destroy',
  'updateList': host + 'contactList',
  'getColumnsAllowImport': host + 'contact/columns-allow',
  'getReport': host + 'report',
  'updateContactStage': host + 'contact/updateStage',
  'listNotes': host + 'note',
  'uploadNote': host + 'note/upload',
  'createNote': host + 'note/store',
  'updateNote': host + 'note/update',
  'deleteNote': host + 'note/destroy',
  'storeContactPoint': host + 'contactPoint',
  'storeContactPointByDeal': host + 'contactPointByDeal',
  'listContactPoint': host + 'contact/contactPoints',
  'allContactPoint': host + 'allcontactPoint',
  'updateContactPoint': host + 'contactPoint',
  'storeExistingContactPoint': host + 'contact/addExistingContactPoints',
  'storeExistingContactPointByDeal': host + 'contact/addExistingContactPointsByDeal',
  'deleteExistingContactPoint': host + 'contact/removeExistingContactPoints',
  'storeExistingDeal': host + 'contact/addExistingDeals',
  'deleteExistingDeal': host + 'contact/removeExistingDeals',
  'getDealsForInput': host + 'deal/inputDeal',
  'listUsers': host + 'user',
  'unitUser': host + 'user/unit',
  'createTask': host + 'task/store',
  'listTasks': host + 'task/listById',
  'listLogsTask': host + 'task',
  'updateTask': host + 'task/update',
  'updateResultTask': host + 'task/updateResult',
  'getUnits': host + 'units',
  'getCurrencies': host + 'currencies',
  'deleteTask': host + 'task/destroy',
  'addToBlackList': host + 'contact/addToBlackList',
  'removeFromBlacklist': host + 'contact/removeFromBlacklist',
  'getContactListForSelect': host + 'contactList/getForSelect',
  'listTeam': host + 'team',
  'createTeam': host + 'team/store',
  'deleteTeam': host + "team/destroy",
  'getTeamById': host + 'team/show',
  'updateTeam': host + 'team/update',
  'taskType': host + 'taskTypes',
  'deleteContact': host + 'contact',
  'updateContactInTodoStatus': host + 'updateContactInTodoStatus',
  'listDeals': host + 'deal',
  'getDealById': host + 'deal/show',
  'stageDeals': host + 'stageDeals',
  'createDeal': host + 'deal/store',
  'getContactsForInput': host + 'contact/inputContact',
  'getContactsPointForInput': host + 'contactPoint/inputContactPoint',
  'getTeamsForInput': host + 'team/inputTeam',
  'getStageDeals': host + 'stageDeals',
  'addExistingContact': host + 'deal/contacts/add',
  'detachDealRelation': (relation) => host + `deal/${relation}/detach`,
  'addExistingContactPoint': host + 'deal/contactPoints/add',
  'updateDeal': host + 'deal/update',
  'getDealsByContact': host + 'deal/getByContact',
  'getAllDealsByContact': host + 'deal/getByContactForInput',
  'deleteDeal': host + 'deal/destroy',
  'getItemsByDealId': host + 'item/listByDealId',
  'taskList': host + 'task/list',
  'getTaskById': host + 'getTaskById',
  'getTaskType': host + 'taskTypes',
  'createOrUpdateItem': host + 'item/updateOrCreate',
  'addContactToList': host + 'contact/addToList',
  'listList': host + 'contact/showlists',
  'listHistories': (model_id) => host + `contact/${model_id}/log`,
  'getDetailLogById': host + 'log/getDetailById',
  'getDetailTaskLogById': host + 'log/getDetailTaskById',
  'storeDealByTask': host + 'deal/storeDealByTask',
  'showTask': host + 'task/show',
  'statusOrders': host + 'statusOrders',
  'createOrder': host + 'order/store',
  'editOrder': host + 'order/update',
  'listOders': host + 'order',
  'getOrderById': host + 'order/show',
  'deleteOrder': host + 'order/destroy',
  'getOrdersByStatusNew': host + 'order/getOrdersByStatusNew',
  'exportContactsFromLists': host + 'contact/export/fromList',
  'exportContacts': host + 'contact/export',
  'exportDetailContacts': host + 'contact/exportContact',
  'getSaleChannel': hostProposal + 'v1/sale-channels',
  'getApplicationTypes': hostProposal + 'v1/application-types',
  'getProjectProfession': hostProposal + 'v1/project-professions',
  'getTechnology': hostProposal + 'v1/programming-languages',
  'getExportCount': host + 'contact/export/getCount',
  'createProposal': hostProposal + 'v1/proposals',
  'showProposal': hostProposal + 'v1/proposals/show',
  'downloadFileProposal': hostProposal + 'v1/download',
  'getLastProposal': host + 'quotation/lastProposal',
  'addProposal': host + 'quotation/addProposal',
  'getAllOrderByContact': host + 'order/inputOrder',
  'downloadFileOrder': host + 'download',
  'exchangerate': host + 'exchangerate',
  'listDealHistories': (deal_id) => host + `deal/${deal_id}/log`,
  "listIndustries": host + 'industries',
  "listCompanyClassifications": host + 'companyClassifications',
  "getQuestionsMeeting": host + "meeting/question",
  "createMeeting": host + "meeting/create",
  "updateMeeting": host + "meeting/update",
  'getMeetingListByContact': host + 'meeting/listByContact',
  'getMeetingListInputByContact': host + 'meeting/inputMeeting',
  'getMeetingDetail': host + 'meeting/show',
  'statusQuotations': host + 'statusQuotations',
  'createQuotation': host + "quotation/store",
  'products': host + 'products',
  'getQuotationsByStatusEstimating': host + 'quotation/getQuotationsByStatusEstimating',
  'getQuotation': host + 'quotation/show',
  'updateQuotation': host + 'quotation/update',
  'editProposal': host + 'quotation/editProposal',
  'listInvoices': host + 'invoice',
  'statusInvoices': host + 'statusInvoices',
  'updateAmountInvoice': (invoice_id) => host + `invoice/update-received-amount/${invoice_id}`,
  'createInvoices': host + 'invoice/store',
  'updateStatusInvoice': host + `invoice/updateStatus`,
  'deleteInvoices': host + `invoice/destroy`,
  'updateStatusInvoiceToSent': host + `invoice/updateStatusToSent`,
  'getInvoiceById': host + 'invoice/show',
  'updateInvoice': host + 'invoice/update',
  'myTask': host + 'task/myTask',
  'getQuotationsByStatusNegotiation': host + 'quotation/getQuotationsByStatusNegotiation',
  'contactListDelete': host + 'contactList/destroy',
  'getChannelsForInput': host + 'channel/inputChannel',
  'updateTaxInvoice': host + 'invoice/update-tax',
  'storeOrUpdateTarget': host + 'target/store-or-update',
  'storeOrUpdateTargetSale': host + 'target/store-or-update-sale',
  'getDataTarget': host + 'target',
  'shareContactPrivate': host + 'contact/shareContactPrivate',
  'getTotalRevenueByMonth': host + 'order/getTotalRevenueByMonth',
  'getReportOrderTable': host + 'order/getReportOrderTable',
  'updatePIC': host + 'contact/updatePIC',
  'exportRevenueFromLists': host + 'finance/revenue/export',
  'listEmailByContact': (model_id) => host + `contact/${model_id}/email`,
  'getHelpChannel': hostMattermost + 'test/channels/thuong-de-dx',
  'getInvoiceCode': host + 'invoice/save-invoice-code',
  'removeInvoiceCode': host + 'invoice/remove-invoice-code',
  'getUncheckMonthByUser': host + 'user/getUncheckMonth',
  'updateUncheckMonthByUser': host + 'user/updateUncheckMonth',
  'getLogOrder': host + 'order-logs',
  'getAllInvoiceCode': host + 'invoice/get-invoice-code',
  'getAllOrder': host + 'order/getAll',
};

export { API };
