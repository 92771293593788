import React, { useCallback, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import DropdownMenu from '../DropdownMenu';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authUserSelector } from '../../redux/auth/auth.selectors';
import { logoutAction, saveUserInfoAction } from '../../redux/auth/auth.actions';
import './index.css';
import Img from '../Image';
import { API } from "../../utils/api";
import { getUserInfo } from "../../redux/auth/auth.service";
import { useNavigate } from "react-router-dom";
import { setAuthInfo } from "../../redux/auth/auth.slice";
import LanguageSwitcher from "../Buttons/LanguageSwitcher";

const pages = [
    {
        label: "dashboard",
        path: "/dashboard",
        role: ["marketing_manager"],
        options: [],
    },
    {
        label: "contact_menu",
        path: "/contacts",
        role: ["marketing_manager"],
        options: [
            { label: "contact_menu", path: "contacts", role: ["marketing_manager"] },
            { label: "list", path: "contacts-list", role: ["marketing_manager"] },
        ],
    },
    {
        label: "contact_form",
        path: "/contact-form",
        role: ["marketing_manager", "marketer"],
        options: [],
    },
    {
        label: "sale",
        path: "/deals",
        role: ["marketing_manager"],
        options: [
            { label: "deal", path: "deals", role: ["marketing_manager"] },
            { label: "task", path: "tasks", role: ["marketing_manager"] },
            { label: "order", path: "orders", role: ["marketing_manager"] },
            { label: "invoice", path: "invoices", role: ["marketing_manager"] },
        ],
    },
    {
        label: "finance",
        path: "/revenue",
        role: ["marketing_manager"],
        options: [
            { label: "revenue", path: "revenue", role: ["marketing_manager"] }
        ],
    },
    {
        label: "report",
        path: "/reports",
        role: ["marketing_manager"],
        options: [
            { label: "report", path: "reports", role: ["marketing_manager"] },
        ],
    },
    {
        label: "setting",
        path: "/teams",
        role: ["marketing_manager"],
        options: [
            { label: "team", path: "teams", role: ["marketing_manager"] },
            { label: "target", path: "target", role: ["marketing_manager"] }
        ],
    },
    {
        label: "History log",
        path: "/history-logs",
        role: ["marketing_manager"],
        options: [],
    },
    {
        label: "help",
        path: API.getHelpChannel,
        role: ["marketing_manager", "marketer"],
        options: [],
    },
];

const settings = [
    // {label: 'Profile', path: '/profile', options: []},
    // {label: 'Dashboard', path: '/dashboard', options: []},
];
const toolbarStyle = {
    minHeight: '50px',
};

function NavBar() {
    const { t } = useTranslation();
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const authUser = useSelector(authUserSelector);
    const dispatch = useDispatch();
    const isMatchLogin = useMatch('/login');
    const location = useLocation();
    const navigate = useNavigate();
    const userInfo = JSON.parse(localStorage.getItem('user_info'));
    const handleOpenNavMenu = useCallback(
        (event) => {
            setAnchorElNav(event.currentTarget);
        },
        [setAnchorElNav]
    );
    const handleOpenUserMenu = useCallback(
        (event) => {
            setAnchorElUser(event.currentTarget);
        },
        [setAnchorElUser]
    );

    const handleCloseNavMenu = useCallback(() => {
        setAnchorElNav(null);
    }, [setAnchorElNav]);

    const handleCloseUserMenu = useCallback(() => {
        setAnchorElUser(null);
    }, [setAnchorElUser]);

    const handleLogout = useCallback(() => {
        handleCloseUserMenu();
        dispatch(logoutAction());
        loadUserInfoFromLocalStorage().then().catch();
    }, [dispatch, handleCloseUserMenu]);

    const loadUserInfoFromLocalStorage = useCallback(async () => {
        const userInfo = await getUserInfo();
        if (!userInfo) navigate('/login');
        else if (userInfo && userInfo.role === null) {
            handleLogout();
        }
        else {
            await dispatch(setAuthInfo(userInfo))
        }
    }, [dispatch, navigate]);

    useEffect(() => {
        loadUserInfoFromLocalStorage().then().catch();
    }, [dispatch, loadUserInfoFromLocalStorage]);

    return (
        <AppBar position="static" style={{ backgroundColor: '#5F89BA', display: userInfo ? 'block' : 'none' }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters style={toolbarStyle}>
                    <Link to="/">

                    </Link><Img src="deha-vietnam-logo_w.webp" alt="Deha Vietnam logo"
                        style={{ width: '60px', marginBottom: '5px', marginRight: '10px' }} />
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} className="nav-bar">
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page, index) => (
                                <DropdownMenu key={index} page={page}
                                    onClose={() => setAnchorElNav(null)}></DropdownMenu>
                            ))}
                        </Menu>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} className="nav-bar">
                        {pages.map((page, index) => (
                            <DropdownMenu key={index} page={page}
                                onClose={() => setAnchorElNav(null)}></DropdownMenu>
                        ))}
                    </Box>

                    {authUser ? (
                        <>
                            <LanguageSwitcher />
                            <Box sx={{ flexGrow: 0 }}>

                                <IconButton className="current-user" onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar alt="Remy Sharp" src={authUser.avatar} style={{ width: '35px', height: '35px' }} />
                                    <Typography ml={1} className="text-white" style={{ fontSize: '0.9rem' }} >
                                        {authUser.name}
                                    </Typography>
                                </IconButton>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {settings.map((setting) => (
                                        <MenuItem key={setting.label}>
                                            <Link to={{ pathname: setting.path }} onClick={handleCloseUserMenu}>
                                                <Typography textAlign="center">{t(setting.label)}</Typography>
                                            </Link>
                                        </MenuItem>
                                    ))}
                                    <MenuItem key="logout" onClick={handleLogout}>
                                        <Typography textAlign="center">{t('logout')}</Typography>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        </>

                    ) : (
                        !isMatchLogin && (
                            <Box>
                                <Button variant="text" href="/login">
                                    <Avatar alt="Remy Sharp"
                                    />
                                    <Typography className="text-white"
                                        sx={{ ml: 2 }}
                                    >{t('Login')
                                        }</Typography>

                                </Button>
                            </Box>
                        )
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default NavBar;
