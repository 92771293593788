/* eslint-disable max-len */
import {createSlice} from '@reduxjs/toolkit';
import {API} from '../utils/api';
import {getTypeForOption, makeURLSearchParams} from '../utils/helper';
import {createAsyncThunk} from '@reduxjs/toolkit';
import request from '../services/client';

const initialState = {
  contacts: [],
  loading: false,
  page: 1,
  total: 0,
  from: 0,
  to: 0,
  limit: 50,
  last_page: 1,
  search_option: {
    lists: [],
    result: [],
    createDate: [],
    lastUpdated: [],
    text: [],
  },
  selectedTab: 'assign_me',
  error: null,
};

const contactFormSlice = createSlice({
  name: 'contactForm',
  initialState,
  reducers: {
    getContactsStart: (state) => {
      state.loading = true;
    },
    getContactsSuccess: (state, action) => {
      state.loading = false;
      state.page = action.payload.data.current_page;
      state.total = action.payload.data.total;
      state.contacts = action.payload.data.data;
      state.from = action.payload.data.from;
      state.to = action.payload.data.to;
      state.last_page = action.payload.data.last_page;
    },
    getContactsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateContactStart: (state) => {
      state.loading = true;
    },
    updateContactSuccess: (state, action) => {
      state.loading = false;
      const updatedContact = action.payload;
      // eslint-disable-next-line max-len
      const contactIndex = state.contacts.findIndex((contact) => contact.id === updatedContact.id);
      state.contacts[contactIndex] = updatedContact;
    },
    updateContactFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createContactStart: (state) => {
      state.loading = true;
    },
    createContactSuccess: (state, action) => {
      state.loading = false;
      state.contacts.push(action.payload);
    },
    createContactFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateOptionSearch: (state, action) => {
      const data = action.payload;
      const type = data.type ? data.type : getTypeForOption(data.option);
      if (type === 'text') {
        state.search_option = {
          ...state.search_option,
          'text': data.option ? [data.option] : [],
        };
        return;
      }
      if (type === "lists") {
        state.search_option = {
          ...state.search_option,
          [type]: state.search_option[type].map(item => item.id).includes(data.option.id) ?
            state.search_option[type].filter((value) => value.id !== data.option.id) :
            [...state.search_option[type], data.option],
        };
        return;
      }
      if (type === "result") {
        state.search_option = {
          ...state.search_option,
          [type]: state.search_option[type].includes(data.option) ?
            state.search_option[type].filter((value) => value !== data.option) :
            [...state.search_option[type], data.option],
        };
        return;
      } else if (type === "createDate" || type === "lastUpdated") {
        state.search_option = {
          ...state.search_option,
          [type]: [data.option],
        };
        return;
      } else {
        state.search_option = {
          ...state.search_option,
          [type]: state.search_option[type].includes(data.option) ?
            state.search_option[type].filter((value) => value !== data.option) :
            [...state.search_option[type], data.option],
        };
      }
    },
    deleteOptionSearch: (state, action) => {
      const category = action.payload;

      state.search_option = {
        ...state.search_option,
        [category]: [],
      };
    },
    setLimit: (state, action) => {
      state.limit = action.payload
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload
    },
  },
});

export const {
  getContactsStart,
  getContactsSuccess,
  getContactsFailure,
  updateContactStart,
  updateContactSuccess,
  updateContactFailure,
  createContactStart,
  createContactSuccess,
  createContactFailure,
  updateOptionSearch,
  deleteOptionSearch,
  setLimit,
  setSelectedTab
} = contactFormSlice.actions;

export const fetchContactForm = (page = 1, tab) => async (dispatch, getState) => {
  const searchOption = getState().contactForm.search_option;
  const limit = getState().contactForm.limit;

  try {
    dispatch(getContactsStart());
    const params = {
      page: page,
      flag: tab,
      limit: limit,
    };

    if (searchOption) {
      const { lists, result, createDate, lastUpdated, text } = searchOption;
      if (lists?.length > 0) {
        params.contact_list_id = lists.map(item => item.id).join(',').toLowerCase();
      }
      if (result?.length > 0) {
        params.result = result.map(item => item).join(',').toLowerCase();
      }
      if (createDate?.length > 0) {       
        params.create_date_start = createDate[0].startDate;
        params.create_date_start = createDate[0].endDate;
      }
      if (lastUpdated?.length > 0) {
        params.update_date_start = lastUpdated[0].startDate;
        params.update_date_end = lastUpdated[0].endDate;
      }
      if (text?.length > 0) {
        params.search = text.join(',').toLowerCase();
      }
    }
    const query = makeURLSearchParams(params);
    const url = `${API.listContactForm}${query}`;
    const response = await request(url);
    const responseJson = await response.json();
    dispatch(getContactsSuccess(responseJson));
  } catch (error) {
    dispatch(getContactsFailure(error.message));
  }
};

export const updateContactFormResult = (formId, formData) => async (dispatch) => {
  const contactFormData = new FormData();
  formData['result'] && contactFormData.append('result', formData['result']);
  formData['note'] && contactFormData.append('note', formData['note']);
  formData['image'] && contactFormData.append('image', formData['image']);

  try {
    const response = await request(API.updateContactForm(formId), {
      method: 'POST',
      body: contactFormData,
    });
    if (response.ok) {
      const data = await response.json();
    } else {
      dispatch(updateContactFailure(`API returned status code ${response.status}`));
    }
  } catch (error) {
    dispatch(updateContactFailure(error.message));
  }
};


export const getContactList = ({list, input_number}) => async (dispatch) => {
  const contactListId = list;
  const number = input_number;

  try {
    const response = await request(API.getContactFormEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        contact_list_id: contactListId,
        number: number,
      }),
    });

    if (response.ok) {
    } else {
      dispatch(getContactsFailure(`API returned status code ${response.status}`));
    }
  } catch (error) {
    dispatch(getContactsFailure(error.message));
  }
};


export const selectSearch = (option, type) => async (dispatch) => {
  const data = {
    option,
    type
  }
  try {
    dispatch(updateOptionSearch(data));
  } catch (error) {
    //
  }
};

export const removeSearch = (category) => async (dispatch) => {
  try {
    dispatch(deleteOptionSearch(category));
  } catch (error) {
    //
  }
};

export const setPageSize = (data) => async (dispatch) => {
  try {
    dispatch(setLimit(data));
  } catch (error) {
    //
  }
};

export const setSelectTab = (data) => async (dispatch) => {
  try {
    dispatch(setSelectedTab(data));
  } catch (error) {
    //
  }
};

export default contactFormSlice;
