import React, { useCallback, useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import Dropdown from '../../../../components/Dropdown';
import ArrowDownIconOutlined from '@mui/icons-material/KeyboardArrowDown';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import ModeEditOutlineIcon from '@mui/icons-material/Mode';
import Modal from '../../../../components/ModalTask';
import { useDispatch, useSelector } from 'react-redux';
import HTMLReactParser from 'html-react-parser';
import moment from 'moment';
import "./content-styles.css";
import Toast from "../../../../components/ToastMessage";
import { useTranslation } from 'react-i18next';
import { authUserSelector } from '../../../../redux/auth/auth.selectors';
import { createTask, deleteTask, fetchTasks, getDetailTaskLog, getLogs, getTaskTypeList, resetError, resetTaskValue, resetTasks, selectSearch, updateOptionSearch, updateResult, updateTask } from '../../../../store/tasksSlice';
import { Chip, Stack, debounce } from '@mui/material';
import ModalDelete from "../../../../components/ModalCenter"
import TaskComment from './TaskComment';
import Button from '../../../../components/Buttons/BaseButton';
import ModalCenter from '../../../../components/ModalCenter/index';
import ModalRight from '../../../../components/ModalRight';
import { ACTION_TASKS_LOG, DEAL_FIELD } from '../../../../utils/constant';
import ModalDeal from '../../../../components/ModalDeal';
import { createDealByTask, getAllDealsByContact, getDealById } from '../../../../store/dealsSlice';
import { replacedContent } from '../../../../utils/helper';
import ModalHistory from '../../../../components/ModalHistory/index';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllOrderByContact, getOrdersByStatusNew, resetNotification } from '../../../../store/ordersSlice';
import ModalProposal from "../../../../components/ModalProposal"
import { set } from 'react-hook-form';
import NoData from "../../../../components/SkeletonLoading";
import { getProposal } from '../../../../store/proposalSlice';
import { getQuotation } from '../../../../store/quotationsSlice';

function TaskTab({ contact, results, setReloadDealAt, currency, taskNavigate, reLoadContactData }) {
  const { t } = useTranslation();
  const authUser = useSelector(authUserSelector);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const tasks = useSelector((state) => state.tasks.tasks);
  const taskTypes = useSelector((state) => state.tasks.taskTypes);
  const [page, setPage] = useState(1);
  const lastPage = useSelector((state) => state.tasks.last_page);
  const loadMoreRef = useRef();
  const [searchText, setSearchText] = useState('');
  const error = useSelector((state) => state.tasks.error)
  const [toast, setToast] = useState(false);
  const [type, setType] = useState({ 'id': 0, 'name': t('all') });
  const [selectedTaskEdit, setSelectedTaskEdit] = useState(null);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [selectedTaskDelete, setSelectedTaskDelete] = useState(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [modalCreateDeal, setModalCreateDeal] = useState(false);
  const [idTasks, setIdTasks] = useState([]);
  const [changeResult, setChangeResult] = useState([]);
  const [idUpdateResult, setIdUpdateResult] = useState(null);
  const [currentResultChange, setCurrentResultChange] = useState(null);
  const [dealFieldCreate, setDealFieldCreate] = useState(DEAL_FIELD);
  const contactPoints = useSelector((state) => state.contactDetail.contact_points);
  const [currentUserAssignTo, setCurrentUserAssignTo] = useState(null);
  const [expandTask, setExpandTask] = useState([]);
  const [beforeResultChange, setBeforeResultChange] = useState(null);
  const navigate = useNavigate();
  let { state } = useLocation();
  const location = useLocation();

  const [listDeals, setListDeals] = useState([]);
  const [proposalData, setProposalData] = useState({});
  const [listOrders, setListOrders] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const logsTask = useSelector((state) => state.tasks.logs);
  const [isOpenModalHistory, setIsOpenModalHistory] = useState(false);
  const [detailDescription, setDetailDescription] = useState(null);
  const [isScroll, setIsScroll] = useState(true);
  const notification = useSelector((state) => state.orders.notification);
  const [isOpenModalCreateProposal, setIsOpenModalCreateProposal] = useState(false);

  // callback function when the loadMoreRef intersects the viewport
  const handleLoadMoreIntersection = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting && !loading && page < lastPage) {
      setLoading(true);
      setPage((prevPage) => prevPage + 1);
    }
  }
  const fetchDeals = async () => {
    try {
      const data = await dispatch(getAllDealsByContact(contact.id));
      setListDeals(data?.data ?? []);
    } catch (error) {
      setListDeals([]);
    }
  }

  const fetchOrders = async () => {
    try {
      const data = await dispatch(getOrdersByStatusNew(contact.id));
      setListOrders(data?.data ?? []);
    } catch (error) {
      setListOrders([]);
    }
  }
  // observe the loadMoreRef using IntersectionObserver

  useEffect(() => {
    return () => {
      dispatch(resetTaskValue());
    }
  }, [])

  useEffect(() => {
    if (state === "quotation") {
      setToast({
        show: true,
        message: "Quotation created successfully",
      });
      navigate(location.pathname, { replace: true });
    } else if (state?.quotation) {
      setToast({
        show: true,
        message: `Quotation "${state.quotation}" updated successfully`,
      });
      navigate(location.pathname, { replace: true });
    }
  }, [state]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };
    const observer = new IntersectionObserver(
      handleLoadMoreIntersection,
      options
    );
    if (loadMoreRef.current) observer.observe(loadMoreRef.current);
    return () => observer.disconnect();
  }, [loading, handleLoadMoreIntersection]);

  const fetchAllTask = async (value = null) => {
    await dispatch(fetchTasks(value ? value : contact.id, type))
      .then((res) => {
        setLoading(false);
        let array = [...expandTask]

        if (taskNavigate) {
          res.data.data.map((item, index) => { item.can_update_task_result === false && item.id !== taskNavigate ? array[index] = false : array[index] = true });
        } else {
          res.data.data.map((item, index) => { item.can_update_task_result === false ? array[index] = false : array[index] = true });
        }
        setExpandTask([...array]);
      })
      .catch(() => setLoading(false));
  }
  useEffect(() => {
    setLoading(true);
    if (page === 1) {
      resetTasks();
    }
    dispatch(updateOptionSearch({ option: '', type: 'text' }));
  }, [dispatch, page]);

  useEffect(() => {
    if (error.length) {
      setToast({ show: true, error: true, message: error });
      setLoading(false);
      dispatch(resetError());
    }
  }, [dispatch, error]);

  useEffect(() => {
    dispatch(getTaskTypeList());
    fetchAllTask();
  }, [dispatch, type]);

  const scroll = useCallback(
    debounce((taskNavigate) => {
      const element = document.getElementById(`task_${taskNavigate}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      setIsScroll(false);
    }, 500),
    []
  );

  useEffect(() => {
    if (notification !== "") {
      setToast({ show: true, message: notification })
      dispatch(resetNotification());
    }
  }, [dispatch])

  useEffect(() => {
    if (isScroll && logsTask.length && taskNavigate) {
      scroll(taskNavigate);
      setIsScroll(false);
    }
  }, [logsTask]);

  const getLogforTask = async (item, index) => {
    await dispatch(getLogs(item.id, index));
  }

  useEffect(() => {
    let array = [...expandTask]
    tasks.forEach((item, index) => { item.can_update_task_result === false ? array[index] = false : array[index] = true });
    if (tasks.length !== expandTask.length) {
      setExpandTask([...array]);
    }
    if (tasks) {
      tasks.forEach((item, index) => {
        if (array[index]) {
          getLogforTask(item, index);
        }
      })
    }
  }, [tasks]);

  const handleCreate = async (task) => {
    setLoading(true);
    let createSuccess = await dispatch(createTask(task));
    if (createSuccess) {
      setToast({ show: true, message: t("Task created successfully") });
    }
    setIsOpenCreateModal(false);
    fetchAllTask();
    setLoading(false);
    setIsDisabled(false);
  };

  const handleEditClick = (item) => {
    setSelectedTaskEdit(item);
    setIsOpenEditModal(true);
    fetchDeals();
    fetchOrders();
  };
  const handleEdit = async (task, action, assignUser) => {
    setLoading(true);
    let updateSuccess = await dispatch(updateTask(task));
    if (updateSuccess) {
      setToast({ show: true, message: t('Task updated successfully') });
      if (task && (task?.task_result_id === 13 || task?.task_result_id === 14 || task?.task_result_id === 15)) {
        setReloadDealAt((new Date()).getTime());
      }
      if (action && action === "open_create_deal_modal") {
        reLoadContactData();
        setModalCreateDeal(true);
        setCurrentUserAssignTo(assignUser);
      } else if (action && action === "open_create_order_modal") {
        let item = tasks.find((x) => x.id === task.id);
        let createForTask = {
          contact: item.taskable,
          deal: item.referenceable,
          currency: currency
        }
        setTimeout(() => {
          navigate('/orders/create', { state: { createForTask } });
        }, 2000);
        sessionStorage.setItem("from_detail_deal", `orders`);
      }

      if (task.task_result_id === 22) {
        if (updateSuccess?.payload?.data?.quotation?.option_id == 1) {
          setIsOpenEditModal(false);
          setLoading(true);
          const proposal = await dispatch(getProposal(task.quotation_id));
          let result = proposal.data;
          result.quotation_id = task.quotation_id;
          setProposalData(result);
          setIsOpenModalCreateProposal(true);
          setLoading(false);
        } else {
          navigate(`/quotation/edit/${task?.quotation_id}?taskId=${task.id}`, { state: "reEstimate" });
        }
      }
    }
    setSelectedTaskEdit(null);
    setIsOpenEditModal(false);
    fetchAllTask();
    setIsDisabled(false);
    setLoading(false);
  };
  const handleDeleteClick = (item) => {
    setSelectedTaskDelete(item);
    setIsOpenDeleteModal(true);
  };
  const handleDelete = async (task) => {
    setLoading(true);
    let deleteSuccess = await dispatch(deleteTask(task));
    if (deleteSuccess) {
      setToast({ show: true, message: t('Task removed successfully') });
    }
    setSelectedTaskDelete(null);
    setIsOpenDeleteModal(false);
    setLoading(false);
  };
  const arrowIcon = (
    <ModeEditOutlineIcon
      style={{
        marginRight: "0.2em",
        color: "#5f89ba",
        height: "17px"
      }}
    ></ModeEditOutlineIcon>
  );
  const callAllContactPoint = async (value) => {
    try {
      setPage(1);
      dispatch(selectSearch(value.search, "text"));
      dispatch(fetchTasks(value.contact_id, type));
    } catch (error) {
      console.log(error);
    }
  };

  const debounceDropDown = useCallback(
    debounce((nextValue) => callAllContactPoint(nextValue), 1000),
    []
  );

  const handleSearch = async (event) => {
    setSearchText(event.target.value);
    const value = {
      contact_id: contact.id,
      search: event.target.value,
    };
    debounceDropDown(value);
  };

  const handleGetListResult = (taskName) => {
    let item = results?.filter((x) => x.name === taskName);
    return item[0]?.value ?? [];
  }

  const handleGetResult = (taskId, currentResult) => {
    if (idTasks.includes(taskId)) {
      let item = changeResult.filter((x) => x.id === taskId);
      return item[0].value.name;
    } else {
      return currentResult;
    }
  }

  const handleShowBtn = (e, taskId, assignUser, value) => {
    if (value.name === "Doing") {
      let newIdTask = idTasks.filter((x) => x != taskId);
      let newChangeResult = changeResult.filter((x) => x.id != taskId);
      setIdTasks(newIdTask);
      setChangeResult(newChangeResult);
      return;
    }
    if (idTasks.includes(taskId)) {
      let newList = changeResult.map((item) => {
        if (item.id === taskId) {
          item.value = value;
        }
        return item;
      })
      setChangeResult(newList);
    } else {
      setChangeResult([...changeResult, { id: taskId, value: value, user: assignUser }]);
    }
    setIdTasks([...idTasks, taskId]);
  }
  const handleOpenModalChangeResult = async (task) => {
    setLoading(true);
    let item = changeResult.filter((x) => x.id === task.id);
    if (task.task_type.name.toLowerCase() === "negotiating with customer" && item[0].value.name.toLowerCase() === "re-estimate") {
      if (task?.quotation?.option_id == 1) {
        const proposal = await dispatch(getProposal(task.quotation.id));
        let result = proposal.data;
        result.quotation_id = task.quotation_id;
        setProposalData(result);
      } else {
        setProposalData({ isLabo: task });
      }
      setBeforeResultChange(task.task_result.id);
    }
    setIdUpdateResult(task.id);
    setCurrentResultChange(item[0].value);
    setCurrentUserAssignTo(item[0].user);
    setLoading(false);
  }

  const handleCloseConfirmUpdateResult = () => {
    let newIdTask = idTasks.filter((x) => x !== idUpdateResult);
    let newChangeResult = changeResult.filter((x) => x.id !== idUpdateResult);
    setIdTasks(newIdTask);
    setChangeResult(newChangeResult);
    setCurrentResultChange(null);
    setIdUpdateResult(null);
  }
  const handleUpdateResult = async () => {
    setIsDisabled(true);
    if (beforeResultChange != currentResultChange.id) {
      let obj = {
        id: idUpdateResult,
        task_result_id: currentResultChange.id
      }
      setLoading(true);
      let updateSuccess = await dispatch(updateResult(obj));
      if (updateSuccess) {
        setToast({ show: true, message: t('Task updated successfully') });
      }
      fetchAllTask();
      setLoading(false);
      if (currentResultChange && (currentResultChange.id === 13 || currentResultChange.id === 14 || currentResultChange.id === 15)) {
        setReloadDealAt((new Date()).getTime());
      }
      if (currentResultChange && currentResultChange.action === "open_create_deal_modal") {
        reLoadContactData();
        handleCloseConfirmUpdateResult();
        setModalCreateDeal(true);
      } else if (currentResultChange && currentResultChange.action === "open_create_order_modal") {
        let item = tasks.find((x) => x.id === idUpdateResult);
        let createForTask = {
          contact: item.taskable,
          deal: item.referenceable,
          currency: currency
        }
        setTimeout(() => {
          navigate('/orders/create', { state: { createForTask } });
        }, 2000);
        sessionStorage.setItem("from_detail_deal", `orders`);
      } else if (updateSuccess && currentResultChange && currentResultChange.name.toLowerCase() === "re-estimate") {
        if (proposalData.isLabo) {
          navigate(`/quotation/edit/${proposalData.isLabo?.quotation_id}?taskId=${proposalData.isLabo.id}`, { state: "reEstimate" });
        } else {
          setIsOpenModalCreateProposal(true);
        }
        handleCloseConfirmUpdateResult();
      } else if (updateSuccess && currentResultChange) {
        reLoadContactData();
        handleCloseConfirmUpdateResult();
      } else {
        handleCloseConfirmUpdateResult();
      }
    } else if (proposalData.isLabo) {
      navigate(`/quotation/edit/${proposalData.isLabo?.quotation_id}?taskId=${proposalData.isLabo.id}`, { state: "reEstimate" });
    } else {
      setIsOpenModalCreateProposal(true);
    }

    setIsDisabled(false);
  }

  const handleExpandTask = (index, action) => {
    expandTask[index] = action
    setExpandTask([...expandTask]);
  }

  const handleCreateDeal = async (deal) => {
    setLoading(true);
    let createSuccess = await dispatch(createDealByTask(deal));
    if (createSuccess) {
      setToast({ show: true, message: t("Deal created successfully") });
      setModalCreateDeal(false);
      setReloadDealAt((new Date()).getTime());
      fetchAllTask();
    }
    setLoading(false);
  };

  const handleOpenModalHistory = async (taskId) => {
    try {
      const { data } = await dispatch(getDetailTaskLog(taskId));
      setDetailDescription(data);
      setIsOpenModalHistory(true);
    } catch (error) {
      //
    }
  }

  const handleCloseModalHistory = () => {
    setIsOpenModalHistory(false);
    setDetailDescription(null);
  }
  const getDealForCreate = () => {
    let newcontactPoints = [];
    if (contactPoints.length > 0) {
      contactPoints.map((item, index) => {
        newcontactPoints.push({ label: item.full_name, value: item.id, contact: [contact.id] });
      })
    }
    const deal = {
      "contact": [{ label: contact.name, value: contact.id }],
      "contact_point": newcontactPoints ?? [],
      "owner": { label: `${currentUserAssignTo?.name} (${currentUserAssignTo.email ?? ""})`, value: currentUserAssignTo?.id },
      "currency": currency
    }
    return deal;
  }

  const handleCreateProposal = () => {
    setToast({
      show: true,
      message: "Proposal created successfully.",
    });
    setIdUpdateResult(null);
    setIsOpenModalCreateProposal(false);
  }

  return (
    <>
      <div className="activity">
        <div className="activity__search">
          <input
            type="text"
            value={searchText}
            onChange={(event) => handleSearch(event)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setPage(1);
                dispatch(selectSearch(e.target.value, 'text'));
                fetchAllTask();
              }
            }}
            className="search"
            placeholder={t("search_activity")}
          />
          <SearchIcon className="search-icon" />
        </div>
        <button
          className="button btn-create"
          style={{
            marginTop: '-10px',
            padding: '0 30px',
            height: '35px',
          }}
          onClick={() => {
            setIsOpenCreateModal(true);
            fetchDeals();
            fetchOrders();
          }}
        >
          {t('add')}
        </button>
      </div>
      <div className="type-task">
        <p id="type-label">{t('type')}</p>
        <Dropdown
          className="type"
          options={[{ 'id': 0, 'name': t('all') }, ...taskTypes]}
          objSelect={true}
          component={type.name}
          onChange={(e) => {
            setType(e);
          }}
          styles={{
            color: '#0091AE',
            fontSize: '14px',
            whiteSpace: 'nowrap',
          }}
        ></Dropdown>
      </div>
      {tasks?.length === 0 ? (
        <>
          {loading ? (
            <NoData />
          ) : (
            <p
              style={{
                marginTop: "20px",
                textAlign: "center",
                fontSize: "14px",
              }}
            >
              {t('no_data')}
            </p>
          )}
        </>
      ) : (
        tasks.map((task, rowIndex) => (
          <div className="item-wrap" key={rowIndex} style={{ fontSize: '14px' }} id={`task_${task.id}`}>
            <div className='d-flex'
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  width: '65%'
                }}
              >
                <div style={{ display: "flex" }}>
                  {
                    (expandTask[rowIndex] === false ? <ArrowForwardIosOutlinedIcon
                      style={{
                        marginRight: "0.2em",
                        color: "#5f89ba",
                      }}
                      onClick={() => {
                        handleExpandTask(rowIndex, true);
                        getLogforTask(task, rowIndex);
                      }} ></ArrowForwardIosOutlinedIcon> :
                      <ArrowDownIconOutlined
                        style={{
                          marginRight: "0.2em",
                          color: "#5f89ba",
                        }}
                        onClick={() => handleExpandTask(rowIndex, false)} ></ArrowDownIconOutlined>)
                  }
                  <span className='bold' style={{ color: '#33475B' }}>#T{task.id} - {task.name}</span>
                </div>
                {expandTask[rowIndex] === true && <div
                  style={{
                    marginRight: 'auto',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'baseline',
                    paddingBlock: '5px'
                  }}
                ><div style={{ minWidth: '75px', textAlign: 'left' }}>
                    <p style={{ color: '#33475B' }}>{t('type')}</p>
                  </div>
                  <p className="show-type">
                    {task.task_type.name}
                  </p>
                </div>}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  width: '35%',
                  justifyContent: 'flex-end'
                }}
              >
                <div style={{ textAlign: 'right' }}>
                  <p>
                    {moment(task.created_at).format('YYYY/MM/DD - HH:mm')}
                  </p>
                  {
                    expandTask[rowIndex] === true &&
                    <p className="blue-text">
                      {t('deadline')}: {moment(task.deadline).format('YYYY/MM/DD - HH:mm')}
                    </p>
                  }
                </div>

                {(
                  <div>
                    <Dropdown
                      options={
                        ['Edit', 'Delete'].map((action) => {
                          if (action === 'Delete' && task?.can_delete_task && task.user.id === authUser.id) {
                            return action;
                          } else if (action === 'Edit') {
                            return action;
                          }
                          return null;
                        }).filter(action => action !== null)
                      }
                      styles={{
                        height: '10px',
                        marginLeft: 'auto',
                      }}
                      dropdownStyle={{
                        minWidth: '260%'
                      }}
                      editFromNote={true}
                      onChange={(value) => {
                        value === 'Edit' && handleEditClick(task);
                        value === 'Delete' && handleDeleteClick(task);
                      }}
                      editFrom={true}
                      arrowIcon={arrowIcon}
                    />
                  </div>
                )}
              </div>
            </div>
            {expandTask[rowIndex] === true && <div
              style={{
                marginRight: 'auto',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'baseline',
                paddingBlock: '5px'
              }}
            >
              <div style={{ minWidth: '75px', textAlign: 'left' }}>
                <p style={{ color: '#33475B' }}>{t('result')}</p>
              </div>
              <Stack direction="row" sx={{
                marginLeft: "10px", overflow: "auto",

              }} spacing={1}>
                {(handleGetListResult(task.task_type.name))?.map((x) => (
                  <Chip label={x.name}
                    color={task.can_update_task_result === false
                      ? "default"
                      : "primary"}
                    variant={handleGetResult(task.id, task.task_result.name) === x.name ? "" : "outlined"} sx={{
                      height: "22px",
                      color: task.can_update_task_result === false
                        ? "grayText"
                        : "",
                      pointerEvents:
                        task.can_update_task_result === false
                          ? "none"
                          : ""
                    }}
                    onClick={(e) => {
                      handleShowBtn(e, task.id, task.assign_user, x);
                    }}
                    disabled={(task.task_type.name.toLowerCase() === "meeting") && task.meeting === null || (task.task_type.name.toLowerCase() === "creating quotation") && task.quotation === null ? true : false} />
                ))}
              </Stack>
            </div>}
            {expandTask[rowIndex] === true && <>

              <div
                style={{
                  marginRight: 'auto',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'baseline',
                  paddingBlock: '5px'
                }}
              >
                <div style={{ width: '75px', textAlign: 'left' }}>
                  <p style={{ color: '#33475B' }}>{t('assigned_to')}</p>
                </div>
                <p className="show-type">
                  {task.assign_user?.name}
                </p>
              </div>

              <div
                style={{
                  marginRight: 'auto',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'baseline',
                  paddingBlock: '5px'
                }}
              >
                <div style={{ width: '75px', textAlign: 'left' }}>
                  <p style={{ color: '#33475B' }}>{t('contact')}</p>
                </div>
                <p
                  className={`show-contact ${task.user.id === authUser.id && "p-magrin-right"}`}
                  onClick={() => navigate(`/contact/${contact.id}`)}
                >
                  {task.taskable?.name}
                </p>
              </div>

              {(task.task_type.name.toLowerCase() === "creating quotation" || task.task_type.name.toLowerCase() === "negotiating with customer") &&
                <div
                  style={{
                    marginRight: 'auto',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'baseline',
                    paddingBlock: '5px'
                  }}
                >
                  <div style={{ width: '75px', textAlign: 'left' }}>
                    <p style={{ color: '#33475B' }}>{t('deal')}</p>
                  </div>
                  <p className="show-deal" onClick={() => navigate(`/deal/${task?.referenceable?.id}`)} style={{ cursor: 'pointer' }}>
                    {task?.referenceable?.name}
                  </p>
                </div>
              }

              {(task.task_type.name.toLowerCase() === "customer care" || task.task_type.name.toLowerCase() === "signing a contract with customer") &&
                <div
                  style={{
                    marginRight: 'auto',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'baseline',
                    paddingBlock: '5px'
                  }}
                >
                  <div style={{ width: '75px', textAlign: 'left' }}>
                    <p style={{ color: '#33475B' }}>{t('order')}</p>
                  </div>
                  <p className="show-order" onClick={() => navigate(`/orders/edit/${task?.referenceable?.id}`, { state: { order: task?.referenceable } })} style={{ cursor: 'pointer' }}>
                    {task?.referenceable?.name}
                  </p>
                </div>
              }

              {(task.task_type.name.toLowerCase() === "negotiating with customer") &&
                <div
                  style={{
                    marginRight: 'auto',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'baseline',
                    paddingBlock: '5px'
                  }}
                >
                  <div style={{ width: '75px', textAlign: 'left' }}>
                    <p style={{ color: '#33475B' }}>{t('quotation')}</p>
                  </div>
                  <p className="show-order" onClick={() => navigate(`/quotation/edit/${task?.quotation?.id}?taskId=${task.id}`)} style={{ cursor: 'pointer' }}>
                    {task?.quotation?.name}
                  </p>
                </div>
              }

              {task.task_type.name.toLowerCase() === "meeting" && task?.meeting && (
                <>
                  <hr style={{ margin: '16px 0' }} />
                  <div
                    style={{
                      marginRight: 'auto',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'baseline'
                    }}
                  >
                    <p style={{ color: '#33475B' }}>{t('meeting_note')}</p>
                    <p className="show-deal">
                      {moment(task.meeting.meeting_answers[0].answerable_value).format('YYYY/MM/DD HH:mm')}
                    </p>
                  </div>
                  {task.note === null &&
                    <hr style={{ margin: '16px 0' }} />
                  }
                </>
              )}
              {task.task_type.name.toLowerCase() === "meeting" && !task.meeting && (
                <>
                  <hr style={{ margin: '16px 0' }} />
                  <p style={{ fontFamily: "Inter" }}>{t('meeting_title')}</p>
                  <Button
                    backgroundColor="#EE8062"
                    text={t('add') + ' ' + t('meeting_note')}
                    style={{
                      borderWidth: 0,
                      padding: "5px 25px",
                      height: "30px",
                      fontWeight: 'bold',
                      color: "white",
                      borderRadius: "4px",
                      fontSize: "14px",
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "normal"
                    }}
                    onClick={() => navigate(`/meeting/?contact=${contact.id}`, { state: { task, contact } })}
                  />
                  {task.note === null &&
                    <hr style={{ margin: '16px 0' }} />
                  }

                </>
              )}
              {task.task_type.name.toLowerCase() === "creating quotation" && task?.quotation && (
                <>
                  <hr style={{ margin: '16px 0' }} />
                  <p style={{ textAlign: "left" }}>{t('quotation_title_1')} <span style={{ color: "#0091AE", cursor: "pointer" }}
                    onClick={() => {
                      navigate(`/quotation/edit/${task?.quotation?.id}?taskId=${task.id}`)
                    }}
                  >
                    {task?.quotation?.name}
                  </span> {t('quotation_title_2')} </p>
                  <hr style={{ margin: '16px 0' }} />
                </>
              )}
              {task.task_type.name.toLowerCase() === "creating quotation" && !task.quotation && (<>
                <hr style={{ margin: '16px 0 0 0' }} />
                <Button
                  backgroundColor="#EE8062"
                  text="Create Quotation"
                  style={{
                    borderWidth: 0,
                    padding: "5px 25px",
                    height: "30px",
                    fontWeight: 'bold',
                    color: "white",
                    borderRadius: "4px",
                    fontSize: "14px",
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "normal",
                    cursor: "pointer",
                    opacity: 1
                  }}
                  onClick={() => {
                    navigate(`/quotation/create?dealId=${task.referenceable_id}&taskId=${task.id}`)
                  }}
                />
              </>


              )}
              {(task.task_type.name.toLowerCase() !== "creating quotation" && task.task_type.name.toLowerCase() !== "meeting") && <>
                <hr style={{ margin: '16px 0 0 0' }} />
              </>}
              <div className="ck-content" style={{ marginLeft: '25px', marginTop: "10px" }}>
                {HTMLReactParser(task.note ?? '')}
              </div>
              {task.note !== null &&
                <hr style={{ margin: '16px 0 0 0' }} />}

              <div style={{ maxHeight: '300px', overflow: 'auto', marginBottom: '15px', marginRight: '-9px', paddingRight: '9px' }} id={`log${task.id}`}>
                {logsTask && logsTask[rowIndex]?.log.map((log, rowIndexNote) => (
                  ACTION_TASKS_LOG.includes(log.action) && (
                    log.action === "updated_task_description" ? (
                      <div style={{ marginTop: '15px' }} key={rowIndexNote}>
                        <div className="d-flex">
                          <p style={{ fontStyle: 'oblique' }}
                            dangerouslySetInnerHTML={{
                              __html: replacedContent(t(log?.action), log?.message?.reference_values)
                            }}
                          >
                          </p>
                          <span
                            className="bold"
                            style={{
                              cursor: 'pointer',
                            }}
                            onMouseEnter={(event) => {
                              event.target.style.color = "#5179A7";
                            }}
                            onMouseLeave={(event) => {
                              event.target.style.color = "#000000";
                            }}
                            onClick={
                              () => handleOpenModalHistory(task.id)
                            }
                          >
                            &nbsp;({t("show_detail")})
                          </span>
                          <p
                            style={{ marginLeft: 'auto', color: '#80868B', whiteSpace: 'nowrap' }}
                            className={task.user.id === authUser.id && "p-magrin-right"}
                          >
                            {moment(log.updated_at).format('YYYY/MM/DD - HH:mm')}
                          </p>
                        </div>
                      </div>
                    ) : log.action === "added_task_comment" ? (
                      <div style={{ marginTop: '15px' }} key={rowIndexNote}>
                        <div className="d-flex">
                          <p style={{ fontStyle: 'oblique' }}
                            dangerouslySetInnerHTML={{
                              __html: replacedContent(t(log?.action), log?.message?.reference_values)
                            }}
                          >
                          </p>
                          <p
                            style={{ marginLeft: 'auto', color: '#80868B', whiteSpace: 'nowrap' }}
                            className={task.user.id === authUser.id && "p-magrin-right"}
                          >
                            {moment(log.created_at).format('YYYY/MM/DD - HH:mm')}
                          </p>
                        </div>
                        <div style={{ marginLeft: '25px' }}>
                          <div className="ck-content">{HTMLReactParser(log?.message?.reference_values?.note?.note ?? '')}</div>
                        </div>
                      </div>
                    ) : (
                      <div style={{ marginTop: '15px' }} key={rowIndexNote}>
                        <div className="d-flex">
                          <p style={{ fontStyle: 'oblique' }}
                            dangerouslySetInnerHTML={{
                              __html: replacedContent(t(log?.action), log?.message?.reference_values)
                            }}
                          >
                          </p>
                          <p
                            style={{ marginLeft: 'auto', color: '#80868B', whiteSpace: 'nowrap' }}
                            className={task.user.id === authUser.id && "p-magrin-right"}
                          >
                            {moment(log.created_at).format('YYYY/MM/DD - HH:mm')}
                          </p>
                        </div>
                      </div>
                    )
                  )
                ))}
              </div>

              {!idTasks.includes(task.id) && <TaskComment selectedTask={task} setToast={setToast} setLoading={setLoading} dispatch={dispatch} />}
              <div
                style={{ display: "flex", justifyContent: 'center' }}
              >
                {idTasks.includes(task.id) &&
                  <Button
                    className={"button btn-create"}
                    text={"Save"}
                    style={{
                      padding: "4px 30px",
                      height: "30px",
                      marginTop: "-5px",
                      marginLeft: 0,
                      justifyContent: "center"
                    }}
                    onClick={() => handleOpenModalChangeResult(task)}
                  ></Button>
                }
              </div>
            </>}

          </div>
        ))
      )}

      {isOpenCreateModal && (
        <Modal
          title="Create task"
          contact={contact}
          isOpen={isOpenCreateModal}
          onClose={() => setIsOpenCreateModal(false)}
          onSubmit={handleCreate}
          onLoadingEnd={() => {
            setLoading(false);
          }}
          listDeals={listDeals}
          listOrders={listOrders}
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
          setLoading={setLoading}
        />
      )}
      {selectedTaskEdit && (
        <Modal
          title="Edit task"
          contact={contact}
          initialValues={selectedTaskEdit}
          isOpen={isOpenEditModal}
          onClose={() => {
            setIsOpenEditModal(false);
            setSelectedTaskEdit(null);
          }}
          onLoadingEnd={() => {
            setLoading(false);
          }}
          onSubmit={handleEdit}
          listDeals={listDeals}
          listOrders={listOrders}
          isDisabled={isDisabled}
          setIsDisabled={setIsDisabled}
          setLoading={setLoading}
        />
      )}
      {selectedTaskDelete && (
        <ModalDelete
          isOpen={isOpenDeleteModal}
          title={'Confirmation Delete'}
          onClose={() => {
            setIsOpenDeleteModal(false);
            setSelectedTaskDelete(null);
          }}
          name="task"
          useConfirm={true}
          type={'filter'}
          onConfirm={() => handleDelete(selectedTaskDelete)}
        />
      )}
      {modalCreateDeal && (
        <ModalDeal
          title="Create Deal"
          isOpen={modalCreateDeal}
          onClose={() => {
            setModalCreateDeal(false);
            setCurrentUserAssignTo(null);
          }}
          onSubmit={handleCreateDeal}
          fields={dealFieldCreate}
          setField={setDealFieldCreate}
          dealCreate={getDealForCreate()}
          isDisableContact={true}
        />
      )}

      <div ref={loadMoreRef} style={{ height: '10px' }} />
      {toast.show && (
        <Toast
          message={toast.message}
          duration={3000}
          error={toast.error}
          onClose={() => {
            setToast({ show: false });
          }}
        />
      )}
      {idUpdateResult && !isOpenModalCreateProposal && (
        <ModalCenter
          isOpen={idUpdateResult != false && !isOpenModalCreateProposal}
          title={"Confirmation Change Result"}
          onClose={() => handleCloseConfirmUpdateResult()}
          name={currentResultChange.action_message ?? "Are you sure you want to change this Task"}
          nameResult={currentResultChange?.name}
          useConfirm={true}
          onTask={true}
          onConfirm={() => handleUpdateResult()}
          isDisabled={isDisabled}
        />
      )}

      <ModalHistory
        title="Edit task description"
        isOpen={isOpenModalHistory}
        onClose={handleCloseModalHistory}
        histories={detailDescription}
      />

      {isOpenModalCreateProposal &&
        <ModalProposal
          isOpen={isOpenModalCreateProposal}
          proposalData={proposalData}
          isCreate
          onCreate={handleCreateProposal}
          onClose={() => {
            setIdUpdateResult(null);
            setIsOpenModalCreateProposal(false);
          }}
        />
      }
    </>
  );
}

export default TaskTab;
