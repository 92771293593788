import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import Loading from "../Loading";
import moment from 'moment';
import { formatNumberWithCommas, formatNumberWithCommasAndDot, formatNumberWithNoCommas } from '../../utils/helper';
import IncrementIcon from "./../../assets/images/increment.svg";
import DecrementIcon from "./../../assets/images/decrement.svg";

export default function ModalRevenue({ isOpen, onClose, data }) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [dataRevenue, setDataRevenue] = useState(data);
    const [oldRevenue, setOldRevenue] = useState(data?.old_revenue || []);
    const [newRevenue, setNewRevenue] = useState(data?.new_revenue || []);
    const [months, setMonths] = useState(data?.months || []);

    // Memoize sorted months to prevent re-sorting on every render
    const sortedMonths = useMemo(() => {
        return (data?.months || []).slice().sort((a, b) =>
            moment(a, 'YYYY-MM-DD').diff(moment(b, 'YYYY-MM-DD'))
        );
    }, [data?.months]);

    useEffect(() => {
        setMonths(sortedMonths);  // Set sorted months once after sorting
    }, [sortedMonths]);

    // Memoize revenue maps for fast lookups
    const oldRevenueMap = useMemo(() => {
        return oldRevenue.reduce((acc, item) => {
            acc[item.month] = item.revenue;
            return acc;
        }, {});
    }, [oldRevenue]);

    const newRevenueMap = useMemo(() => {
        return newRevenue.reduce((acc, item) => {
            acc[item.month] = item.revenue;
            return acc;
        }, {});
    }, [newRevenue]);
    const amountDifference = (oldValue, newValue) => {
        return newValue - oldValue;
    }
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            fullWidth
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title" style={{ padding: "0 12px" }}>
                <button
                    onClick={onClose}
                    style={{
                        float: "right",
                        fontSize: "20px",
                        background: "transparent",
                        border: "none",
                    }}
                >
                    &times;
                </button>
            </DialogTitle>
            <DialogContent dividers style={{ borderTop: "unset", padding: "0 40px 16px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    <p>{dataRevenue.order_name}</p>
                    <span>{moment(dataRevenue.time).format('DD/MM/YYYY HH:mm')}</span>
                </div>
                <div className="invoice-amount">
                    {loading && <Loading />}
                    <div className="form-group">
                        <table className='table-revenue' style={{ marginBottom: "10px" }}>
                            <thead>
                                <tr>
                                    <th>Month</th>
                                    {months.map((value, index) => {
                                        return amountDifference(oldRevenueMap[value] ?? 0, newRevenueMap[value] ?? 0) !== 0 && (
                                            <td key={index}>{moment(value).format('MM/YYYY')}</td>)
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Previous Distribute Revenue</td>
                                    {months.map((value, index) => {
                                        return amountDifference(oldRevenueMap[value] ?? 0, newRevenueMap[value] ?? 0) !== 0 && (
                                            <td key={index} style={{ textAlign: "center" }}>
                                                {formatNumberWithCommas(oldRevenueMap[value] ?? 0)}
                                            </td>)
                                    })}
                                </tr>
                                <tr>
                                    <td>Updated Distribute Revenue</td>
                                    {months.map((value, index) => {
                                        return amountDifference(oldRevenueMap[value] ?? 0, newRevenueMap[value] ?? 0) !== 0 && (
                                            <td key={index} style={{ textAlign: "center" }}>
                                                {formatNumberWithCommas(newRevenueMap[value] ?? 0)}
                                            </td>)
                                    })}
                                </tr>
                                <tr>
                                    <td>Amount Difference</td>
                                    {months.map((value, index) => {
                                        const amount_difference = amountDifference(oldRevenueMap[value] ?? 0, newRevenueMap[value] ?? 0);
                                        return amount_difference !== 0 && (
                                            <td key={index} className={amount_difference < 0 ? 'negative' : 'positive'} style={{ textAlign: "center" }}>
                                                {amount_difference < 0 ? (
                                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                                        <img src={DecrementIcon} alt="Eye" style={{ marginRight: "4px" }} />
                                                        {formatNumberWithCommas(amount_difference)}
                                                    </div>

                                                ) : (
                                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                                        <img src={IncrementIcon} alt="Eye" style={{ marginRight: "4px" }} />
                                                        {formatNumberWithCommas(amount_difference)}
                                                    </div>

                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            </tbody>
                        </table>
                        {dataRevenue.old_total !== dataRevenue.new_total && (
                            <p style={{ display: "flex" }}>Order Revenue: change from <b style={{ margin: "0 4px" }}> {formatNumberWithCommasAndDot(formatNumberWithNoCommas(dataRevenue.old_total))} </b> to <span className={dataRevenue.old_total < dataRevenue.new_total ? 'positive' : 'negative'}><b style={{ display: "flex", margin: "0 4px" }}> {dataRevenue.old_total < dataRevenue.new_total ? (
                                <img src={IncrementIcon} alt="Eye" style={{ marginRight: "4px" }} />
                            ) : (
                                <img src={DecrementIcon} alt="Eye" style={{ marginRight: "4px" }} />

                            )}{formatNumberWithCommasAndDot(formatNumberWithNoCommas(dataRevenue.new_total))}</b></span></p>
                        )}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
