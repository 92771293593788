/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, Fragment } from 'react';
import './index.css';
import StageBar from '../Statge';
import Modal from '../ModalCenter';
import { formatDate, formatNumber, formatNumberWithCommasAndDot, formatNumberWithNoCommas, pluck, checkDR } from '../../utils/helper';
import { useDispatch } from 'react-redux';
import { getContactById } from '../../store/contactsSlice';
import { Tooltip } from '@mui/material';
import { TYPE_CONTACT_OBJECT, ACTION_TYPE_LOG } from '../../utils/constant';
import moment from "moment";
import MenuTab from '../MenuTab'
import ModalNote from '../ModalCenter';
import { downloadFile } from '../../store/proposalSlice';
import IconEdit from "./../../assets/images/edit.svg";
import EyeIcon from "./../../assets/images/eye.svg";
import Select, { components } from 'react-select';
import FaAngleDown from '@mui/icons-material/ArrowDropDown';
import DescriptionIcon from '@mui/icons-material/Description';
import { downloadFile as downloadFileCrm } from "../../store/ordersSlice";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// eslint-disable-next-line max-len, react/prop-types
const Table = ({ data, columns, searchTerm, handleRowClick, handleOrderRowClick, handleCheckBox, handleLinkClick,
  updateContact, isSticky = false, handleCheckUnit, disableCheckbox, handleOpenModal, statusInvoices, handleChangeStatus, handleOpenNoteModal, setLoading, nameTable, handleOpenTaxModal, handleOpenRevenueModal, handleOpenInvoiceModal }) => {
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState([]);
  const [contact, setContact] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [listContactSelected, setListContactSelected] = useState([]);
  const [isOpenModalNoteCancel, setIsOpenModalNoteCancel] = useState(false);
  const [isOpenModalNoteEstimateCancel, setIsOpenModalNoteEstimateCancel] = useState(false);
  const [note, setNote] = useState();
  const [noteEstimateCost, setNoteEstimateCost] = useState();
  const [hover, setHover] = useState(-1);
  const menuPortalTarget = document.body;
  const { t } = useTranslation();
  const handleRowSelection = (index, row) => {
    const newSelectedRows = [...selectedRows];
    const rowIndex = newSelectedRows.indexOf(index);
    if (rowIndex === -1) {
      newSelectedRows.push(index);
    } else {
      newSelectedRows.splice(rowIndex, 1);
    }
    if (handleCheckUnit) {
      let newSelectedList = [...listContactSelected];
      const value = { id: index, unit: row.unit };
      const unitIndex = newSelectedList.find((item) => item.id === value.id);
      if (!unitIndex) {
        newSelectedList.push(value);
      } else {
        newSelectedList = newSelectedList.filter((item) => item.id !== value.id);
      }
      setListContactSelected(newSelectedList);
      handleCheckUnit(newSelectedList);
    }
    setSelectedRows(newSelectedRows);
    handleCheckBox(newSelectedRows, newSelectedRows.length === filteredData.length && filteredData.length > 0);
  };

  const handleSelectAll = () => {
    if (selectedRows.length === filteredData.length) {
      setSelectedRows([]);
      handleCheckBox([], false);
      if (handleCheckUnit) {
        setListContactSelected([]);
        handleCheckUnit([]);
      }
    } else {
      if (handleCheckUnit) {
        let newSelectedList = filteredData.map((data, index) => {
          return { id: data.id, unit: data.unit };
        });
        setListContactSelected(newSelectedList);
        handleCheckUnit(newSelectedList);
      }
      setSelectedRows(filteredData.map((data, index) => data.id));
      handleCheckBox(filteredData.map((data, index) => data.id), true);
    }
  };

  const handleDownloadFile = async (file, fileName) => {
    setLoading(true);
    await dispatch(downloadFile(file, fileName));
    setLoading(false);
  }

  const onStageClick = (newContactData) => {
    if (searchTerm === "is_blacklist") {
      return;
    } else {
      dispatch(getContactById(newContactData.id))
        .then((contact) => {
          const newContact = { ...contact };
          newContact.oldStage = newContact.stage;
          newContact.stage = newContactData.stage;
          if (contact.stage !== newContactData.stage) {
            setIsOpenConfirmModal(true);
            setContact(newContact);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const handleCancelNoteModal = async (note) => {
    setIsOpenModalNoteCancel(true);
    setNote(note);
  }

  const handleCancelNoteEstimateModal = async (note) => {
    setIsOpenModalNoteEstimateCancel(true);
    setNoteEstimateCost(note);
  }

  const customStyles = (value) => {
    let colorText;
    switch (value) {
      case "Paid":
        colorText = "#29C203"
        break;
      case "Overdue":
        colorText = "#FF0000"
        break;
      case "Sent":
        colorText = "#0063B0"
        break;
      case "Partially Paid":
        colorText = "#EE8062"
        break;
      default:
        colorText = "black";
        break;
    }

    return {
      menuPortal: (base) => ({ ...base, zIndex: 99999 }),
      control: (base) => ({ ...base, textAlign: 'left', border: "none", cursor: "pointer", fontWeight: "bold", boxShadow: 'none' }),
      menuList: (base) => ({
        ...base,
        width: "100%",
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        padding: 0
      }),
      option: (base) => ({
        ...base,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        cursor: "pointer"
      }),
      valueContainer: (provided) => ({
        ...provided,
        padding: 0,
      }),
      singleValue: (provided) => ({
        ...provided,
        color: `${colorText}`
      })
    };
  }

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <FaAngleDown />
      </components.DropdownIndicator>
    );
  };

  const downLoadFileOrder = (file) => {
    return (
      <DescriptionIcon sx={{ color: '#5179A7' }} onClick={() => downloadFileCRM(file)} />
    );
  };

  const downloadFileCRM = async (fileUrl) => {
    setLoading(true);
    await dispatch(downloadFileCrm(fileUrl));
    setLoading(false);
  }
  const centerDataNumberOrDate = (column) => {
    switch (column) {
      case 'created_date':
      case 'last_updated':
      case 'members':
      case 'update_date':
      case 'expected_revenue':
      case 'expected revenue':
      case 'total':
      case 'order_date':
      case 'expected_start':
      case 'expected_end':
      case 'invoice_amount_pre':
      case 'invoice_amount_after':
      case 'tax':
      case 'received_amount':
      case 'payment_date':
      case 'due_date':
      case 'create_date':
      case 'deadline':
      case 'estimate_cost':
      case 'expect_start_date':
      case 'Size':
        return 'text-right';
      case 'unit':
      case 'currency':
        return 'text-center';
      default:
        return;
    }
  }

  return (
    <div className={`table-container ${isSticky ? "sticky-table" : ''} ${nameTable === "dashboard-task" || nameTable === "dashboard-quotation" ? "fixTableHead" : ""}`}>
      <table className="table">
        <thead>
          <tr>
            {!disableCheckbox && (<th className="checkboxRow">
              <input
                type="checkbox"
                // eslint-disable-next-line max-len
                checked={selectedRows.length === filteredData.length && filteredData.length > 0}
                onChange={handleSelectAll}
              />
            </th>)}
            {columns.map((column, columnIndex) => {
              return (column === "PIC" && searchTerm === "my_task") ? <></> : (column === 'estimate_file') ? <th style={{ width: "170px", textAlign: 'left' }}>{t('estimate_file')}</th> : (
                // eslint-disable-next-line max-len
                <th
                  key={`${columnIndex}-${column}`}
                  width={column === "Website" ? 80 : ""}
                  className={`table-header ${centerDataNumberOrDate(column)} ${column === "created_date" || column === "update_date" || column === "deadline"
                    ? "small"
                    : ""
                    }`}
                >
                  {column === "update_date" ? t('last_updated') : t(column)}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {filteredData.length === 0 ? (
            <tr>
              <td colSpan={columns.length + 1} style={{ textAlign: 'center', fontSize: '14px' }}>{t('no_data')}</td>
            </tr>
          ) : (
            filteredData.map((row, rowIndex) => (
              <tr key={`${rowIndex}-${row}`} className="table-row">
                {!disableCheckbox && (<td className="checkboxRow checkboxTable">
                  <input
                    type="checkbox"
                    checked={selectedRows.indexOf(row.id) !== -1}
                    onChange={() => handleRowSelection(row.id, row)}
                  />
                </td>)}
                {columns.map((column, columnIndex) => {
                  return (column === "PIC" && searchTerm === "my_task") ? <></> : (
                    // eslint-disable-next-line max-len
                    <td
                      style={{
                        maxWidth: "280px",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                      key={`${column}-${columnIndex}`}
                      className={`${centerDataNumberOrDate(column)} ${column === "name" ? "hover-pointer" : "hover-default"
                        } table-cell ${column === "created_date" || column === "update_date" || column === "deadline"
                          ? "small"
                          : ""
                        } ${column === "status" && columns.includes("invoice_name") ? "statusInvoice" : ""} ${nameTable === "dashboard-task" || nameTable === "dashboard-quotation" ? "dashboard-td" : ""}`}
                      onClick={() => (column === "name") && handleRowClick(row)}
                      onMouseEnter={() => (column === "estimate_file") && setHover(rowIndex)} onMouseLeave={() => (column === "estimate_file") && setHover(-1)}
                    >
                      {column === "created_date" ? (
                        row["created_at"] && formatDate(moment(row["created_at"]))
                      ) : column === "update_date" ? (
                        row["updated_at"] && formatDate(moment(row["updated_at"]))
                      ) : column === "due_date" ? (
                        row["due_date"] && formatDate(moment(row["due_date"]))
                      ) : column === "payment_date" ? (
                        Date.parse(row["payment_date"]) ? formatDate(moment(row["payment_date"])) : ''
                      ) : column === "deadline" && nameTable === "dashboard-task" ? (
                        row["deadline"] && moment(row["deadline"]).format("YYYY/MM/DD")
                      ) : column === "deadline" ? (
                        row["deadline"] && moment(row["deadline"]).format("YYYY/MM/DD HH:mm")
                      ) : column === "last_updated" ? (
                        row["updated_at"] && moment(row["updated_at"]).format("YYYY-MM-DD HH:mm:ss")
                      )
                        : column === "list" || column === "lists" ? (
                          // eslint-disable-next-line max-len
                          row["contact_lists"] &&
                            row["contact_lists"].length > 1 ? (
                            <Tooltip
                              title={row["contact_lists"].map((contact, idx) => (
                                <span key={`${contact.id}-${idx}`}>
                                  {(idx ? ", " : "") + contact.name}
                                </span>
                              ))}
                              arrow
                            >
                              <span>
                                {row["contact_lists"] &&
                                  row["contact_lists"][0].name + " ..."}
                              </span>
                            </Tooltip>
                          ) : (
                            row["contact_lists"] &&
                            row["contact_lists"].map((contact) => (
                              <span key={`${contact.id}-${contact.name}`}>
                                {contact.name}
                              </span>
                            ))
                          )
                        ) : column === "contact" && nameTable === "dashboard-task" ? (
                          row["taskable"]?.name
                        ) : column === "contact" ? (
                          // eslint-disable-next-line max-len
                          row['taskable'] ? row['taskable'].name :
                            row['contact'] ? row['contact'].name :
                              row['contacts']?.length <= 1
                                ? row['contacts'][0]?.name ?? ''
                                : <MenuTab
                                  title={row['contacts']?.length + ' records'}
                                  label={'Contacts'}
                                  data={row['contacts'] ?? ''}
                                />
                        )
                          : column === "contact_point" || column === "contact point" ? (
                            // eslint-disable-next-line max-len
                            row['contact_points']?.length <= 1
                              ? row['contact_points'][0]?.email ?? row['contact_points'][0]?.phone ?? row['contact_points'][0]?.full_name ?? ''
                              : <MenuTab
                                title={row['contact_points']?.length + ' records'}
                                label={'Contact Points'}
                                data={row['contact_points'] ?? ''}
                              />
                          )
                            : column === "expected_revenue" || column === "expected revenue" ? (
                              // eslint-disable-next-line max-len
                              row['revenue'] && Number(row['revenue']).toLocaleString() + ' ' + row['currency']?.name
                            )
                              : column === "PIC" || column === "pic" ? (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center"
                                  }}
                                >
                                  {row['deal']?.user && <>
                                    <p>S:</p>
                                    <img
                                      src={row['deal']['user']?.avatar ?? ''}
                                      className='pic-avatar'
                                      title={row['deal']['user']?.name}
                                      alt={row['deal']['user']?.name}
                                    />
                                  </>}
                                  {row['user'] && <>
                                    <p>S:</p>
                                    <img
                                      src={row['user']?.avatar ?? ''}
                                      className='pic-avatar'
                                      title={row['user']?.name}
                                      alt={row['user']?.name}
                                    />
                                  </>}
                                  {row['assign_user'] && <>
                                    <p>S:</p>
                                    <img
                                      src={row['assign_user']?.avatar ?? ''}
                                      className='pic-avatar'
                                      title={row['assign_user']?.name}
                                      alt={row['assign_user']?.name}
                                    />
                                  </>}
                                  {row['marketer'] && <>
                                    <p>M:</p>
                                    <img
                                      src={row['marketer']?.avatar ?? ''}
                                      className='pic-avatar'
                                      title={row['marketer']?.name}
                                      alt={row['marketer']?.name}
                                    />
                                  </>}
                                  {row['saler'] && <>
                                    <p>S:</p>
                                    <img
                                      src={row['saler']?.avatar ?? ''}
                                      className='pic-avatar'
                                      title={row['saler']?.name}
                                      alt={row['saler']?.name}
                                    />
                                  </>}
                                  {row['am'] && <>
                                    <p>AM:</p>
                                    <img
                                      src={row['am']?.avatar ?? ''}
                                      className='pic-avatar'
                                      title={row['am']?.name}
                                      alt={row['am']?.name}
                                    />
                                  </>}
                                </div>
                              )
                                : column === "Stage" || column === "stage" ? (
                                  // eslint-disable-next-line max-len
                                  (searchTerm === 'all_deal' || searchTerm === 'my_deal')
                                    ? row["stage"]?.name || ''
                                    : <StageBar
                                      stageName={row["stage"]}
                                      contactId={row["id"]}
                                      onClick={onStageClick}
                                    />
                                ) : column === "result" ? (
                                  // eslint-disable-next-line max-len
                                  row["task_result"] ? row["task_result"].name :
                                    <p
                                      style={{ color: "#2E89FF", cursor: "pointer" }}
                                      onClick={() =>
                                        searchTerm === "assign_me"
                                          ? handleLinkClick({ ...row })
                                          : null
                                      }
                                    >
                                      {row["result"] === "todo"
                                        ? "To do"
                                        : row["result"] === "sent"
                                          ? "Sent"
                                          : row["result"] === "unsent"
                                            ? "Unsent"
                                            : row["result"]}
                                    </p>
                                ) : column === "type" ? (
                                  // eslint-disable-next-line max-len
                                  row['task_type'] ? row['task_type'].name :
                                    <p>{TYPE_CONTACT_OBJECT[row["type"]] ?? row["type"]}</p>
                                ) : column === "contact_form_url" ? (
                                  // eslint-disable-next-line max-len
                                  <div>
                                    {row["contact_form_url"] ? (
                                      <a href={row["contact_form_url"]} target="_blank" rel="noreferrer">URL</a>
                                    ) : ""}
                                  </div>
                                ) : column === "link_proposal" ? (
                                  // eslint-disable-next-line max-len
                                  <div>
                                    {row["link"] ? (
                                      <a href={row["link"]} target="_blank" rel="noreferrer">URL</a>
                                    ) : ""}
                                  </div>
                                ) : column === "Website" ? (
                                  <a
                                    href={row["website"]}
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={() => handleLinkClick({ ...row })}
                                  >
                                    {row["website"]}
                                  </a>
                                ) : column === "unit" ? (
                                  // eslint-disable-next-line max-len
                                  row["taskable"] ? row["taskable"].unit?.name ?? "" :
                                    row[
                                      column
                                        .replace(/\s+/g, "")
                                        .replace(/[A-Z]/g, (match) => match.toLowerCase())
                                    ]?.name ?? row["deal"]?.unit?.name ?? (row.first_contact?.unit?.name || '')
                                ) : column === "Team ID" ? (
                                  row["id"]
                                ) : column === "team_name" ? (
                                  row["name"]
                                ) : column === "team_manager" ? (
                                  row["manager"].name
                                ) : column === "members" ? (
                                  row["users_count"]
                                  // ) : column === "Object" ? (
                                  //   row["taskable_type"].charAt(0).toUpperCase() + row["taskable_type"].slice(1)
                                ) : column === "name" ? (
                                  row["name"]
                                ) : column === "order_date" ? (
                                  row["order_date"] && formatDate(moment(row["order_date"]))
                                ) : column === "total" ? (
                                  row['total'] && Number(row['total']).toLocaleString() + ' ' + row['currency']?.name
                                ) : column === "deal" ? (
                                  row["deal"].name
                                ) : column === "status" && columns.includes("invoice_name") ? (
                                  <Select
                                    options={statusInvoices}
                                    components={{ DropdownIndicator, IndicatorSeparator: () => null }}
                                    styles={customStyles(row["status"]?.name)}
                                    menuPortalTarget={menuPortalTarget}
                                    value={{ value: row["status"]?.id, label: row["status"]?.name }}
                                    onChange={(selectedOption) => {
                                      if (selectedOption?.value === row["status"]?.id) {
                                        return;
                                      }
                                      handleChangeStatus(row, selectedOption);
                                    }}
                                    menuPlacement="auto"
                                    menuPosition="fixed"
                                    isSearchable={false}
                                  />
                                ) : column === "status" ? (
                                  row["status"]?.name
                                ) : column === "expected_start" ? (
                                  row["start_date"] && formatDate(moment(row["start_date"]))
                                ) : column === "expected_end" ? (
                                  row["end_date"] && formatDate(moment(row["end_date"]))
                                ) : column === "proposal_name" ? (
                                  "media" in row ? (
                                    <p className='text-click' onClick={() => handleRowClick(row)}>
                                      {row["name"]}
                                    </p>
                                  ) : row["name"]
                                ) : column === "cost" ? (
                                  formatNumber(row["cost_deal"] ?? row["estimation_cost"])
                                ) : column === "receive_date" ? (
                                  (row["completed_at"] && formatDate(moment(row["completed_at"]))) ?? (row["created_at"] && formatDate(moment(row["created_at"])))
                                ) : column === "estimator" ? (
                                  row["estimator"] ? row["estimator"]['name'] : null
                                ) : column === "project_type" ? (
                                  row["project_type"]
                                ) : column === "invoice_name" ? (
                                  <p className="hover-pointer" onClick={() => handleRowClick(row)}>
                                    {row["name"]}
                                  </p>
                                ) : column === "order_name" ? (
                                  <p className="hover-pointer" onClick={() => handleOrderRowClick(row["order"])}>
                                    {row["order"]?.name}
                                  </p>
                                ) : column === "saler" ? (
                                  <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center"
                                  }}>
                                    <p>S:</p>
                                    <img
                                      src={row['saler']?.avatar ?? ''}
                                      className='pic-avatar'
                                      title={row['saler']?.name}
                                      alt={row['saler']?.name}
                                    />
                                  </div>
                                ) : column === "currency" ? (
                                  row["currency"]?.name
                                ) : column === "Invoice Amount" ? (
                                  Number(row["invoice_amount"]).toLocaleString()
                                ) : column === "invoice_amount_pre" ? (
                                  row["invoice_amount"] && Number(row["invoice_amount"]).toLocaleString()
                                ) : column === "invoice_amount_after" ? (
                                  row["invoice_amount_after_tax"] && Number(row["invoice_amount_after_tax"]).toLocaleString()
                                ) : column === "tax" ? (
                                  <div className="tax" style={{ display: "flex" }}>
                                    <p className="tax-percent">{!isNaN(row["tax"]) && `${row["tax"]} %`}</p>
                                    <img src={IconEdit} alt="Edit" className='iconEdit' onClick={() => handleOpenTaxModal(row)} />
                                  </div>
                                ) : column === "received_amount" ? (
                                  row["received_amount"] !== 0 ? <div className='test' onClick={() => handleOpenModal(row)}>
                                    {Number(row["received_amount"]).toLocaleString()}
                                  </div> :
                                    <div style={{ display: "flex", justifyContent: 'end' }}>
                                      <img src={IconEdit} alt="Edit" className='iconEdit' onClick={() => handleOpenModal(row)} />
                                    </div>
                                ) : column === "cost_adjustment_reasons" ? (
                                  (row["note_estimate_cost"] && (
                                    <p className='text-click' onClick={() => handleCancelNoteEstimateModal(row["note_estimate_cost"])}>
                                      View Detail
                                    </p>
                                  ))
                                ) : column === "cancel_reason" ? (
                                  (row["cancellation_reasons"] && (
                                    <p className='text-click' onClick={() => handleCancelNoteModal(row["cancellation_reasons"])}>
                                      View Detail
                                    </p>
                                  )) ?? (row["note"] && (
                                    <p className='text-click' onClick={() => handleCancelNoteModal(row["note"])}>
                                      View Detail
                                    </p>
                                  ))
                                ) : column === "note" ? (
                                  row["notes"] && row["notes"].length !== 0 ? (
                                    <p className='text-click' onClick={() => handleOpenNoteModal(row["id"], row["notes"])}>
                                      Note
                                    </p>
                                  ) :
                                    <img src={IconEdit} alt="Edit" className='iconEdit' onClick={() => handleOpenNoteModal(row["id"])} />
                                ) : column === "estimate_file" ? (
                                  row['media'] ?
                                    <div>
                                      {hover !== rowIndex ? <div>
                                        <p className='text-click' >
                                          {row['media'].length > 1 ? row['media'].length + ' files' : <div style={{ maxWidth: '170px', textOverflow: 'ellipsis', overflow: 'hidden' }} onClick={() => { handleDownloadFile(row['media'][0]?.filename + '.' + row.media[0]?.extension, row.media[0]?.name) }}>{row.media[0]?.name}</div>}
                                        </p>
                                      </div> : <div>
                                        {
                                          row['media'].map(item => (
                                            <Tooltip title={item?.name} placement="right-start">
                                              <div style={{ maxWidth: '170px', textOverflow: 'ellipsis', overflow: 'hidden' }} onClick={() => { handleDownloadFile(item.filename + '.' + item.extension, item.name) }} className='text-click'>{item.name}</div>
                                            </Tooltip>

                                          ))
                                        }
                                      </div>}
                                    </div> : row['estimate_file'] ?
                                      <div>
                                        {downLoadFileOrder(row['estimate_file'])}
                                      </div> : ''
                                ) : column === "Order File" ? (
                                  row['file'] ?
                                    <div>
                                      {downLoadFileOrder(row['file'])}
                                    </div> : ''
                                ) : column === "quotation" ? (
                                  <p className="hover-pointer" onClick={() => handleRowClick(row)}>
                                    {row["name"]}
                                  </p>
                                ) : column === "estimate_cost" ? (
                                  `${formatNumberWithCommasAndDot(formatNumberWithNoCommas(row["estimation_cost"]))} ${row["currency"]?.name}`
                                ) : column === "expect_start_date" ? (
                                  row["start_date"] && moment(row["start_date"]).format("YYYY/MM/DD")
                                ) : column === "time" ? (
                                  row["created_at"] && moment(row["created_at"]).format("DD/MM/YYYY HH:mm")
                                ) : column === "actor" ? (
                                  row["user"].name
                                ) : column === "order_log" ? (
                                  row["new_value"].name
                                ) : column === "previous_or" ? (
                                  row["old_value"].length !== 0 && row["new_value"].length !== 0 && row["old_value"].total_revenue !== row["new_value"].total_revenue ? `${formatNumberWithCommasAndDot(formatNumberWithNoCommas(row["old_value"].total_revenue))} ${row["old_value"]?.currency?.name}` : '-'
                                ) : column === "updated_or" ? (
                                  row["old_value"].length !== 0 && row["new_value"].length !== 0 && row["old_value"].total_revenue !== row["new_value"].total_revenue ? `${formatNumberWithCommasAndDot(formatNumberWithNoCommas(row["new_value"].total_revenue))} ${row["new_value"]?.currency?.name}` : '-'
                                ) : column === "previous_dr" ? (
                                  row["old_value"].length !== 0 && row["new_value"].length !== 0 && checkDR(row["old_value"], row["new_value"]) ? `${row["old_value"]?.revenue_distributions?.length ?? 0} ${row["old_value"]?.revenue_distributions?.length > 1 ? 'months' : 'month'}` : '-'
                                ) : column === "updated_dr" ? (
                                  row["old_value"].length !== 0 && row["new_value"].length !== 0 && checkDR(row["old_value"], row["new_value"]) ? (
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      <p style={{ marginRight: "4px" }}>{`${row["new_value"].revenue_distributions.length} ${row["new_value"]?.revenue_distributions?.length > 1 ? 'months' : 'month'}`}</p>
                                      <img src={EyeIcon} alt="Eye" onClick={() => handleOpenRevenueModal(row)} />
                                    </div>
                                  ) : '-'
                                ) : column === "order_detail_log" ? (
                                  <a href={`/orders/edit/${row["new_value"]?.id}`} target="_blank" rel="noreferrer">View Detail</a>

                                ) : column === "invoice_detail_log" ? (
                                  ((row["new_value"]?.invoice_code) || row["old_value"]?.invoice_code) && (<p className='text-click' onClick={() => handleOpenInvoiceModal(row)}>
                                    View Detail
                                  </p>)

                                ) : column === "unit_log" ? (
                                  row["new_value"]?.deal?.unit.name
                                ) : column === "note_log" ? (
                                  row?.note
                                ) : column === "action_log" ? (
                                  ACTION_TYPE_LOG.filter((item) => item.value === row['action'])[0]?.label
                                ) : column === "invoice_code_log" ? (
                                  row["new_value"]?.invoice_code ?? row["old_value"]?.invoice_code
                                ) : column === "order_invoice_log" ? (
                                  row["new_value"]?.order?.name ?? row["old_value"]?.order?.name
                                ) : column === "inv_amount_before" ? (
                                  row["old_value"].length !== 0 && row["new_value"].length !== 0 && row["old_value"].invoice_amount_after_tax !== row["new_value"].invoice_amount_after_tax ? `${formatNumberWithCommasAndDot(formatNumberWithNoCommas(row["old_value"].invoice_amount_after_tax))} ${row["old_value"]?.order?.currency?.name}` : '-'
                                ) : column === "inv_amount_after" ? (
                                  row["old_value"].length !== 0 && row["new_value"].length !== 0 && row["old_value"].invoice_amount_after_tax !== row["new_value"].invoice_amount_after_tax ? `${formatNumberWithCommasAndDot(formatNumberWithNoCommas(row["new_value"].invoice_amount_after_tax))} ${row["new_value"]?.order?.currency?.name}` : '-'
                                ) : column === "receive_amount_before" ? (
                                  row["old_value"].length !== 0 && row["new_value"].length !== 0 && row["old_value"].received_amount !== row["new_value"].received_amount ? `${formatNumberWithCommasAndDot(formatNumberWithNoCommas(row["old_value"].received_amount))} ${row["old_value"]?.order?.currency?.name}` : '-'
                                ) : column === "receive_amount_after" ? (
                                  row["old_value"].length !== 0 && row["new_value"].length !== 0 && row["old_value"].received_amount !== row["new_value"].received_amount ? `${formatNumberWithCommasAndDot(formatNumberWithNoCommas(row["new_value"].received_amount))} ${row["new_value"]?.order?.currency?.name}` : '-'
                                ) : (
                                  // eslint-disable-next-line max-len
                                  row[
                                  column
                                    .replace(/\s+/g, "_")
                                    .replace(/[A-Z]/g, (match) => match.toLowerCase())
                                  ]
                                )}
                    </td>
                  )
                })}
              </tr>
            ))
          )}
        </tbody>
      </table>
      <Modal
        isOpen={isOpenConfirmModal}
        title={"Confirmation"}
        onClose={() => setIsOpenConfirmModal(false)}
        useConfirm={true}
        onConfirm={({ contactListId }) => {
          updateContact(contact, contactListId?.value, contact.stage);
        }}
        contact={contact}
      />
      {isOpenModalNoteCancel && (
        <ModalNote
          title={t("note")}
          isOpen={isOpenModalNoteCancel}
          note={true}
          onClose={() => setIsOpenModalNoteCancel(false)}
          dataNote={note}
          isDisabled={true}
        />
      )}
      {isOpenModalNoteEstimateCancel && (
        <ModalNote
          title={t("note")}
          isOpen={isOpenModalNoteEstimateCancel}
          note={true}
          onClose={() => setIsOpenModalNoteEstimateCancel(false)}
          dataNote={noteEstimateCost}
          isDisabled={true}
        />
      )}
    </div>
  );
};

export default Table;
