import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ArrowRightIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowDownIconOutlined from "@mui/icons-material/KeyboardArrowDown";
import { capitalizeFirstLetter, isNotEmptyStringOrNull } from "../../../utils/helper";
import { validateForm, countDifferences } from '../../../utils/helper';
import Loading from "../../../components/Loading";
import Dropdown from "../../../components/Dropdown";
import Toast from "../../../components/ToastMessage";
import { Checkbox, Switch } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "../../../components/ModalBottom";
import ModalDelete from '../../../components/ModalCenter';
import PointModal from "../../../containers/StaticPages/Contact/ModalDetail/index";
import DealModal from "./ModalDeal/index";
import Tooltip from "@mui/material/Tooltip";
import {
  STAGE_OPTION,
  STAGE_OBJECT,
  DEAL_FIELD,
  CONTACT_FIELD_CREATE,
  ADD_CONTACT_POINT_FIELD,
  EDIT_CONTACT_POINT_FIELD,
  CONTACT_TO_LIST_FIELD_CREATE,
  COUNTRY_UNIT_JMU,
  COUNTRY_UNIT_SFS,
  COUNTRY_UNIT_EMU,
} from "../../../utils/constant";
import {
  updateContact,
  getContactById,
} from "../../../store/contactsSlice";
import {
  deleteDeal,
  getDealById,
  updateDeal
} from "../../../store/dealsSlice";
import { useParams } from "react-router-dom";
import "./index.css";
import TabSwitcher from "./ContactDetailChild/TabSwitcher";
import { fetchContactPoint, deleteExistingContactPoint, resetError, fetchContactList, getIndustries, getCompanyClassifications } from "../../../store/contactDetailSlice";
import ModalCenter from "../../../components/ModalCenter";
import { fetchUnits } from "../../../store/unitSlice";
import ModalDeal from "../../../components/ModalDeal";
import { fetchCurrencies } from "../../../store/currencySlice";
import { getDealsByContact } from "../../../store/dealsSlice";
import moment from "moment";
import { formatDate } from "../../../utils/helper";
import Fade from '@mui/material/Fade';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import { fetchStageDeals } from "../../../store/StageDealSlice";
import ListModal from "./ModalLists";
import { fetchTasks } from "../../../store/tasksSlice";
import { getTeamsForInput } from "../../../store/teamSlice";
import { getChannelsForInput } from "../../../store/channelSlice";
import { fetchAllUsers, fetchUnitUser } from "../../../store/userSlice";
import { getCitiesForInput, getCountriesForInput } from "../../../store/regionSlice";
import { Helmet } from "react-helmet";
import CalendarIcon from "../../../assets/images/Calendar.png";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModalPrivate from "../../../components/ModalPrivate";
import { authUserSelector } from "../../../redux/auth/auth.selectors";

const ArrowIcon = (props) => {
  return (
    <ArrowDownIconOutlined
      style={{
        marginRight: '0.2em',
        color: '#5f89ba',
      }}
      {...props}
    ></ArrowDownIconOutlined>);
};

const ListDeal = ({ id, navigate, contact, contactPoint, reloadAt, setReloadDealAt, setLoading }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [deals, setDeals] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [toast, setToast] = useState(false);
  const [dealFieldCreate, setDealFieldCreate] = useState(DEAL_FIELD);
  const [isOpenAddDealModal, setIsOpenAddDealModal] = useState(false);
  const [isOpenEditDealModal, setIsOpenEditDealModal] = useState(false);
  const [selectedDealEdit, setSelectedDealEdit] = useState(null);
  const [idDealDelete, setIdDealDelete] = useState(null);
  const userInfo = JSON.parse(localStorage.getItem('user_info'));

  useEffect(() => {
    fetchDeals();
    dispatch(fetchCurrencies());
  }, [id, reloadAt]);

  const fetchDeals = async () => {
    setLoading(true);
    try {
      const element = document.getElementById('deals-wrapper');
      if (element) element.scrollTop = 0;
      const { data } = await dispatch(getDealsByContact(parseInt(id), { page: 1 }));
      setDeals(data?.data ?? []);
      setHasNextPage(!!data?.next_page_url ?? false);
      setCurrentPage(1);
      setLoading(false);
    } catch (error) {
      setDeals([]);
      setHasNextPage(false);
      setCurrentPage(1);
    }
  }

  const handleScroll = async (e) => {
    if (e.target.scrollHeight - e.target.scrollTop == e.target.clientHeight && hasNextPage && !isLoadingMore) {
      try {
        setIsLoadingMore(true);
        const newPage = currentPage + 1;
        const { data } = await dispatch(getDealsByContact(parseInt(id), { page: newPage }));
        if (data?.data?.length) setDeals([...deals, ...data?.data]);
        setCurrentPage(newPage);
        setHasNextPage(!!data?.next_page_url ?? false);
      } catch (error) {
      } finally {
        setIsLoadingMore(false);
      }
    }
  }

  const formatCurrency = (money, currency) => {
    if (typeof money !== 'undefined' && isNotEmptyStringOrNull(money)) {
      return Number(money) ? Number(money).toLocaleString() : money + ' ' + currency;
    }

    return '';
  }

  const handleCreateSuccess = async () => {
    setIsLoadingMore(true);
    setToast({ show: true, message: t("deal_create_success") });
    setIsOpenAddDealModal(false);
    fetchDeals();
    setIsLoadingMore(false);
    dispatch(fetchTasks(contact.id, { 'id': 0, 'name': 'All' }));
  };

  const handleCreateField = async () => {
    setIsLoadingMore(true);
    setToast({ show: true, message: t("deal_create_fail") });
    setIsOpenAddDealModal(false);
    fetchDeals();
    setIsLoadingMore(false);
  }

  const handleEditDealClick = async (id) => {
    await getDeal(id);
    setIsOpenEditDealModal(true);
  }

  const getDeal = async (id) => {
    const deal = await dispatch(getDealById(parseInt(id)));
    setSelectedDealEdit(deal);
  }

  const getDealForEdit = () => {
    if (selectedDealEdit) {
      let contacts = [];
      selectedDealEdit.contacts.forEach((item) => {
        contacts.push({ label: item.name, value: item.id, currency: { id: contact?.unit?.currency?.id, label: contact?.unit?.currency?.name } });
      });
      let contactPoints = [];
      selectedDealEdit.contact_points.forEach((item) => {
        contactPoints.push({ label: item.full_name, value: item.id, contacts: item.contacts.map((x) => x.id) });
      });
      const deal = {
        id: selectedDealEdit.id,
        name: selectedDealEdit.name,
        "contact": contacts,
        contact_point: contactPoints,
        owner: selectedDealEdit.user !== null ? { label: `${selectedDealEdit.user.name} (${selectedDealEdit.user.email})`, value: selectedDealEdit.user.id } : null,
        team: selectedDealEdit.team !== null ? { label: selectedDealEdit.team.name, value: selectedDealEdit.team.id } : null,
        stage: selectedDealEdit.stage !== null ? { label: selectedDealEdit.stage.name, value: selectedDealEdit.stage.id } : null,
        "expected_close_date": selectedDealEdit.closing_date,
        "expected_revenue": selectedDealEdit.revenue,
        "currency": selectedDealEdit.currency !== null ? { label: selectedDealEdit.currency.name, value: selectedDealEdit.currency.id } : null
      }
      return deal;
    }
    return null;
  }

  const getContactForCreate = (contact) => {
    if (contact) {
      let contacts = [];
      contacts.push({ label: contact?.name, value: contact?.id, currency: { id: contact?.unit?.currency?.id, label: contact?.unit?.currency?.name } });
      let newContactPoints = [];
      contactPoint.forEach((item) => {
        newContactPoints.push({ label: item.full_name, value: item.id, contacts: [contact?.id] })
      })
      const deals = {
        "contact": contacts,
        "contact_point": newContactPoints,
        "currency": contact?.unit !== null ? { label: contact?.unit["currency"]?.name, value: contact?.unit["currency"]?.id } : null
      }
      return deals;
    }
    return null;
  }

  const handleUpdate = async (deal) => {
    setIsLoadingMore(true);
    let updateSuccess = await dispatch(updateDeal(deal));
    if (updateSuccess) {
      setToast({ show: true, message: t("deal_update_success") });
      setIsOpenEditDealModal(false);
      setReloadDealAt((new Date()).getTime());
    }
    setIsLoadingMore(false);
  }

  const handleDeleteDeal = async () => {
    setIsLoadingMore(true);
    let deleteResponse = await dispatch(deleteDeal(userInfo.user.id, idDealDelete));
    if (deleteResponse.status_code == 200) {
      setToast({ show: true, message: t("deal_delete_success") });
      setReloadDealAt((new Date()).getTime());
    } else {
      setToast({ show: true, error: true, message: JSON.parse(deleteResponse.error) ?? '' });
      setLoading(false);
    }
    setIdDealDelete(null);
    setIsLoadingMore(false);
  }


  const handleClickDeal = (deal) => {
    navigate(`/deal/${deal.id}`);
  };

  return (
    <div className="contact-point" id="deals-wrapper" onScroll={handleScroll}>
      <div className="d-flex contact-point-header">
        <p className="bread-crumb">
          <ArrowDownIconOutlined
            style={{
              marginRight: "0.2em",
              color: "#5f89ba",
            }}
          ></ArrowDownIconOutlined>
          {t('deal')}
        </p>
        <p
          className="bread-crumb"
          onClick={() => setIsOpenAddDealModal(true)}
        >
          <span className="blue-text add-contact-point-button">
            + {t("add")}
          </span>
        </p>
      </div>
      <div className="contact-point-content">
        {
          deals?.map((deal) => (
            <div className="item-wrap info-contact" key={deal.id}>
              <div className="d-grid">
                <div className="d-flex">
                  <Tooltip title={deal?.name ?? ''}
                    arrow
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                  >
                    <p
                      className="blue-text bold deal-name"
                      onClick={() => { handleClickDeal(deal) }}
                    >
                      {deal?.name ?? ''}
                    </p>
                  </Tooltip>
                  <Dropdown
                    elementId="deals-wrapper"
                    options={[t("edit")]}
                    styles={{ height: "10px", marginLeft: "auto" }}
                    editFrom={true}
                    arrowIcon={<ArrowIcon />}
                    onChange={(value) => {
                      if (value === 'Edit' || value === t('edit')) {
                        handleEditDealClick(deal?.id);
                      }
                    }}
                  ></Dropdown>
                </div>
                <div>
                  <p className="d-flex color-33475b">
                    {t('expected_revenue')}:
                    <span className="blue-text pl-2">{formatCurrency(deal?.revenue, deal?.currency?.name)}</span>
                  </p>
                  <p className="d-flex color-33475b">
                    {t('expected_close_date')}:
                    <span className="blue-text pl-2">{deal?.closing_date ? formatDate(moment(deal?.closing_date)) : ''}</span>
                  </p>
                  <p className="d-flex color-33475b">
                    {t('stage')}:
                    <span className="blue-text pl-2">{deal?.stage?.name ?? ''}</span>
                  </p>
                </div>
              </div>
            </div>
          ))
        }
      </div>

      {
        isOpenEditDealModal && (
          <ModalDeal
            title={t("edit_deal")}
            isOpen={isOpenEditDealModal}
            isCreate={false}
            isEdit={true}
            onClose={() => setIsOpenEditDealModal(false)}
            fields={dealFieldCreate}
            setField={setDealFieldCreate}
            deal={getDealForEdit()}
            onSubmit={handleUpdate}
          />
        )
      }
      {
        isOpenAddDealModal && (
          <DealModal
            title={t("create_deal")}
            isOpen={isOpenAddDealModal}
            onClose={() => setIsOpenAddDealModal(false)}
            onCreateSuccess={handleCreateSuccess}
            onCreateFailed={handleCreateField}
            fields={dealFieldCreate}
            setField={setDealFieldCreate}
            contact={getContactForCreate(contact)}
            isCreate={true}
            contactId={id}
          />
        )
      }

      {
        isLoadingMore &&
        <Box className="deal-skeleton">
          <Box className="d-flex">
            <Skeleton animation="wave" sx={{ flexGrow: 1, marginRight: '10px' }} />
            <ArrowIcon style={{ color: 'rgba(0, 0, 0, 0.11', cursor: 'default' }} />
          </Box>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </Box>
      }

      {
        toast.show && (
          <Toast
            message={toast.message}
            duration={3000}
            error={toast.error}
            onClose={() => {
              setToast({ show: false });
            }}
          />
        )
      }
      {idDealDelete && (
        <ModalDelete
          isOpen={idDealDelete}
          title={'Confirmation Delete'}
          onClose={() => {
            setIdDealDelete(null);
          }}
          name={"deal"}
          useConfirm={true}
          type={'filter'}
          onConfirm={() => handleDeleteDeal()}
        />
      )}
    </div>
  );
}

// eslint-disable-next-line require-jsdoc
function ContactDetail() {
  const { t } = useTranslation();
  const { id } = useParams();
  const authUser = useSelector(authUserSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [contactData, setContactData] = useState(null);
  const [changedItemsCount, setChangedItemsCount] = useState(0);
  const [errors, setErrors] = useState({});
  const [toast, setToast] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [contactPoint, setContactPoint] = useState([]);
  const listsContactLists = useSelector((state) => state.contactDetail.lists);
  const [contactList, setContactList] = useState(listsContactLists);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [selectedContactPointEdit, setSelectedContactPointEdit] = useState(null);
  const [selectedContactPointDelete, setSelectedContactPointDelete] = useState(null);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [dataType, setDataType] = useState("");
  const unitOptions = useSelector((state) => state.units.data.map(({ id, name }) => ({ id, name })));
  const userOptions = useSelector((state) => state.users.data.map(({ id, name, email }) => ({ id, name, email })));
  const [reloadDealAt, setReloadDealAt] = useState((new Date()).getTime());
  const [isOpenAddContactToListModal, setIsOpenAddContactToListModal] = useState(false);
  const [contactListField, setContactListField] = useState(CONTACT_TO_LIST_FIELD_CREATE);
  const [currency, setCurrency] = useState(null);
  const error = useSelector((state) => state.contactDetail.error);
  const [countryOptions, setCountryOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [industriesOption, setIndustriesOption] = useState([]);
  const [companysOption, setCompanysOption] = useState([]);
  const [channelOptions, setChannelOptions] = useState([]);
  const { state } = useLocation();
  const [isOpenMqlDate, setIsOpenMqlDate] = useState(false);
  const [isOpenSqlDate, setIsOpenSqlDate] = useState(false);
  const [isShowModalPrivate, setIsShowModalPrivate] = useState(false);
  const unitUser = useSelector((state) => state.users.unitUser);

  const handleCreateListField = () => {
    const data = CONTACT_TO_LIST_FIELD_CREATE.map((field) => {
      if (field.label === "Unit") {
        return {
          ...field,
          options: [...unitOptions.map((unit) => ({ value: unit.id, label: unit.name }))]
        }
      }
      return field;
    });
    return data;
  };

  useEffect(() => {
    dispatch(fetchUnits());
    dispatch(fetchCurrencies());
    dispatch(fetchStageDeals());
    dispatch(fetchAllUsers());
    dispatch(fetchUnitUser());
  }, [dispatch]);

  useEffect(() => {
    if (unitUser !== null) {
      callCountries();
    }
  }, [unitUser]);
  useEffect(() => {
    if (!!state?.deal) {
      setIsOpenAddModal(true);
    }
  }, []);

  const inputRef = useRef(null);
  const inputUrlRef = useRef(null);

  useEffect(() => {
    const contactDetailContainer = document.querySelector(
      ".contact-detail-container"
    );
    const fullViewport = document.querySelector(".full-viewport");
    if (contactDetailContainer && window.innerWidth <= 1050) {
      fullViewport.style.width = "1000px";
    } else {
      fullViewport.style.width = "100%";
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const contact = await dispatch(getContactById(parseInt(id)));
        if (!contact) navigate("/contacts");
        const industries = await dispatch(getIndustries());
        const company = await dispatch(getCompanyClassifications());
        setIndustriesOption(industries);
        setCompanysOption(company);
        setContactData(contact);
        setCurrency(contact.unit.currency);
        setSelectedData(contact);
        callCities(contact?.country);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    if (id) {
      fetchData();
    }
  }, [id, dispatch]);

  useEffect(() => {
    const fetchContactPointData = async () => {
      setLoading(true);
      try {
        const contactPoint = await dispatch(fetchContactPoint(parseInt(id)));
        setContactPoint(contactPoint.payload.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    if (id) {
      fetchContactPointData();
    }
  }, []);

  useEffect(() => {
    const fetchContactListData = async () => {
      setLoading(true);
      try {
        const contactList = await dispatch(fetchContactList(parseInt(id)));
        setContactList(contactList.payload.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    if (id) {
      fetchContactListData();
    }
  }, []);

  useEffect(() => {
    if (isEditing === "website" && inputRef.current) {
      inputRef.current.focus();
      autoResize(inputRef.current);
    } else if (isEditing === "contact_form_url" && inputUrlRef.current) {
      inputUrlRef.current.focus();
      autoResize(inputUrlRef.current);
    }
  }, [isEditing]);

  useEffect(() => {
    if (error) {
      setLoading(false);
      setToast({ show: true, message: error, error: "true" });
      dispatch(resetError());
    }
  }, [error]);

  useEffect(() => {
    setContactList(listsContactLists);
  }, [listsContactLists]);

  useEffect(() => {
    if (contactData) {
      const textareas = document.querySelectorAll('.textarea-autosize');
      textareas.forEach((textarea) => {
        autoResize(textarea);
      });
      callTeams(contactData?.unit?.id);
      callChannels(contactData?.unit?.id);
    }
  }, [contactData]);

  const reLoadContactData = () => {
    const fetchData = async () => {
      try {
        const contact = await dispatch(getContactById(parseInt(id)));
        setContactData(contact);
        setCurrency(contact.unit.currency);
        setSelectedData(contact);
        callCities(contact?.country);
      } catch (error) {
        setLoading(false);
      }
    };
    if (id) {
      fetchData();
    }
  }

  const reloadContactPointList = async (res) => {
    try {
      setLoading(true);
      const contactPoint = await dispatch(fetchContactPoint(parseInt(id)));
      if (res.status_code === 200) {
        setContactPoint(contactPoint.payload.data);
        setLoading(false);
        setToast({
          show: true,
          message: "Contact point created successfully",
        });
        setIsOpenAddModal(false);
      } else {
        setLoading(false);
        setToast({
          show: true,
          error: true,
          message: res?.error["list_contact_points"] ? res?.error["list_contact_points"] : ADD_CONTACT_POINT_FIELD.map((item) => res?.error[item.id] ?? null),
        });
      }
    } catch (error) {
      // 
    }
  }

  const reloadContactPointUpdateList = async (res) => {
    try {
      setLoading(true);
      const contactPoint = await dispatch(fetchContactPoint(parseInt(id)));
      if (res.status_code === 200) {
        setContactPoint(contactPoint.payload.data);
        setLoading(false);
        setToast({
          show: true,
          message: "Contact point updated successfully",
        });
        setIsOpenEditModal(false);
      } else {
        setLoading(false);
        setToast({
          show: true,
          error: true,
          message: EDIT_CONTACT_POINT_FIELD.map((item) => res?.error[item.id] ?? null),
        });
      }
    } catch (error) {
      // 
    }
  }

  const reloadContactPointDeleteList = async (res) => {
    try {
      setLoading(true);
      const contactPoint = await dispatch(fetchContactPoint(parseInt(id)));
      if (res.status_code === 200) {
        setContactPoint(contactPoint.payload.data);
        setToast({
          show: true,
          message: "Contact point removed successfully",
        });
      } else {
        setToast({
          show: true,
          error: true,
          message: "Contact point removed failed",
        });
      }
    } catch (error) {
      // 
    }
  }

  const handleEditClick = (value) => {
    setIsEditing(value);
  };

  const handleInputBlur = (e) => {
    let updateContact;
    if (isEditing === "website" && inputRef.current) {
      updateContact = { ...contactData, "website": e.target.value };
    } else {
      updateContact = { ...contactData, "contact_form_url": e.target.value };
    }
    setContactData(updateContact);
    setIsEditing(false);
  };

  const handleMouseEnter = (value) => {
    setIsHovering(value);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleDateChange = (e, fieldName) => {
    let newValue = e !== "" ? e : null;
    const updatedContactData = { ...contactData, [fieldName]: newValue };
    const differences = countDifferences(selectedData, updatedContactData);
    setChangedItemsCount(differences);
    setContactData(updatedContactData);
  };

  const handleInputChange = (e, fieldName) => {
    let newValue = e.target.value !== "" ? e.target.value : null;
    if (fieldName === "email") newValue = e.target.value;
    const updatedContactData = { ...contactData, [fieldName]: newValue };
    const differences = countDifferences(selectedData, updatedContactData);
    setChangedItemsCount(differences);
    setContactData(updatedContactData);
  };

  const handleDropdownChange = (value, fieldName) => {
    const updatedContactData = { ...contactData, [fieldName]: value };
    const differences = countDifferences(selectedData, updatedContactData);
    setChangedItemsCount(differences);
    setContactData(updatedContactData);
  };

  const handleStageChange = (value, contactListId, date) => {
    let updatedContactData = { ...contactData, stage: value, contact_list_id: contactListId };
    updatedContactData = handleChangeDateState(value, date, updatedContactData);
    const differences = countDifferences(selectedData, updatedContactData);
    setChangedItemsCount(differences);
    setContactData(updatedContactData);
  };

  const handleChangeDateState = (stage, date, dataUpdateContact) => {
    if (stage === "sql" || stage === "mql") {
      let fieldName = stage + "_date";
      dataUpdateContact[fieldName] = new Date(date).toLocaleDateString('en-ZA');
    }

    return dataUpdateContact;
  }

  const handleBlacklistChange = (checked) => {
    const updatedContactData = {
      ...contactData,
      is_blacklist: checked,
    };

    const differences = countDifferences(selectedData, updatedContactData);

    setChangedItemsCount(differences);
    setContactData(updatedContactData);
  };

  const handleEditContact = async () => {
    const formIsValid = validateForm(CONTACT_FIELD_CREATE, contactData, setErrors, true);
    if (formIsValid) {
      setLoading(true);
      await dispatch(updateContact(contactData))
        .then((response) => {
          if (response.status_code === 422) {
            setErrors({ ...response.error });
            setChangedItemsCount(0);
            setToast({ show: true, error: true, message: t('Contact editing failed') });
            setLoading(false);
          } else {
            if (typeof contactData?.unit !== 'object' && contactData?.unit !== null) {
              contactData['unit'] = unitOptions.find((unit) => unit.id === contactData?.unit) ?? contactData?.unit;
            }
            if (typeof contactData?.marketer !== 'object' && contactData?.marketer !== null) {
              contactData['marketer'] = userOptions.find((marketer) => marketer.id === contactData?.marketer) ?? contactData?.marketer;
            }
            if (typeof contactData?.saler !== 'object' && contactData?.saler !== null) {
              contactData['saler'] = userOptions.find((saler) => saler.id === contactData?.saler) ?? contactData?.saler;
            }
            if (typeof contactData?.am !== 'object' && contactData?.am !== null) {
              contactData['am'] = userOptions.find((am) => am.id === contactData?.am) ?? contactData?.am;
            }
            if (typeof contactData?.team !== 'object' && contactData?.team !== null) {
              contactData['team'] = teamOptions.find((team) => team.id === contactData?.team) ?? contactData?.team;
            }
            if (typeof contactData?.company_classification !== 'object' && contactData?.company_classification !== null) {
              contactData['company_classification'] = companysOption.find((company_classification) => company_classification.id === contactData?.company_classification) ?? contactData?.company_classification;
            }
            if (typeof contactData?.industry !== 'object' && contactData?.industry !== null) {
              contactData['industry'] = industriesOption.find((industry) => industry.id === contactData?.industry) ?? contactData?.industry;
            }
            if (typeof contactData?.channel !== 'object' && contactData?.channel !== null) {
              contactData['channel'] = channelOptions.find((channel) => channel.id === contactData?.channel) ?? contactData?.channel;
            }

            delete contactData['newStage'];
            delete contactData['oldStage'];
            setChangedItemsCount(0);
            reLoadContactData();
            setCurrency(response.data.unit.currency);
            setLoading(false);
            setToast({ show: true, message: t("Contact edited successfully") });
            dispatch(fetchTasks(contactData.id, { 'id': 0, 'name': 'All' }));
          }
        })
        .catch(() => {
          setChangedItemsCount(0);
          setLoading(false);
          setToast({ show: true, error: true, message: t('Contact editing failed') });
        });

    }
  };
  const autoResize = (e) => {
    if (!e) return;
    e.style.height = "25px";
    if (e.value.length === 0) {
      e.style.height = "25px";
    } else {
      e.style.height = (e.scrollHeight) + "px";
    }
  };

  const onStageClick = (newContactData) => {
    const newContact = { ...contactData };
    newContact.oldStage = newContactData.oldStage;
    newContact.newStage = newContactData.newStage;
    if (newContactData.newStage !== newContactData.oldStage) {
      setIsOpenConfirmModal(true);
      setContactData(newContact);
    }
  };

  const handleEditContactPointClick = (item) => {
    setSelectedContactPointEdit(item);
    setIsOpenEditModal(true);
  };

  const handleDeleteContactPointClick = (item) => {
    setSelectedContactPointDelete(item);
    setIsOpenDeleteModal(true);
  };
  const handleDeleteContactPoint = async (contactPoint) => {
    setLoading(true);
    const data = {
      contact_id: parseInt(id),
      list_contact_points: contactPoint.id
    };
    let res = await dispatch(deleteExistingContactPoint(data));
    reloadContactPointDeleteList(res);
    setSelectedContactPointDelete(null);
    setIsOpenDeleteModal(false);
    setLoading(false);
  };
  const handleAddToListSuccess = async () => {
    setToast({ show: true, message: t("Add Contacts to List successfully") });
    setIsOpenAddContactToListModal(false);
    const contact = await dispatch(getContactById(parseInt(id)));
    setContactData(contact);
    setSelectedData(contact);
  }

  // Custom sorting function with a parameter
  const sortByArray = (specialItems) => (a, b) => {
    const isSpecialA = specialItems.includes(a);
    const isSpecialB = specialItems.includes(b);

    if (isSpecialA && !isSpecialB) {
      return -1; // Place special item A before B
    } else if (!isSpecialA && isSpecialB) {
      return 1; // Place special item B before A
    }

    // For non-special items or when both are special, maintain the order
    return a.id - b.id;
  };

  const sortCountryByUnit = (unit, countries) => {
    let countryUnit;
    switch (unit) {
      case 'HUO':
      case 'ETS':
      case 'SFS':
        countryUnit = COUNTRY_UNIT_SFS;
        break;
      case 'JMU':
        countryUnit = COUNTRY_UNIT_JMU;
        break;
      case 'EMU':
        countryUnit = COUNTRY_UNIT_EMU;
        break;
      default:
        countryUnit = COUNTRY_UNIT_SFS;
        break;
    }
    return countries.sort(sortByArray(countryUnit));
  }
  const callCountries = async (value = null) => {
    try {
      const result = await dispatch(getCountriesForInput(value));
      if (result) {
        let listCountries = result.data;
        let countries = [...listCountries?.map((country) => country.name)];
        const sortedArray = sortCountryByUnit(unitUser, countries);
        setCountryOptions([
          ...sortedArray,
        ]);
      }
    } catch (error) { }
  };

  const callCities = async (value = null) => {
    try {
      const result = await dispatch(getCitiesForInput(value));
      if (result) {
        let listCities = result.data.states;
        setCityOptions([
          ...listCities?.map((city) => city.name),
        ]);
      }
    } catch (error) { }
  };
  const callTeams = async (value) => {
    try {
      const result = await dispatch(getTeamsForInput("", null, value, 'get_all'));
      if (result) {
        let listTeams = result.data;
        setTeamOptions([
          ...listTeams?.map((team) => ({ 'id': team.id, 'name': team.name })),
        ]);
      }
    } catch (error) { }
  };
  const callChannels = async (value) => {
    try {
      const result = await dispatch(getChannelsForInput("", value, 'get_all'));
      if (result) {
        let listChannels = result.data;
        setChannelOptions([
          ...listChannels?.map((channel) => ({ 'id': channel.id, 'name': channel.name })),
        ]);
      }
    } catch (error) { }
  };

  const handleChangePrivate = (event) => {
    const updatedContactData = { ...contactData, is_private: event.target.checked ? 1 : 0 };
    const differences = countDifferences(selectedData, updatedContactData);
    setChangedItemsCount(differences);
    setContactData(updatedContactData);
  };

  const handleOpenModalPrivate = (row) => {
    setIsShowModalPrivate(true);
  }

  const handleSharePrivateSuccess = async (privateUsers) => {
    setIsShowModalPrivate(false)
    setToast({ show: true, message: t("Shared successfully") });
    setContactData({ ...contactData, 'private_users': privateUsers });
    setSelectedData({ ...selectedData, 'private_users': privateUsers });
  };

  const handleSharePrivateFailed = async () => {
    setIsShowModalPrivate(false)
    setToast({ show: true, message: t("Share failled") });
  }

  return (
    <>
      <Helmet>
        <title>{t('contact')}</title>
        <meta name="description" content="Description of Contact Detail Page" />
      </Helmet>
      {loading && <Loading />}
      <div className="detail" style={{ overflow: 'hidden' }}>
        <div className="d-flex detail-container">
          <div className="contact-detail-container">
            <div className="d-flex">
              <p
                className="bread-crumb"
                style={{ fontSize: "18px !important" }}
              >
                <span
                  className="blue-text bread-crumb__link"
                  onClick={() => navigate("/contacts")}
                >
                  {t("contact")}
                </span>
                <>
                  <ArrowRightIcon
                    style={{
                      fontSize: "1em",
                      marginLeft: "0.2em",
                      marginRight: "0.2em",
                      position: "relative",
                      bottom: "0.1em",
                    }}
                  ></ArrowRightIcon>
                  <span
                    className="text-xl"
                    style={{ color: "#000000", wordBreak: "break-all" }}
                  >
                    {selectedData && selectedData.name}
                  </span>
                </>
              </p>
            </div>
            {contactData ? (
              <div className="contact-info-container">
                <div className="contact-info-item">
                  <p className="contact-info-label">{t("name")}</p>
                  <textarea
                    maxLength={255}
                    className="contact-info-value large-input textarea-autosize"
                    value={contactData.name}
                    onChange={(e) => {
                      handleInputChange(e, "name");
                      autoResize(e.target);
                    }}
                    onFocus={(e) => (e.target.style.padding = "0px 10px")}
                    onBlur={(e) => (e.target.style.padding = "0px")}
                  />
                </div>
                {errors["Name"] && (
                  <span
                    className="error-text"
                    style={{
                      color: "red",
                      position: "relative",
                      bottom: "10px",
                    }}
                  >
                    {errors["Name"]}
                  </span>
                )}
                <div className="contact-info-item">
                  <p className="contact-info-label">{t("type")}</p>
                  <Dropdown
                    options={["Company", "Individual"]}
                    component={capitalizeFirstLetter(contactData.type)}
                    onChange={(value) => {
                      setContactData({ ...contactData, type: value });
                      handleDropdownChange(value.toLowerCase(), "type");
                    }}
                    styles={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                  ></Dropdown>
                </div>
                {(contactData.create_user === null || contactData.create_user?.id === authUser.id) && (
                  <>
                    <div className="contact-info-item">
                      <p className="contact-info-label">{t("private")}</p>
                      <Checkbox
                        label="Label"
                        checked={contactData.is_private}
                        style={{ padding: 0, borderRadius: '5px', color: '#5F89BA' }}
                        onChange={handleChangePrivate}
                      />
                    </div>
                    {contactData.is_private === 1 && (
                      <div className="contact-info-item">
                        <p className="contact-info-label">{t("share_with")}</p>
                        {contactData.private_users && contactData.private_users.length > 0 ? (
                          <div>
                            <span className="">{contactData.private_users.length}{t("people")}</span>
                            <VisibilityIcon sx={{ color: '#5F89BA' }} onClick={handleOpenModalPrivate} />
                          </div>
                        ) : (
                          <span className="add-people" onClick={handleOpenModalPrivate}>{t("add_people")}</span>
                        )}
                      </div>
                    )}
                  </>
                )}

                <div className="contact-info-item">
                  <p className="contact-info-label">{t("stage")}</p>
                  <Dropdown
                    options={STAGE_OPTION}
                    component={
                      STAGE_OBJECT[
                      contactData.stage.toLowerCase().replace(" ", "_")
                      ]
                    }
                    onChange={(value) => {
                      onStageClick({
                        oldStage: contactData.stage,
                        newStage: value.toLowerCase().replace(" ", "_"),
                      });
                    }}
                    fields={"stage"}
                    styles={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                  ></Dropdown>
                </div>
                <div className="contact-info-item">
                  <p className="contact-info-label">{t("MQL Date")}</p>
                  {contactData.mql_date ? <p style={{ display: "flex" }}>
                    {moment(contactData.mql_date).format('YYYY/MM/DD') ?? "　"}
                    <span style={{ paddingLeft: "20px" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          open={isOpenMqlDate}
                          value={contactData.mql_date}
                          onOpen={() => { setIsOpenMqlDate(true) }}
                          onClose={() => { setIsOpenMqlDate(false) }}
                          renderInput={(params) =>
                            <img ref={params.inputRef} onClick={() => setIsOpenMqlDate(true)} style={{ cursor: 'pointer' }} src={CalendarIcon} alt={"calendar"} />
                          }
                          onChange={(event) => {
                            handleDateChange(event.$d, "mql_date");
                            let newErr = { ...errors };
                            delete newErr["mql_date"];
                            setErrors(newErr);
                          }}
                        />
                      </LocalizationProvider>

                    </span>
                  </p> :
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        open={isOpenMqlDate}
                        onOpen={() => { setIsOpenMqlDate(true) }}
                        onClose={() => { setIsOpenMqlDate(false) }}
                        renderInput={(params) =>
                          <img ref={params.inputRef} onClick={() => setIsOpenMqlDate(true)} style={{ cursor: 'pointer' }} src={CalendarIcon} alt={"calendar"} />
                        }
                        onChange={(event) => {
                          handleDateChange(event.$d, "mql_date");
                          let newErr = { ...errors };
                          delete newErr["mql_date"];
                          setErrors(newErr);
                        }}
                      />
                    </LocalizationProvider>
                  }

                </div>
                {errors["mql_date"] && (
                  <span
                    className="error-text"
                    style={{
                      color: "red",
                      position: "relative",
                      bottom: "10px",
                    }}
                  >
                    {errors["mql_date"]}
                  </span>
                )}
                <div className="contact-info-item">
                  <p className="contact-info-label">{t("SQL Date")}</p>
                  <div>
                    {contactData.sql_date ? <p style={{ display: "flex" }}>
                      {moment(contactData.sql_date).format('YYYY/MM/DD') ?? "　"}
                      <span style={{ paddingLeft: "20px" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            open={isOpenSqlDate}
                            value={contactData.sql_date}
                            onOpen={() => { setIsOpenSqlDate(true) }}
                            onClose={() => { setIsOpenSqlDate(false) }}
                            renderInput={(params) =>
                              <img ref={params.inputRef} onClick={() => setIsOpenSqlDate(true)} style={{ cursor: 'pointer' }} src={CalendarIcon} alt={"calendar"} />
                            }
                            onChange={(event) => {
                              handleDateChange(event.$d, "sql_date");
                              let newErr = { ...errors };
                              delete newErr["sql_date"];
                              setErrors(newErr);
                            }}
                          />
                        </LocalizationProvider>
                      </span>
                    </p> :
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          open={isOpenSqlDate}
                          onOpen={() => { setIsOpenSqlDate(true) }}
                          onClose={() => { setIsOpenSqlDate(false) }}
                          renderInput={(params) =>
                            <img ref={params.inputRef} onClick={() => setIsOpenSqlDate(true)} style={{ cursor: 'pointer' }} src={CalendarIcon} alt={"calendar"} />
                          }
                          onChange={(event) => {
                            handleDateChange(event.$d, "sql_date");
                            let newErr = { ...errors };
                            delete newErr["sql_date"];
                            setErrors(newErr);
                          }}
                        />
                      </LocalizationProvider>
                    }
                  </div>
                </div>
                {errors["sql_date"] && (
                  <span
                    className="error-text"
                    style={{
                      color: "red",
                      position: "relative",
                      bottom: "10px",
                    }}
                  >
                    {errors["sql_date"]}
                  </span>
                )}
                <div
                  className="contact-info-item"
                  onMouseEnter={() => handleMouseEnter("website")}
                >
                  <p className="contact-info-label">{t("Website")}</p>
                  <Tooltip title={contactData.website} placement="top">
                    <div
                      className="contact-info-value"
                      style={{ whiteSpace: "break-spaces" }}
                      onMouseLeave={handleMouseLeave}
                    >
                      {isEditing === "website" ? (
                        <textarea
                          maxLength={255}
                          type="text"
                          className="contact-info-value large-input textarea-autosize"
                          defaultValue={contactData.website}
                          onBlur={handleInputBlur}
                          onChange={(e) => {
                            handleInputChange(e, "website");
                            autoResize(e.target);
                          }}
                          ref={inputRef}
                        />
                      ) : (
                        <>
                          <a
                            href={contactData.website}
                            target="_blank"
                            rel="noreferrer"
                            className="contact-info-link"
                            onMouseEnter={() =>
                              inputRef.current && inputRef.current.focus()
                            }
                          >
                            {contactData.website?.length > 50
                              ? contactData.website.substring(0, 50) + "..."
                              : contactData.website}
                          </a>
                          {isHovering === "website" && (
                            <EditIcon
                              style={{ fontSize: "1em", marginLeft: "0.5em" }}
                              onClick={() => handleEditClick("website")}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </Tooltip>
                </div>
                {errors["website"] && (
                  <span
                    className="error-text"
                    style={{
                      color: "red",
                      position: "relative",
                      bottom: "10px",
                    }}
                  >
                    {errors["website"]}
                  </span>
                )}
                <div className="contact-info-item">
                  <p className="contact-info-label">{t("Email")}</p>
                  {dataType === "Email" ? (
                    <textarea
                      maxLength={255}
                      className="contact-info-value large-input textarea-autosize"
                      style={{ height: "25px" }}
                      value={contactData.email ?? ""}
                      onChange={(e) => {
                        handleInputChange(e, "email");
                        autoResize(e.target);
                      }}
                      onFocus={(e) => (e.target.style.padding = "0px 10px")}
                      onBlur={(e) => {
                        e.target.style.padding = "0px";
                        setDataType("");
                      }}
                      autoFocus={dataType === "Email"}
                    />
                  ) : (
                    <p onClick={() => setDataType("Email")}>
                      {contactData.email ?? "　"}
                    </p>
                  )}
                </div>
                {errors["Email"] && (
                  <span
                    className="error-text"
                    style={{
                      color: "red",
                      position: "relative",
                      bottom: "10px",
                    }}
                  >
                    {errors["Email"]}
                  </span>
                )}
                <div className="contact-info-item">
                  <p className="contact-info-label">{t("phone")}</p>
                  {dataType === "Phone" ? (
                    <input
                      className="contact-info-value large-input"
                      value={contactData.phone}
                      onChange={(e) => handleInputChange(e, "phone")}
                      onFocus={(e) => (e.target.style.padding = "0px 10px")}
                      onBlur={(e) => {
                        setDataType("");
                        e.target.style.padding = "0px";
                      }}
                      autoFocus={dataType === "Phone"}
                    />
                  ) : (
                    <p onClick={() => setDataType("Phone")}>
                      {contactData.phone ?? "　"}
                    </p>
                  )}
                </div>
                {errors["Phone"] && (
                  <span
                    className="error-text"
                    style={{
                      color: "red",
                      position: "relative",
                      bottom: "10px",
                    }}
                  >
                    {errors["Phone"]}
                  </span>
                )}
                <div className="contact-info-item">
                  <p className="contact-info-label">{t("country")}</p>
                  <Dropdown
                    options={countryOptions}
                    component={contactData?.country}
                    onChange={(value) => {
                      setContactData({ ...contactData, country: value });
                      handleDropdownChange(value, "country");
                      callCities(value);
                    }}
                    withSearch={true}
                    styles={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                  ></Dropdown>
                </div>
                <div className="contact-info-item">
                  <p className="contact-info-label">{t("city")}</p>
                  <Dropdown
                    options={cityOptions}
                    component={contactData?.city}
                    onChange={(value) => {
                      setContactData({ ...contactData, city: value });
                      handleDropdownChange(value, "city");
                    }}
                    isDisabled={!contactData.country}
                    withSearch={true}
                    styles={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                  ></Dropdown>
                </div>
                <div className="contact-info-item">
                  <p className="contact-info-label">{t("address")}</p>
                  {dataType === "Address" ? (
                    <textarea
                      maxLength={255}
                      className="contact-info-value large-input textarea-autosize"
                      style={{ height: "25px" }}
                      value={contactData.address ?? ""}
                      onChange={(e) => {
                        handleInputChange(e, "address");
                        autoResize(e.target);
                      }}
                      onFocus={(e) => (e.target.style.padding = "0px 10px")}
                      onBlur={(e) => {
                        setDataType("");
                        e.target.style.padding = "0px";
                      }}
                      autoFocus={dataType === "Address"}
                    />
                  )
                    : (
                      <p onClick={() => setDataType("Address")}>
                        {contactData.address ?? "　"}
                      </p>
                    )}
                </div>
                <div className="contact-info-item">
                  <p className="contact-info-label">{t("industry")}</p>
                  <Dropdown
                    options={industriesOption}
                    component={contactData?.industry?.name}
                    onChange={(value) => {
                      setContactData({ ...contactData, industry: value });
                      handleDropdownChange(value, "industry");
                    }}
                    objSelect={true}
                    styles={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                    withSearch={true}
                  ></Dropdown>
                </div>
                <div className="contact-info-item">
                  <p className="contact-info-label">{t("channel")}</p>
                  <Dropdown
                    options={channelOptions}
                    component={contactData?.channel?.name}
                    onChange={(channel) => {
                      setContactData({ ...contactData, channel });
                      handleDropdownChange(channel, "channel");
                    }}
                    withSearch={true}
                    objSelect={true}
                    styles={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                  ></Dropdown>
                </div>
                <div className="contact-info-item">
                  <p className="contact-info-label">{t("unit")}</p>
                  <Dropdown
                    options={unitOptions}
                    component={contactData?.unit?.name}
                    onChange={(unit) => {
                      setContactData({ ...contactData, unit });
                      handleDropdownChange(unit, "unit");
                    }}
                    objSelect={true}
                    isDisabled={contactData?.is_attached_list || contactData?.is_blacklist}
                    styles={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                  ></Dropdown>
                </div>
                <div className="contact-info-item">
                  <p className="contact-info-label">{t("team")}</p>
                  <Dropdown
                    options={teamOptions}
                    component={contactData?.team?.name}
                    onChange={(team) => {
                      setContactData({ ...contactData, team });
                      handleDropdownChange(team, "team");
                    }}
                    withSearch={true}
                    objSelect={true}
                    styles={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                  ></Dropdown>
                </div>
                <div className="contact-info-item">
                  <p className="contact-info-label">{t("marketer")}</p>
                  <Dropdown
                    options={userOptions}
                    component={contactData?.marketer?.name}
                    onChange={(marketer) => {
                      setContactData({ ...contactData, marketer });
                      handleDropdownChange(marketer, "marketer");
                    }}
                    withSearch={true}
                    objSelect={true}
                    styles={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                  ></Dropdown>
                </div>
                <div className="contact-info-item">
                  <p className="contact-info-label">{t("saler")}</p>
                  <Dropdown
                    options={userOptions}
                    component={contactData?.saler?.name}
                    onChange={(saler) => {
                      setContactData({ ...contactData, saler });
                      handleDropdownChange(saler, "saler");
                    }}
                    withSearch={true}
                    objSelect={true}
                    styles={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                  ></Dropdown>
                </div>
                <div className="contact-info-item">
                  <p className="contact-info-label">{t("AM")}</p>
                  <Dropdown
                    options={userOptions}
                    component={contactData?.am?.name}
                    onChange={(am) => {
                      setContactData({ ...contactData, am });
                      handleDropdownChange(am, "am");
                    }}
                    withSearch={true}
                    objSelect={true}
                    styles={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                  ></Dropdown>
                </div>
                <div className="contact-info-item">
                  <p className="contact-info-label">
                    {t("company_classification")}
                  </p>
                  <Dropdown
                    options={companysOption}
                    component={contactData?.company_classification?.name}
                    onChange={(value) => {
                      setContactData({ ...contactData, company_classification: value });
                      handleDropdownChange(value, "company_classification");
                    }}
                    objSelect={true}
                    styles={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                  ></Dropdown>
                </div>
                <div
                  className="contact-info-item"
                  onMouseEnter={() => handleMouseEnter("contact_form_url")}
                >
                  <p className="contact-info-label">{t("contact_form_url")}</p>
                  <Tooltip title={contactData.contact_form_url} placement="top">
                    <div
                      className="contact-info-value"
                      style={{ whiteSpace: "break-spaces" }}
                      onMouseLeave={handleMouseLeave}
                    >
                      {isEditing === "contact_form_url" ? (
                        <textarea
                          maxLength={1000}
                          type="text"
                          className="contact-info-value large-input textarea-autosize"
                          defaultValue={contactData.contact_form_url}
                          onBlur={handleInputBlur}
                          onChange={(e) => {
                            handleInputChange(e, "contact_form_url");
                            autoResize(e.target);
                          }}
                          ref={inputUrlRef}
                        />
                      ) : (
                        <>
                          <a
                            href={contactData.contact_form_url}
                            target="_blank"
                            rel="noreferrer"
                            className="contact-info-link"
                            onMouseEnter={() =>
                              inputUrlRef.current && inputUrlRef.current.focus()
                            }
                          >
                            {contactData.contact_form_url?.length > 50
                              ? contactData.contact_form_url.substring(0, 50) + "..."
                              : contactData.contact_form_url}
                          </a>
                          {isHovering === "contact_form_url" && (
                            <EditIcon
                              style={{ fontSize: "1em", marginLeft: "0.5em" }}
                              onClick={() => handleEditClick("contact_form_url")}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </Tooltip>
                </div>
                {errors["contact_form_url"] && (
                  <span
                    className="error-text"
                    style={{
                      color: "red",
                      position: "relative",
                      bottom: "10px",
                    }}
                  >
                    {errors["contact_form_url"]}
                  </span>
                )}
                <div className="contact-info-item">
                  <p className="contact-info-label">{t("blacklist")}</p>
                  <div style={{ position: "relative", left: "-10px" }}>
                    <Switch
                      checked={contactData.is_blacklist || false}
                      onChange={(event, checked) =>
                        handleBlacklistChange(checked)
                      }
                    />
                  </div>
                </div>
                <div
                  className="contact-info-item"
                  style={{ marginTop: "20px" }}
                ></div>
              </div>
            ) : null}
          </div>
          <div className="activities-container">
            {contactData && <TabSwitcher contact={contactData} loading={loading} setReloadDealAt={setReloadDealAt} currency={currency} reLoadContactData={reLoadContactData} />}
          </div>
          <div className="contact-point-block" style={{ fontSize: '14px' }}>
            <div className="contact-point" id="contact-point">
              <div className="d-flex contact-point-header">
                <p className="bread-crumb">
                  <ArrowDownIconOutlined
                    style={{
                      marginRight: "0.2em",
                      color: "#5f89ba",
                    }}
                  ></ArrowDownIconOutlined>
                  {t('contact_point')}
                </p>
                <p
                  className="bread-crumb"
                  onClick={() => setIsOpenAddModal(true)}
                >
                  <span className="blue-text add-contact-point-button">
                    + {t("add")}
                  </span>
                </p>
              </div>
              <div className="contact-point-content">
                {contactPoint.map((item, index) => (
                  <div className="item-wrap info-contact" key={index}>
                    <div className="d-grid">
                      <div className="d-flex header-child-content">
                        <p className="blue-text bold" style={{ cursor: "default" }}>{item?.full_name}</p>
                        <Dropdown
                          elementId="contact-point"
                          options={[t("edit"), t("delete")]}
                          styles={{ height: "10px", marginLeft: "auto" }}
                          editFrom={true}
                          onChange={(value) => {
                            value === t("edit") &&
                              handleEditContactPointClick(contactPoint[index]);
                            value === t("delete") &&
                              handleDeleteContactPointClick(
                                contactPoint[index]
                              );
                          }}
                          arrowIcon={<ArrowIcon />}
                        ></Dropdown>
                      </div>
                      <div className="body-child-content">
                        <p style={{ color: "#80868B", fontSize: "14px" }}>
                          {item?.job_position}
                        </p>
                        <p className="blue-text">{item?.email}</p>
                        <p className="blue-text">{item?.phone}</p>
                        <p className="blue-text">{item?.address}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="contact-point">
              <div className="d-flex contact-point-header">
                <p className="bread-crumb">
                  <ArrowDownIconOutlined
                    style={{
                      marginRight: "0.2em",
                      color: "#5f89ba",
                    }}
                  ></ArrowDownIconOutlined>
                  {t('list')}
                </p>
                <p
                  className="bread-crumb"
                  onClick={() => {
                    if (!contactData.is_blacklist) {
                      setIsOpenAddContactToListModal(true);
                      setContactListField(handleCreateListField());
                    }
                  }}
                >
                  <span className="blue-text add-contact-point-button">
                    + {t("add")}
                  </span>
                </p>
              </div>
              <div className="contact-point-content">
                {contactList.map((item, index) => (
                  <div className="item-wrap info-contact" key={index}>
                    <div className="d-grid">
                      <div className="d-flex">
                        <p className="blue-text bold" onClick={() => navigate(`/contacts-list/${item.id}`)}>{item?.name}</p>
                        {/* <Dropdown
                          elementId="contact-point"
                          options={["Edit", "Delete"]}
                          styles={{ height: "10px", marginLeft: "auto" }}
                          editFrom={true}
                          arrowIcon={<ArrowIcon />}
                        ></Dropdown> */}
                      </div>
                      <div>
                        <p style={{ color: "#80868B", fontSize: "14px" }}>
                          {t('source')}: <span className="blue-text">{item?.source}</span>
                        </p>
                        <p style={{ color: "#80868B", fontSize: "14px" }}>
                          {t('unit')}: <span className="blue-text">{item?.unit?.name}</span>
                        </p>
                        <p style={{ color: "#80868B", fontSize: "14px" }}>
                        {t('created_date')}: <span className="blue-text">{formatDate(moment(item?.created_at))}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <ListDeal
              id={id}
              navigate={navigate}
              contact={contactData}
              contactPoint={contactPoint}
              reloadAt={reloadDealAt}
              setReloadDealAt={setReloadDealAt}
              setLoading={setLoading}
            />
          </div>
        </div>
        <Modal
          title="Note"
          isOpen={isOpenCreateModal}
          onClose={() => setIsOpenCreateModal(false)}
        />
        <PointModal
          title={t("add_contact_point")}
          isOpen={isOpenAddModal}
          onClose={() => setIsOpenAddModal(false)}
          fields={ADD_CONTACT_POINT_FIELD}
          isCreate={false}
          onReload={reloadContactPointList}
          isEdit={false}
          contactId={id}
          deal={state?.deal ? state?.deal : null}
        />
        <ModalCenter
          isOpen={isOpenConfirmModal}
          title={"Confirmation"}
          onClose={() => setIsOpenConfirmModal(false)}
          useConfirm={true}
          onConfirm={({ contactListId, date }) => {
            handleStageChange(
              contactData?.newStage.toLowerCase().replace(" ", "_"),
              contactListId?.value,
              date
            );
            setIsOpenConfirmModal(false);
          }}
          contact={{ ...contactData, stage: contactData?.newStage }}
        />
        <PointModal
          title={t("edit_contact_point")}
          isOpen={isOpenEditModal}
          onClose={() => setIsOpenEditModal(false)}
          fields={EDIT_CONTACT_POINT_FIELD}
          isCreate={false}
          onReload={reloadContactPointUpdateList}
          isEdit={true}
          contactId={id}
          contactPoint={selectedContactPointEdit}
        />

        {selectedContactPointDelete && (
          <ModalDelete
            isOpen={isOpenDeleteModal}
            title={t("confirm_delete")}
            onClose={() => {
              setIsOpenDeleteModal(false);
              setSelectedContactPointDelete(null);
            }}
            name={t("contact_point")}
            useConfirm={true}
            type={"filter"}
            onConfirm={() =>
              handleDeleteContactPoint(selectedContactPointDelete)
            }
          />
        )}

        {isOpenAddContactToListModal &&
          <ListModal
            title={t("add_contact_list")}
            isOpen={isOpenAddContactToListModal}
            onClose={() => setIsOpenAddContactToListModal(false)}
            fields={contactListField}
            unit={contactData?.unit}
            idContact={contactData?.id}
            onSuccess={handleAddToListSuccess}
            setLoading={setLoading}
            lists={contactList}
          />
        }
        {isShowModalPrivate && (
          <ModalPrivate
            isOpen={isShowModalPrivate}
            dataUsers={contactData?.private_users}
            onClose={() => setIsShowModalPrivate(false)}
            onSuccess={handleSharePrivateSuccess}
            onFailed={handleSharePrivateFailed}
            contact={contactData}
          />
        )}
        {changedItemsCount > 0 && (
          <div
            className="d-flex"
            style={{
              backgroundColor: "#F6F7FA",
              height: "75px",
              position: "absolute",
              borderTop: "solid 0.8px #5F89BA",
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1,
            }}
          >
            <button
              className="button btn-create"
              onClick={() => {
                handleEditContact();
              }}
            >
              {t('save')}
            </button>
            <button
              className="button btn-cancel"
              onClick={() => {
                setContactData(selectedData);
                setChangedItemsCount(0);
                setErrors({});
              }}
            >
              {t('cancel')}
            </button>
            <p
              className="font-bold"
              style={{ marginLeft: "15px", marginTop: "25px" }}
            >
              {changedItemsCount} item(s) was/were changed
            </p>
          </div>
        )}
        {toast.show && (
          <Toast
            message={toast.message}
            duration={3000}
            error={toast.error}
            onClose={() => {
              setToast({ show: false });
            }}
          />
        )}
      </div>
    </>
  );
}

export default ContactDetail;
