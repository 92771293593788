const en = {
  translation: {
    homepage: 'Homepage',
    about: 'About',
    login: 'Login',
    logout: 'Logout',
    profile: 'Profile',
    english: 'English',
    vietnamese: 'Vietnamese',
    japanese: 'Japanese',
    dashboard: 'Dashboard',
    contact_menu: 'Contacts',
    contact_form: "Contacts Form",
    sale: 'Sales',
    finance: 'Finance',
    report: 'Reports',
    setting: 'Settings',
    list: 'Lists',
    deal: 'Deals',
    task: 'Tasks',
    order: 'Orders',
    invoice: 'Invoice',
    revenue: 'Revenue',
    team: 'Teams',
    target: 'Targets',
    dashboard_content: {
      personal_info: 'Personal Info',
      my_task: 'My Task',
      project_under_negotiation: 'Project under negotiation',
      total: 'Total'
    },
    name: 'Name',
    type: "Type",
    result: 'Result',
    contact: 'Contact',
    deadline: 'Deadline',
    quotation: "Quotation",
    status: 'Status',
    estimate_cost: 'Estimate Cost',
    expect_start_date: 'Expect Start Date',
    create: 'Create',
    import: 'Import',
    add_list: 'Add to List',
    all_contacts: 'All Contacts',
    blacklist: 'Blacklist',
    my_contact: 'My Contacts',
    unassigned_contacts: 'Unassigned Contacts',
    phone: 'Phone',
    contact_form_url: 'Contact form URL',
    unit: 'Unit',
    stage: 'Stage',
    created_date: 'Created Date',
    update_date: 'Update Date',
    last_updated: 'Last Updated',
    showing: 'Showing',
    to: 'to',
    of: 'of',
    entry: 'entry',
    entries: 'entries',
    message: {
      contact_create_success: 'Contact created successfully',
      contact_edit_success: 'Contact edited successfully',
      contact_import_success: 'Contacts imported successfully',
      error_import_contact: 'Error importing contacts',
      remove_contact_success: 'Remove to contacts successfully',
      move_blacklist_success: 'Move to Blacklist successfully',
      remove_blacklist_success: 'Remove From Blacklist successfully',
      stage_update_success: 'Contact stage updated successfully',
      error_update_stage: 'Error updating contact stage',
      filter_remove_success: 'Filter removed successfully',
      error_remove_filter: 'Error removing filter',
      filter_create_success: 'Filter created successfully',
      error_create_filter: 'Error creating filter',
      filter_update_success: 'Filter updated successfully',
      error_update_filter: 'Error updating filter',
      add_contact_list_success: 'Add Contacts to List successfully',
      update_pic_success: 'Update PIC successfully',
      export_contact_success: 'Export contacts successfully',
      export_contact_failed: 'Export contacts failed.',
    },
    action: 'Actions',
    stage_filter: 'Stages',
    unit_filter: 'Units',
    country: 'Countries',
    city: 'Cities',
    my_filter: 'My Filter',
    search: 'Search...',
    edit: 'Edit',
    export: 'Export',
    remove_blacklist: 'Remove From Blacklist',
    update_PIC: 'Update PIC',
    delete: 'Delete',
    move_blacklist: 'Move to Blacklist',
    export_contact: 'Export Contacts',
    close: 'Close',
    contact_selected: 'contact is selected',
    contacts_selected: 'contacts are selected',
    select_all: "Select all contacts on all pages with {{ contactCount }} contacts in total.",
    confirm_delete_contact: 'Confirmation Delete Contacts',
    confirm_move_blacklist: 'Confirmation Move to Blacklist',
    confirm_remove_blacklist: 'Confirmation remove from Blacklist',
    title_confirm_delete: 'Are you sure to remove',
    this_contact: 'this contact',
    these_contacts: 'these contacts',
    from_blacklist: 'from blacklist',
    from_old_list: 'from old list and move to blacklist',
    add_contact_list: 'Add Contacts to List',
    add_existing: 'Add Existing',
    create_new: 'Create New',
    list_name: 'List Name',
    source: 'Source',
    add: 'Add',
    cancel: 'Cancel',
    select_all_contact: 'Select all contacts on all pages',
    message_error_list_name: 'List Name field is required.',
    create_contact: 'Create Contact',
    address: 'Address',
    industry: 'Industry',
    channel: 'Channel',
    marketer: 'Marketer',
    saler: 'Saler',
    company_classification: 'Company Classification',
    company: 'Company',
    individual: 'individual',
    update: 'Update',
    edit_contact: 'Edit Contact',
    file: 'File',
    button_download: 'Download Sample File',
    add_blacklist: 'Add to Blacklist',
    size: 'Size',
    order_date: 'Order Date',
    total: 'Total',
    order_file: 'Order File',
    estimate_file: 'Estimate File',
    expected_start: 'Expected Start',
    expected_end: 'Expected End',
    get_contact: 'Get Contacts',
    assign_me: 'Assign me',
    save: 'Save',
    input_number: 'Input number',
    contact_point: 'Contact Point',
    expected_revenue: 'Expected Revenue',
    all_deal: 'All Deals',
    my_deal: 'My Deals',
    total_expected_revenue: 'Total Expected Revenue',
    all_task: 'All Tasks',
    my_task: 'My Tasks',
    all: 'All',
    revenue_distribution_needed: 'Revenue Distribution Needed',
    invoice_creation_needed: 'Invoice Creation Needed',
    order_file_needed: 'Order File Needed',
    estimate_file_needed: 'Estimate File Needed',
    invoice_name: 'Invoice Name',
    order_name: 'Order Name',
    invoice_amount_pre: 'Invoice Amount (Pre-tax)',
    tax: 'Tax',
    invoice_amount_after: 'Invoice Amount (After-tax)',
    received_amount: 'Received Amount',
    currency: 'Currency',
    note: 'Note',
    payment_date: 'Payment Date',
    due_date: 'Due Date',
    create_date: 'Create Date',
    total_received: 'Total Received',
    current_month: 'Current Month',
    overdue: 'Overdue',
    all_invoice: 'All Invoices',
    payment_status: 'Payment Status',
    order_revenue: 'Order Revenue',
    revenue_distribution: 'Revenue Distribution',
    invoice_revenue: 'Invoice Revenue',
    last_month: 'Last 3 months',
    next_month: 'Next 3 months',
    target_revenue: "Target",
    actual_revenue: "Actual Revenue",
    actual_target: "Actual/Target (%)",
    revenue_per_contact: "Revenue per Contact",
    expected: "Expected",
    distribution: 'Distribution',
    total_revenue: 'Total(JPY)',
    export_revenue: 'Export Revenue',
    amount_pre_tax: 'Amount(Pre-tax)',
    amount_after_tax: 'Amount(After-tax)',
    receive_amount: 'Receive Amount',
    title_report: 'Please select List and Date to view Report',
    team_name: 'Team Name',
    team_manager: 'Team Manager',
    members: 'Members',
    company_unit: 'Company/Unit',
    target_by_unit: "{{ unit }} Target",
    total_first_quater: 'Total The first Quater',
    total_second_quater: 'Total The Second Quater',
    total_third_quater: 'Total The Third Quater',
    total_fourth_quater: 'Total The Fourth Quater',
    import_modal: 'Import Modal',
    system_field: 'System Fields',
    deal_create_success: 'Deal created successfully',
    deal_create_fail: 'Deal created failed',
    deal_update_success: 'Deal updated successfully',
    deal_delete_success: 'Deal deleted successfully',
    expected_close_date: 'Expected Closing Date',
    edit_deal: 'Edit Deal',
    create_deal: 'Create Deal',
    owner: 'Owner',
    confirm_delete: 'Confirmation Delete',
    edit_contact_point: 'Edit Contact Point',
    add_contact_point: 'Add Contact Point',
    full_name: 'Full name',
    job_position: 'Job position',
    private: 'Private',
    share_with: 'Share with',
    people: 'people(s)',
    add_people: 'Add people(s)',
    history_log: 'History logs',
    meeting: 'Meeting',
    back_list: 'Back to Lists',
    this_deal: 'this deal',
    these_deal: 'these deals',
    notification: 'Notification',
    deal_owner: 'Deal Owner',
    add_contact: 'Add Contact',
    confirm_detach: 'Confirmation Detach',
    title_confirm_detach: 'Are you sure to detach this',
    forecast_start_date: 'Forecast Start Date',
    forecast_end_date: 'Forecast End Date',
    total_price: 'Total Price',
    expected_start_date: 'Expected Start Date',
    expected_end_date: 'Expected End Date',
    create_quotation: 'Create Quotation',
    quotation_info: 'Quotation Info',
    sender: 'Sender',
    proposal_name: 'Proposal Name',
    related_information: 'Related information',
    project_type: 'Project type',
    budget: 'Budget (Man Month)',
    odc_detail: 'ODC-detail',
    project_type_advice: 'Project type advice',
    desired_project_type: 'Desired project type',
    technology: 'Technology',
    technology_select: 'Technology select',
    technology_advice: 'Technology advice',
    file_upload: 'File Upload',
    project_profession: 'Project profession',
    sale_channel: 'Sale channel',
    application_type: 'Application type',
    estimation_cost: 'Estimation cost',
    cost: 'Cost',
    receive_date: 'Receive date',
    estimator: 'Estimator',
    link_proposal: 'Link proposal',
    cancel_reason: 'Cancellation Reasons',
    cost_adjustment_reasons: 'Cost Adjustment Reasons',
    create_order: 'Create Order',
    upload_later: 'Upload later',
    internal_project: 'Internal Project',
    product: 'Product',
    exchange_rate_order: 'Exchange rate - Order Date (VND)',
    exchange_rate_payment: 'Exchange rate - Payment Successful (VND)',
    item_list: 'Item List',
    add_item: 'Add Item',
    quantity: 'Quantity',
    unit_price: 'Unit price',
    title_add_item: 'Please add item(s)',
    month: 'Month',
    title_revenue_distribution: 'Please Choose Expected Date',
    invoice_list: 'Invoice list',
    title_invoice_list: 'Please add invoice(s)',
    add_invoice: 'Add Invoice',
    regenerate_invoice: 'Regenerate Invoice',
    confirm_regenerate_invoice: 'Confirmation Regenerate invoices',
    content_confirm_invoice_1: 'Regenerating invoices will clear all current invoices.',
    content_confirm_invoice_2: 'Are you sure you want to Regenerate Invoices?',
    help: 'Help',
    order_title_revenue: '(Uncheck to hide orders for the selected month)',
    deal_note: 'Deal Note(s)',
    invoice_code: 'Invoice Code',
    search_activity: 'Search Activities',
    show_detail: 'Show Details',
    updated_deal: "${user} updated deal ${deal}",
    updated_am: "${user} updated AM from ${from} to ${to}.",
    updated_saler: "${user} updated Saler from ${from} to ${to}.",
    updated_marketer: "${user} updated Marketer from ${from} to ${to}.",
    contact_updated_information: "${user} updated contact informations.",
    added_task: "${user} added task ${task}.",
    updated_note: "${user} updated note ${note}.",
    added_note: "${user} added note ${note}.",
    updated_task: "${user} updated task ${task}.",
    contact_updated_stage: "${user} updated stage from ${from} to ${to} on List ${list}.",
    added_contact_to_list: "${user} added this contact to List ${list}",
    contact_created: "${user} created this contact.",
    contact_point_created: "${user} created contact point ${contact_point}.",
    added_deal: "${user} added deal ${deal}.",
    added_existing_contact_points: "${user} added existing contact point ${contact_point}.",
    deleted_note: "${user} deleted note ${note}.",
    detached_deal: "${user} detached from deal ${deal}.",
    contact_deleted: "${user} deleted this contact.",
    deleted_deal: "${user} deleted this contact.",
    deleted_task: "${user} deleted task ${task}.",
    moved_contact_to_blacklist: "${user} moved this contact to Blacklist.",
    removed_contact_from_list: "${user} removed this contact from List ${list}.",
    removed_contact_from_blacklist: "${user} removed this contact from Blacklist.",
    task_created: "${user} created this task.",
    updated_task_type: "${user} changed ${field} from ${from} to ${to}.",
    updated_task_description: "${user} updated task description.",
    added_task_comment: "${user} added a comment.",
    auto_created_task: "System created this task.",
    updated_task_result: "${user} changed ${field} from ${from} to ${to}.",
    updated_task_name: "${user} ${action} this task from ${from} to ${to}.",
    detached_contact_points: "${user} detached contact point ${contact_point}.",
    deal_created: "${user} created this deal.",
    added_contact: "${user} added contact ${contact}.",
    added_existing_contact_point_to_deal: "${user} added existing contact point ${contact_point}.",
    detached_contact_point_from_deal: "${user} detached contact point ${contact_point}.",
    deal_updated_stage: "${user} updated stage from ${from} to ${to}.",
    added_items_to_deal: "${user} added List items.",
    updated_items_from_deal: "${user} updated List items.",
    detached_contact: "${user} detached from contact ${contact}.",
    added_order: "${user} created order ${order}.",
    updated_order: "${user} updated order ${order}.",
    task_assigned: "${user} assigned this task to ${assigner}.",
    deleted_order: "${user} deleted order ${order}.",
    updated_task_deadline: "${user} changed ${field} from ${from} to ${to}.",
    task_edit_history: "Task Edit History",
    note_edit_history: "Note Edit History",
    deal_edit_history: "Deal Edit History",
    contact_edit_history: "Contact Edit History",
    order_edit_history: "Order Edit History",
    list_item_edit_history: "List Items Edit History",
    added_a: "added a",
    meeting_title: "You must add meeting note to Finish this task",
    assigned_to: "Assigned To",
    meeting_note: "Meeting Note",
    quotation_title_1: "Please wait until the",
    quotation_title_2: "is estimated to complete the task",
    add_comment: "Add comment",
    no_data: "No Data found",
    title_no_contact_point: "This contact doesn't have any contact point",
    add_new_point: "Add new Contact point",
    edit_contact_form: "Edit Contact Form",
    exclude: "Exclude",
    list_exclude: "List(s)",
    time: 'Time',
    actor: 'Actor',
    previous_or: 'Previous OR',
    updated_or: 'Updated OR',
    previous_dr: 'Previous DR',
    updated_dr: 'Updated DR',
    order_detail_log: 'Order Detail',
    invoice_detail_log: 'Invoice Detail',
    unit_log: 'Unit',
    action_log: 'Action',
    order_log: 'Order',
    order_invoice_log: 'Order',
    inv_amount_before: 'Inv Amount before',
    inv_amount_after: 'Inv Amount after',
    receive_amount_before: 'Receive Amount before',
    receive_amount_after: 'Receive Amount after',
    search_log: 'Search',
    invoice_code_log: 'Invoice Code',
    note_log: 'Note'
  },
};

export default en;
