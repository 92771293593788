import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import FileSymbol from "../../../../assets/images/fileSymbol.png";
import {
  fetchEmails,
  resetEmails,
  updateOptionSearch,
  downloadFile
} from "../../../../store/emailsSlice";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import ArrowDownIconOutlined from "@mui/icons-material/KeyboardArrowDown";
import "./content-styles.css";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import _ from "lodash";
import moment from "moment";
import NoData from "../../../../components/SkeletonLoading";
import { useTranslation } from "react-i18next";

function EmailTab({ contact }) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [emailList, setEmailList] = useState([]);
  const [isOpen, setIsOpen] = useState([]);
  const [showMoreButton, setShowMoreButton] = useState([]);
  const [anchorEl, setAnchorEl] = useState([]);
  const [searchLength, setSearchLength] = useState(1);
  const [searchText, setSearchText] = useState(null);

  useEffect(() => {
    resetEmails();
    dispatch(updateOptionSearch({ options: "", type: "text" }));
    dispatch(fetchEmails(parseInt(contact.id))).then((res) => {
      let dataEmail = _.groupBy(res?.data, ({ created_at }) =>
        moment(created_at.split("-")[0]).format("MMMM YYYY")
      );

      setEmailList(dataEmail);
    });
  }, [contact.id, dispatch]);

  useEffect(() => {
    let arrayOpen = [];
    let array = [];
    let showAnchor = [];
    Object.keys(emailList)?.map((month, indexMonth) => {
      arrayOpen[indexMonth] = [...isOpen];
      array[indexMonth] = [...showMoreButton];
      showAnchor[indexMonth] = [...anchorEl];
      emailList[month]?.map((item, index) => {
        arrayOpen[indexMonth][index] = false;
        showAnchor[indexMonth][index] = false;
        const element = document.getElementById(
          `content_${indexMonth}_${index}`
        );

        if (element) {
          array[indexMonth][index] =
            element.scrollHeight !== element.clientHeight;
        }
      });
    });
    setIsOpen([...arrayOpen]);
    setShowMoreButton([...array]);
    setAnchorEl([...showAnchor]);
  }, [emailList]);

  const handleShowMore = (indexMonth, index, action) => {
    showMoreButton[indexMonth][index] = action;
    setShowMoreButton([...showMoreButton]);
    isOpen[indexMonth][index] = !isOpen[indexMonth][index];
    setIsOpen([...isOpen]);
  };
  const handleClick = (event, index, indexMonth) => {
    anchorEl[indexMonth][index] = anchorEl[indexMonth][index]
      ? null
      : event.currentTarget;
    setAnchorEl([...anchorEl]);
  };

  const handleSearch = (value) => {
    let count = 0;
    const data = Object.keys(emailList)?.reduce((result, month, index) => {
      result[month] = emailList[month]?.map((item) => {
        if (item["subject"].toLowerCase().search(value) != -1) {
          count++;
        }
        return {
          ...item,
          isShow: item["subject"].toLowerCase().search(value) != -1,
        };
      });
      return result;
    }, {});

    if (value.trim() === "") {
      count = 1;
    }
    setSearchLength(count);
    setEmailList(data);
    dispatch(updateOptionSearch({ option: value, type: "text", emails: data }));
  };
  const handleChangeSearch = async (event) => {
    setSearchText(event.target.value);
    handleSearch(event.target.value);
  };
  const downloadFileHandle = async (fileDownload) => {
    await dispatch(downloadFile(fileDownload));
  };
  return (
    <>
      <div className="activity">
        <div className="activity__search">
          <input
            type="text"
            value={searchText}
            onChange={(e) => handleChangeSearch(e)}
            className="search"
            placeholder={t("search_activity")}
          />
          <SearchIcon
            className="search-icon"
            onClick={() => {
              /* handle search icon click event */
            }}
          />
        </div>
      </div>

      <div
        style={{
          marginBottom: "5%",
        }}
      >
        {Object.keys(emailList)?.length === 0 ||
          Object.keys(emailList)?.length == undefined ? (
          <>
            {emailList.length == undefined ? (
              <p
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  fontSize: "14px",
                }}
              >
                {t('no_data')}
              </p>
            ) : (
              <NoData />
            )}
          </>
        ) : (
          Object.keys(emailList)?.map((month, indexMonth) => (
            <>
              <p style={{ marginTop: "20px", fontSize: "14px" }}>{month}</p>
              <div className="email-item">
                {emailList[month]?.map((email, index) => (
                  <>
                    {email.isShow === true && (
                      <>
                        <div
                          className="item-wrap"
                          style={index == 0 ? { marginTop: "5px" } : null}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              alignItems: "flex-start",
                              fontSize: "14px",
                            }}
                          >
                            <div style={{ display: "flex", width: "100%" }}>
                              <div>
                                {showMoreButton[indexMonth] !== undefined &&
                                  showMoreButton[indexMonth][index] === true ? (
                                  <ArrowForwardIosOutlinedIcon
                                    style={{
                                      marginRight: "0.2em",
                                      color: "#5f89ba",
                                    }}
                                    onClick={() => {
                                      handleShowMore(indexMonth, index, false);
                                    }}
                                  ></ArrowForwardIosOutlinedIcon>
                                ) : (
                                  <ArrowDownIconOutlined
                                    style={{
                                      marginRight: "0.2em",
                                      color: "#5f89ba",
                                    }}
                                    onClick={() =>
                                      handleShowMore(indexMonth, index, true)
                                    }
                                  ></ArrowDownIconOutlined>
                                )}
                                <span
                                  className="bold"
                                  style={{ color: "#33475B" }}
                                >
                                  {email.subject}
                                </span>
                              </div>

                              <p
                                style={{
                                  marginLeft: "auto",
                                  color: "#80868B",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {email.created_at}
                              </p>
                            </div>
                            <div
                              style={{
                                marginRight: "auto",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "baseline",
                                marginLeft: "calc(24px + 0.2em)",
                              }}
                            >
                              <div style={{ textAlign: "left" }}>
                                <p style={{ color: "#33475B" }}>from</p>
                              </div>
                              <p className="show-type">{email.from}</p>
                            </div>
                            <div
                              style={{
                                marginRight: "auto",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "baseline",
                                marginLeft: "calc(24px + 0.2em)",
                              }}
                            >
                              <div style={{ textAlign: "left" }}>
                                <p style={{ color: "#33475B" }}>to</p>
                              </div>
                              <p
                                className="paragraph"
                                style={{
                                  marginLeft: "10px",
                                  fontStyle: "italic",
                                }}
                                onClick={(e) =>
                                  handleClick(e, index, indexMonth)
                                }
                                aria-describedby={
                                  anchorEl[indexMonth] !== undefined &&
                                    Boolean(anchorEl[indexMonth][index])
                                    ? `simple-popper_${indexMonth}_${index}`
                                    : undefined
                                }
                                type="button"
                              >
                                {email.to}, {email.cc}
                                {email?.bcc ? `, ${email?.bcc}` : null}
                              </p>

                              <Popper
                                id={
                                  anchorEl[indexMonth] !== undefined &&
                                    Boolean(anchorEl[indexMonth][index])
                                    ? `simple-popper_${indexMonth}_${index}`
                                    : undefined
                                }
                                open={
                                  anchorEl[indexMonth] !== undefined
                                    ? Boolean(anchorEl[indexMonth][index])
                                    : false
                                }
                                anchorEl={
                                  anchorEl[indexMonth] !== undefined
                                    ? anchorEl[indexMonth][index]
                                    : null
                                }
                              >
                                <Box
                                  style={{
                                    padding: "18px",
                                    borderRadius: "4px",
                                    fontSize: "13px",
                                    display: "flex",
                                    justifyContent: "center",
                                    minHeight: "116px",
                                    maxWidth: "200px",
                                  }}
                                  sx={{
                                    border: "1px solid rgba(95, 137, 186, 0.5)",
                                    p: 1,
                                    bgcolor: "background.paper",
                                  }}
                                >
                                  <div>
                                    <p>From: {email.from}</p>
                                    <div
                                      style={{
                                        marginLeft: "16px",
                                        display: "flex",
                                      }}
                                    >
                                      <p>To:</p>
                                      <p>{email.to}</p>
                                    </div>
                                    <div
                                      style={{
                                        marginLeft: "16px",
                                        display: "flex",
                                      }}
                                    >
                                      <p>Cc:</p>
                                      <p>{email.cc}</p>
                                    </div>

                                    {email?.bcc && (
                                      <div
                                        style={{
                                          marginLeft: "16px",
                                          display: "flex",
                                        }}
                                      >
                                        <p>Bcc:</p>
                                        <p>{email?.bcc}</p>
                                      </div>
                                    )}
                                  </div>
                                </Box>
                              </Popper>
                            </div>
                            <hr
                              style={{ margin: "16px 0 0 0", width: "100%" }}
                            />

                            <p
                              className={
                                isOpen[indexMonth] !== undefined &&
                                  isOpen[indexMonth][index]
                                  ? null
                                  : "paragraph"
                              }
                              style={{
                                textAlign: "left",
                                marginTop: "16px",
                                marginBottom: "16px",
                                marginLeft: "calc(24px + 0.2em)",
                              }}
                              id={`content_${indexMonth}_${index}`}
                            >
                              {
                                email.content_html ? (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: email.content,
                                    }}
                                  />
                                ) : (
                                  <span style={{
                                    whiteSpace: 'pre-wrap'
                                  }}>
                                    {email.content}
                                  </span>
                                )
                              }

                            </p>
                            {email?.attachment && (
                              <>
                                {email?.attachment &&
                                  email.attachment.map((element) => (
                                    <div
                                      className="d-flex"
                                      style={{
                                        whiteSpace: "nowrap",
                                        overflow: "auto",
                                        paddingBottom: "10px",
                                        cursor: "pointer",
                                      }}
                                      key={element.split("/")[2]}
                                      onClick={() => downloadFileHandle(element.startsWith('/') ? element.substr(1) : element)}
                                    >
                                      <img src={FileSymbol} alt={"File"} />
                                      <p
                                        className="file-name-title blue-text"
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        {element.split("/")[2]}
                                      </p>
                                    </div>
                                  ))}
                              </>
                            )}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                                color: "#0091AE",
                                fontStyle: "italic",
                              }}
                            >
                              {showMoreButton[indexMonth] !== undefined &&
                                showMoreButton[indexMonth][index] && (
                                  <button
                                    onClick={() =>
                                      handleShowMore(indexMonth, index, false)
                                    }
                                  >
                                    {isOpen[indexMonth] !== undefined &&
                                      isOpen[indexMonth][index]
                                      ? ""
                                      : "See more"}
                                  </button>
                                )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ))}
              </div>
            </>
          ))
        )}
      </div>
    </>
  );
}

export default EmailTab;
