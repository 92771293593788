import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ArrowRightIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowDownIconOutlined from "@mui/icons-material/KeyboardArrowDown";
import { capitalizeFirstLetter, formatLabel, formatNumber, formatNumberWithCommasAndDot, formatNumberWithNoCommas } from "../../../utils/helper";
import { validateForm, countDifferences } from "../../../utils/helper";
import Loading from "../../../components/Loading";
import Dropdown from "../../../components/Dropdown";
import Toast from "../../../components/ToastMessage";
import { Menu, MenuItem, debounce } from "@mui/material";
import Modal from "../../../components/ModalBottom";
import ModalDelete from "../../../components/ModalCenter";
import PointModal from "../../../containers/StaticPages/Deal/ModalDetail";
import { ADD_CONTACT_POINT_FIELD, EDIT_CONTACT_POINT_FIELD, DEAL_RELATIONS, DEAL_FIELD_EDIT_FAST } from "../../../utils/constant";
import { getDealById, getStage, updateDeal } from "../../../store/dealsSlice";
import { useParams } from "react-router-dom";
import "./index.css";
import TabSwitcher from "./DealDetailChild/TabSwitcher";
import ModalCenter from "../../../components/ModalCenter";
import { fetchUnits } from "../../../store/unitSlice";
import ConfirmDetachRelationModal from "./ConfirmDetachRelationModal";
import moment from 'moment';
import { getAllUsers, getTeamsForInput } from "../../../store/teamSlice";
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { deleteOrder, resetNotification } from "../../../store/ordersSlice";
import { Helmet } from 'react-helmet';
import { Button } from "@mui/base";

// eslint-disable-next-line require-jsdoc
function DealDetail() {
  const { t } = useTranslation();
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { state } = useLocation();
  const location = useLocation();

  // eslint-disable-next-line no-unused-vars
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [dealData, setDealData] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [contactPoints, setContactPoints] = useState([]);
  const [changedItemsCount, setChangedItemsCount] = useState(0);
  const [errors, setErrors] = useState({});
  const [toast, setToast] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [selectedContactPointEdit, setSelectedContactPointEdit] = useState(null);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenContactPointModal, setIsOpenContactPointModal] = useState(false);
  const [dataType, setDataType] = useState("");
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const notification = useSelector((state) => state.orders.notification);
  const unitOptions = useSelector((state) => state.units.data.map(({ id, name }) => ({ id, name })));
  const userInfo = JSON.parse(localStorage.getItem('user_info'));
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [dataOfQuotation, setDataOfQuotation] = useState({});
  const [quotations, setQuotations] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const detachRelationInit = {
    isOpen: false,
    objectName: '',
    relationName: '',
    deleteIds: [],
    dealId: null
  };
  const [detachRelation, setDetachRelation] = useState({ ...detachRelationInit });
  const stageDeals = useSelector((state) => state.deals.stages);
  const [orders, setOrders] = useState([]);
  const [orderId, setOrderId] = useState(null);
  useEffect(() => {
    dispatch(fetchUnits());
    dispatch(getStage());
  }, [dispatch]);

  const expectRevenueRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const contactDetailContainer = document.querySelector(".contact-detail-container");
    const fullViewport = document.querySelector(".full-viewport");
    if (contactDetailContainer && window.innerWidth <= 1050) {
      fullViewport.style.width = "1000px";
    } else {
      fullViewport.style.width = "100%";
    }
  }, []);

  useEffect(() => {
    if (state === "quotation") {
      setToast({
        show: true,
        message: "Quotation created successfully",
      });
      navigate(location.pathname, { replace: true });
    } else if (state?.quotation) {
      setToast({
        show: true,
        message: `Quotation "${state.quotation}" updated successfully`,
      });
      navigate(location.pathname, { replace: true });
    }
  }, [state]);

  useEffect(() => {
    if (notification !== "") {
      setToast({ show: true, message: notification })
      dispatch(resetNotification());
    }
  }, [dispatch])

  useEffect(() => {
    if (contacts) {
      setDataOfQuotation((prev) => ({
        ...prev,
        contacts: contacts.map((contact) => ({
          value: contact.id, label: contact.name
        }))
      }))

    }
    if (contactPoints) {
      setDataOfQuotation((prev) => ({
        ...prev,
        contactPoints: contactPoints.map((contactPoint) => ({
          value: contactPoint.id, label: contactPoint.full_name
        }))
      }))

    }
    if (dealData) {
      setDataOfQuotation((prev) => ({
        ...prev,
        deal: { value: dealData?.id, label: dealData?.name },
        currency: { value: dealData.unit?.currency?.id, label: dealData.unit?.currency?.name }
      }))

    }

  }, [dealData]);

  const storeDealData = (deal) => {

    if ('contacts' in deal) {
      const getContacts = [...deal.contacts];
      setContacts(getContacts);
      delete deal['contacts'];
    }

    if ('contact_points' in deal) {
      setContactPoints([...deal.contact_points]);
      delete deal['contact_points'];
    }
    if ('orders' in deal) {
      setOrders([...deal.orders]);
      delete deal['orders'];
    }
    if ('quotations' in deal) {
      setQuotations([...deal.quotations]);
      delete deal['quotations']
    }
    if ('task_contacts' in deal) {
      setTasks([...deal.task_contacts]);
      delete deal['task_contacts']
    }
    setDealData(deal);
  }

  const fetchData = async () => {
    try {
      const deal = await dispatch(getDealById(parseInt(id)));
      if (!deal) navigate('/deals');
      if (deal.user) {
        const result = await dispatch(getTeamsForInput("", deal.user.id));
        let listTeams;
        if (result) {
          listTeams = result.data.data;
          setTeams(listTeams);
        }
      }

      storeDealData(deal);
      setSelectedData(deal);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [dispatch]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
      autoResize(inputRef.current);
    }
  }, [isEditing]);

  useEffect(() => {
    if (dataType === "revenue" && expectRevenueRef.current) {
      expectRevenueRef.current.focus();
      autoResize(expectRevenueRef.current);
    }
  }, [dataType]);

  useEffect(() => {
    if (dealData) {
      const textareas = document.querySelectorAll(".textarea-autosize");
      textareas.forEach((textarea) => {
        autoResize(textarea);
      });
    }
  }, [dealData]);

  const reloadContactList = async (res) => {
    setLoading(true)
    try {
      const contact = await dispatch(getDealById(parseInt(id)));
      if (res.status_code === 200) {
        setContacts(contact.contacts);
        setContactPoints(contact.contact_points);
        setLoading(false);
        setToast({
          show: true,
          message: "Contact created successfully",
        });
      } else {
        setLoading(false);
        setToast({
          show: true,
          error: true,
          message: ADD_CONTACT_POINT_FIELD.map(
            (item) => res?.error[item.id] ?? null
          ),
        });
      }
    } catch (error) {
      //
    }
  };

  const reloadContactPointList = async (res) => {
    setLoading(true);
    try {
      const contactPoint = await dispatch(getDealById(parseInt(id)));
      if (res.status_code === 200) {
        setContactPoints(contactPoint.contact_points);
        setLoading(false);
        setToast({
          show: true,
          message: "Contact point created successfully",
        });
      } else {
        setLoading(false);
        setToast({
          show: true,
          error: true,
          message: ADD_CONTACT_POINT_FIELD.map((item) => res?.error[item.id] ?? null),
        });
      }
    } catch (error) {
      //
    }
  };

  const reloadContactPointUpdateList = async (res) => {
    setLoading(true);
    try {
      const deal = await dispatch(getDealById(parseInt(id)));
      if (res.status_code === 200) {
        setContactPoints(deal.contact_points);
        setLoading(false);
        setToast({
          show: true,
          message: "Contact point updated successfully",
        });
        setIsOpenEditModal(false);
      } else {
        setLoading(false);
        setToast({
          show: true,
          error: true,
          message: EDIT_CONTACT_POINT_FIELD.map((item) => res?.error[item.id] ?? null),
        });
      }
    } catch (error) {
      //
    }
  };

  const handleInputChange = (e, fieldName) => {
    let newValue;
    if (fieldName === "closing_date") {
      newValue = e
    } else {
      newValue = e.target.value !== "" ? e.target.value : null;
    }
    if (fieldName === "revenue") {
      newValue = formatNumberWithCommasAndDot(newValue);
    }
    const updatedDealData = { ...dealData, [fieldName]: newValue };

    const differences = countDifferences(selectedData, updatedDealData);
    setChangedItemsCount(differences);
    setDealData(updatedDealData);
  };

  const checkExpectedRevenue = () => {
    let newValue = formatNumberWithNoCommas(dealData['revenue']);
    if (isNaN(newValue)) {
      newValue = null;
    }
    const updatedDealData = { ...dealData, 'revenue': newValue };

    const differences = countDifferences(selectedData, updatedDealData);
    setChangedItemsCount(differences);
    setDealData(updatedDealData);
  }
  const handleDropdownChange = (value, fieldName) => {
    const updatedDealData = { ...dealData, [fieldName]: value };

    const differences = countDifferences(selectedData, updatedDealData);
    setChangedItemsCount(differences);
    setDealData(updatedDealData);
  };
  const handleEditDeal = async () => {
    const formIsValid = validateForm(DEAL_FIELD_EDIT_FAST, dealData, setErrors, true);
    if (formIsValid) {
      setLoading(true);
      let data = {
        id: dealData.id,
        name: dealData.name,
        user_id: dealData.user?.id,
        closing_date: dealData.closing_date && moment(dealData.closing_date).format('YYYY/MM/DD'),
        team_id: dealData.team?.id,
        stage_id: dealData.stage?.id,
        revenue: dealData.revenue
      };
      let updateSuccess = await dispatch(updateDeal(data));
      if (updateSuccess) {
        setToast({ show: true, message: t("Deal updated successfully") });
        setChangedItemsCount(0);
        setSelectedData(dealData)
        setErrors({});
      }
      setLoading(false);
    }
  }
  const handleGetDifferentUserChange = (userValue, teamValue) => {
    const updatedDealData = { ...dealData, user: userValue, team: teamValue ?? selectedData.team };

    const differences = countDifferences(selectedData, updatedDealData);
    setChangedItemsCount(differences);
    setDealData(updatedDealData);
  };

  const autoResize = (e) => {
    if (!e) return;
    e.style.height = "25px";
    if (e.value.length === 0) {
      e.style.height = "25px";
    } else {
      e.style.height = e.scrollHeight + "px";
    }
  };

  const handleEditContactPointClick = (item) => {
    setSelectedContactPointEdit(item);
    setIsOpenEditModal(true);
  };

  const onDetachRelationSuccess = (relationName, deleteIds, deal = null, message) => {
    if (!Array.isArray(deleteIds)) {
      deleteIds = [deleteIds];
    }
    setToast({
      show: true,
      message: message,
    });

    if (relationName === DEAL_RELATIONS.contact.relationName) {
      const contactsProcessed = contacts.filter((contact) => !deleteIds.includes(contact.id));
      setContacts([...contactsProcessed]);
      if (deal?.unit?.id && dealData?.unit?.id != deal?.unit?.id) {
        setDealData({
          ...dealData,
          unit: deal?.unit
        });
      }
      let contactPointIds = deal?.contact_points?.map(({ id }) => id);
      const contactPointsProcessed = contactPoints.filter((contactPoint) => contactPointIds.includes(contactPoint.id));
      setContactPoints([...contactPointsProcessed]);
    } else {
      const contactPointsProcessed = contactPoints.filter((contactPoint) => !deleteIds.includes(contactPoint.id));
      setContactPoints([...contactPointsProcessed]);
    }
  }

  const arrowIcon = (
    <ArrowDownIconOutlined
      style={{
        marginRight: "0.2em",
        color: "#5f89ba",
      }}
    ></ArrowDownIconOutlined>
  );

  const handleChangeUser = async (value) => {
    const result = await dispatch(getTeamsForInput("", value.id));
    let teamValue;
    let listTeams = null;
    if (result) {
      listTeams = result.data.data;
    }
    if (selectedData.team) {
      teamValue = selectedData.team;
      if (listTeams.length > 0) {
        if (listTeams.filter((item) => item.id === selectedData.team.id).length > 0) {
          setTeams(listTeams);
        } else {
          setTeams([selectedData.team, ...listTeams]);
        }
      } else {
        setTeams([selectedData.team]);
      }
    } else {
      if (listTeams.length > 0) {
        setTeams(listTeams);
        teamValue = listTeams[0];
      } else {
        setTeams([]);
      }
    }
    handleGetDifferentUserChange(value, teamValue)
  }
  const debounceDropDown = useCallback(
    debounce((searchText) => callOptions(searchText), 500),
    []);
  const callOptions = async (searchText) => {
    try {
      if (searchText === "") {
        setUsers([]);
      } else {
        const result = await dispatch(getAllUsers(searchText));
        if (result) {
          let listUser = result.payload.data;
          setUsers(listUser);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSearch = (searchText) => {
    debounceDropDown(searchText);
  }

  const handleDeleteOrder = async (id) => {
    setLoading(true);
    let updateSuccess = await dispatch(deleteOrder(userInfo.user.id, [id]));
    if (updateSuccess) {
      setToast({ show: true, message: t("Order deleted successfully") });
    }
    setIsOpenModalDelete(false);
    fetchData();
    setLoading(false);
  }

  const onClickAddContactPoint = () => {
    if (contacts.length === 1) {
      navigate(`/contact/${contacts[0].id}`, { state: { deal: dealData } });
    } else {
      setIsOpenConfirmModal(true);
    }
  }

  const handleClickAddQuotation = (event, tasks) => {
    if (tasks.length === 1) {
      navigate(`/quotation/create?dealId=${dealData.id}&taskId=${tasks[0].id}`)
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleCloseAddQuotation = (task) => {
    setAnchorEl(null);
    if (task) {
      navigate(`/quotation/create?dealId=${dealData.id}&taskId=${task.id}`)
    }
  };

  return (
    <>
      <Helmet>
        <title>{t("deal")}</title>
        <meta name="description" content="Description of Deals Page" />
      </Helmet>
      {loading && <Loading />}
      <div className="detail" style={{ overflow: "hidden" }}>
        <div className="d-flex detail-container">
          <div className="contact-detail-container">
            <div className="d-flex">
              <p
                className="bread-crumb"
                style={{ fontSize: "18px !important" }}
              >
                <span className="blue-text bread-crumb__link" onClick={() => navigate("/deals")}>
                  {t("deal")}
                </span>
                <>
                  <ArrowRightIcon
                    style={{
                      fontSize: "1em",
                      marginLeft: "0.2em",
                      marginRight: "0.2em",
                      position: "relative",
                      bottom: "0.1em",
                    }}
                  ></ArrowRightIcon>
                  <span className="text-xl" style={{ color: "#000000", wordBreak: "break-all" }}>
                    {dealData && dealData.name}
                  </span>
                </>
              </p>
            </div>
            {dealData ? (
              <div className="contact-info-container">
                <div className="contact-info-item">
                  <p className="contact-info-label">{t("name")}</p>
                  <textarea
                    maxLength={255}
                    className="contact-info-value large-input textarea-autosize"
                    value={dealData.name}
                    onChange={(e) => {
                      handleInputChange(e, "name");
                      autoResize(e.target);
                    }}
                    onFocus={(e) => (e.target.style.padding = "0px 10px")}
                    onBlur={(e) => (e.target.style.padding = "0px")}
                  />
                </div>
                {errors["Name"] && (
                  <span
                    className="error-text"
                    style={{
                      color: "red",
                      position: "relative",
                      bottom: "10px",
                    }}
                  >
                    {errors["Name"]}
                  </span>
                )}
                <div className="contact-info-item">
                  <p className="contact-info-label">{t("deal_owner")}</p>
                  <Dropdown
                    options={users}
                    component={dealData?.user && capitalizeFirstLetter(dealData?.user?.name)}
                    onChange={(value) => { handleChangeUser(value) }}
                    withSearch={true}
                    objSelect={true}
                    styles={{ cursor: 'pointer' }}
                    onSearch={(searchText) => { handleSearch(searchText, "Users") }}
                  ></Dropdown>
                </div>
                <div className="contact-info-item">
                  <p className="contact-info-label">{t("expected_close_date")}</p>
                  {dataType === "Expected Closing" ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        inputFormat="YYYY-MM-DD"
                        value={dealData?.closing_date}
                        onChange={(e) => handleInputChange(e.$d, "closing_date")}
                        onClose={(e) => {
                          setDataType("");
                        }}
                        open={true}
                        inputProps={{ readOnly: true }}
                        InputProps={{
                          sx: {
                            height: "30px",
                            fontSize: "14px",
                            fontFamily: "Inter",
                            color: "#0091AE",
                          },
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        minDate={moment().toDate()}
                      />
                    </LocalizationProvider>
                  ) : (
                    <p onClick={() => setDataType("Expected Closing")}>
                      {dealData.closing_date ?
                        moment(dealData.closing_date).format('YYYY/MM/DD') :
                        <Dropdown
                          options={[]}
                          component={""}
                        ></Dropdown>
                      }
                    </p>
                  )}
                </div>
                <div className="contact-info-item">
                  <p className="contact-info-label">{t("unit")}</p>
                  <Dropdown
                    options={unitOptions}
                    component={dealData?.unit?.name}
                    onChange={(unit) => {
                      setDealData({ ...dealData, unit });
                      handleDropdownChange(unit, "unit");
                    }}
                    objSelect={true}
                    isDisabled
                  ></Dropdown>
                </div>
                <div className="contact-info-item">
                  <p className="contact-info-label">{t("team")}</p>
                  <Dropdown
                    options={teams}
                    component={dealData?.team?.name}
                    onChange={(team) => {
                      setDealData({ ...dealData, team: team });
                      handleDropdownChange(team, "team");
                    }}
                    objSelect={true}
                    onSearch={(searchText) => { handleSearch(searchText, "Teams") }}
                    styles={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                  ></Dropdown>
                </div>
                <div className="contact-info-item">
                  <p className="contact-info-label">{t("stage")}</p>
                  <Dropdown
                    options={stageDeals}
                    component={dealData?.stage?.name}
                    onChange={(value) => {
                      setDealData({ ...dealData, stage: value });
                      handleDropdownChange(value, "stage");
                    }}
                    styles={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                    objSelect={true}
                  ></Dropdown>
                </div>
                <div className="contact-info-item">
                  <p className="contact-info-label">{t("expected_revenue")}</p>
                  {dataType === "revenue" ? (
                    <input type="text"
                      value={(Number(dealData.revenue) ? Number(dealData.revenue).toLocaleString() : dealData?.revenue)}
                      onChange={(e) => handleInputChange(e, "revenue")}
                      onBlur={(e) => {
                        setDataType("");
                        checkExpectedRevenue();
                      }}
                      ref={expectRevenueRef}
                    />
                  ) : (
                    <p onClick={() => setDataType("revenue")}>
                      {(Number(dealData.revenue) ? Number(dealData.revenue).toLocaleString() ?? 0 : dealData.revenue ?? 0) + ' ' + (dealData.currency ? dealData.currency.name : "")}
                    </p>
                  )}
                </div>
                <div className="contact-info-item">
                  <p className="contact-info-label">{t("actual_revenue")}</p>
                  <p >
                    {Number(dealData.actual_revenue).toLocaleString() + ' ' + (dealData.currency ? dealData.currency.name : "")}
                  </p>
                </div>
                <div
                  className="contact-info-item"
                  style={{ marginTop: "20px" }}
                ></div>
              </div>
            ) : null}
          </div>
          <div className="activities-container">
            <TabSwitcher loading={loading} deal={dealData} />
          </div>
          <div className="contact-point-block" style={{ fontSize: "14px" }}>
            <div className="contact-point">
              <div className="d-flex contact-point-header">
                <p className="bread-crumb">
                  <ArrowDownIconOutlined
                    style={{
                      marginRight: "0.2em",
                      color: "#5f89ba",
                    }}
                  ></ArrowDownIconOutlined>
                  {t('contact')}
                </p>
                <p
                  className="bread-crumb"
                  onClick={() => setIsOpenAddModal(true)}
                >
                  <span className="blue-text add-contact-point-button">
                    + {t("add")}
                  </span>
                </p>
              </div>
              <div className="contact-point-content">
                {
                  contacts && contacts.map((contact, key) =>
                    <div className="item-wrap info-contact" key={contact.id}>
                      <div className="d-grid">
                        <div className="d-flex">
                          <p className="blue-text bold" onClick={() => navigate(`/contact/${contact.id}`)}>{contact?.name ?? ""}</p>
                          {contacts.length === 1
                            ? <div></div>
                            : <Dropdown
                              options={contacts.length > 1 ? ["Delete"] : []}
                              styles={{
                                height: "10px",
                                marginLeft: "auto"
                              }}
                              arrowIcon={arrowIcon}
                              onDelete={() => setDetachRelation({
                                isOpen: true,
                                objectName: DEAL_RELATIONS.contact.name,
                                relationName: DEAL_RELATIONS.contact.relationName,
                                deleteIds: contact.id,
                                dealId: dealData.id
                              })}
                            >
                            </Dropdown>}
                        </div>
                        <div>
                          <p style={{ color: "#80868B", fontSize: "14px" }}>
                            {formatLabel(contact?.job_position ?? "")}
                          </p>
                          <p className="blue-text">{contact?.email ?? ""}</p>
                          <p className="blue-text">{contact?.phone ?? ""}</p>
                          <p className="blue-text">{contact?.address ?? ""}</p>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>

            <div className="contact-point" style={{ fontSize: "14px" }}>
              <div className="d-flex contact-point-header">
                <p className="bread-crumb">
                  <ArrowDownIconOutlined
                    style={{
                      marginRight: "0.2em",
                      color: "#5f89ba",
                    }}
                  ></ArrowDownIconOutlined>
                  {t('contact_point')}
                </p>
                <p
                  className="bread-crumb"
                  onClick={() => setIsOpenContactPointModal(true)}
                >
                  <span className="blue-text add-contact-point-button">
                    + {t("add")}
                  </span>
                </p>
              </div>
              <div className="contact-point-content">
                {contactPoints.length === 0 ? (
                  <>
                    <p>{t('title_no_contact_point')}</p>
                    <p style={{ color: "blue" }} onClick={onClickAddContactPoint}>{t('add_new_point')}</p>
                  </>
                ) : (
                  <>
                    {contactPoints && contactPoints.map((point, key) =>
                      <div className="item-wrap info-contact" key={point.id}>
                        <div className="d-grid">
                          <div className="d-flex">
                            <p className="blue-text bold" style={{ cursor: "default" }}>{formatLabel(point?.full_name ?? "")}</p>
                            <Dropdown
                              options={[t("edit"), t("delete")]}
                              styles={{ height: "10px", marginLeft: "auto" }}
                              editFrom={true}
                              arrowIcon={arrowIcon}
                              onChange={(value) => {
                                value === t("edit") &&
                                  handleEditContactPointClick(point);
                                // value === "Delete" &&
                                //   handleDeleteContactPointClick(
                                //     contactPoints[key]
                                //   );
                              }}
                              onDelete={() => setDetachRelation({
                                isOpen: true,
                                objectName: DEAL_RELATIONS.contactPoint.name,
                                relationName: DEAL_RELATIONS.contactPoint.relationName,
                                deleteIds: point.id,
                                dealId: dealData.id
                              })}
                            >
                            </Dropdown>
                          </div>
                          <div>
                            <p style={{ color: "#80868B", fontSize: "14px" }}>{formatLabel(point?.job_position ?? "")}</p>
                            <p className="blue-text">{point?.email ?? ""}</p>
                            <p className="blue-text">{point?.phone ?? ""}</p>
                            <p className="blue-text">{point?.address ?? ""}</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="contact-point">
              <div className="d-flex contact-point-header">
                <p className="bread-crumb">
                  <ArrowDownIconOutlined
                    style={{
                      marginRight: "0.2em",
                      color: "#5f89ba",
                    }}
                  ></ArrowDownIconOutlined>
                  {t('quotation')}
                </p>
                {tasks.length > 0 && (
                  <>
                    <Button
                      className="bread-crumb"
                      id="demo-positioned-button"
                      aria-controls={open ? 'demo-positioned-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(e) => handleClickAddQuotation(e, tasks)}
                    >
                      <span
                        className="blue-text add-contact-point-button"
                      >
                        + {t("add")}
                      </span>
                    </Button>
                    <Menu
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={() => setAnchorEl(null)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      {tasks.map((task) =>
                        <MenuItem onClick={() => handleCloseAddQuotation(task)}>{`#${task.id} - ${task.name}`}</MenuItem>
                      )}
                    </Menu>
                  </>
                )}

              </div>
              <div className="contact-point-content">
                {quotations && quotations.length > 0 &&
                  quotations.map((quotation, index) => (
                    <div className="item-wrap info-contact" key={index}>
                      <div className="d-grid">
                        <div className="d-flex">
                          <p className="blue-text bold" onClick={() => { navigate(`/quotation/edit/${quotation.id}`) }}>{quotation.name}</p>
                          <Dropdown
                            options={["Edit"]}
                            editFrom
                            styles={{
                              height: "10px",
                              marginLeft: "auto"
                            }}
                            arrowIcon={arrowIcon}
                            onChange={(value) => {
                              value === 'Edit' && navigate(`/quotation/edit/${quotation.id}`)
                            }}
                          >
                          </Dropdown>
                        </div>
                        <div>
                          <p>
                            {t('estimate_cost')}: <span className="blue-text">{formatNumber(quotation.estimation_cost)}{" " + quotation.currency.name}</span>
                          </p>
                          <p>
                            {t('status')}: <span className="blue-text">{quotation.status.name}</span>
                          </p>
                          <p>
                            {t('forecast_start_date')}: <span className="blue-text">{quotation.start_date ? moment(quotation.start_date).format('YYYY/MM/DD') : null}</span>
                          </p>
                          <p>
                            {t('forecast_end_date')}: <span className="blue-text">{quotation.end_date ? moment(quotation.end_date).format('YYYY/MM/DD') : null}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                }

              </div>
            </div>

            <div className="contact-point">
              <div className="d-flex contact-point-header">
                <p className="bread-crumb">
                  <ArrowDownIconOutlined
                    style={{
                      marginRight: "0.2em",
                      color: "#5f89ba",
                    }}
                  ></ArrowDownIconOutlined>
                  {t('order')}
                </p>
              </div>
              <div className="contact-point-content">
                {
                  orders && orders.map((order, key) =>
                    <div className="item-wrap info-contact" key={order.id}>
                      <div className="d-grid">
                        <div className="d-flex">
                          <p className="blue-text bold" onClick={() => {
                            navigate(`/orders/edit/${order.id}`, { state: { contacts: contacts?.map(item => ({ value: item.id, label: item.name })), deals: { value: dealData.id, label: dealData.name }, currency: { value: dealData.unit?.currency?.id, label: dealData.unit?.currency?.name } } });
                            sessionStorage.setItem("from_detail_deal", `deal/${id}`);
                          }}>{order?.name ?? ""}</p>
                          <Dropdown
                            options={["Edit"]}
                            styles={{
                              height: "10px",
                              marginLeft: "auto"
                            }}
                            editFrom={order.can_edit_contact_deal}
                            arrowIcon={arrowIcon}
                            onChange={(value) => {
                              if (value === "Edit") {
                                navigate(`/orders/edit/${order.id}`, { state: { contacts: contacts?.map(item => ({ value: item.id, label: item.name })), deals: { value: dealData.id, label: dealData.name }, currency: { value: dealData.unit?.currency?.id, label: dealData.unit?.currency?.name } } });
                                sessionStorage.setItem("from_detail_deal", `deal/${id}`);
                              }
                              // if (value === "Delete") {
                              //   setIsOpenModalDelete(true);
                              //   setOrderId(order.id);
                              // }
                            }}
                          >
                          </Dropdown>
                        </div>
                        <div>
                          <p>
                            {t('total_price')}: <span className="blue-text">{Number(order.total).toLocaleString() + " " + (dealData.currency ? dealData.currency.name : "") ?? ""}</span>
                          </p>
                          <p>
                            {t('status')}: <span className="blue-text">{order.status.name ?? ""}</span>
                          </p>
                          <p>
                            {t('expected_start_date')}: <span className="blue-text">{moment(order.start_date).format("YYYY/MM/DD") ?? ""}</span>
                          </p>
                          <p>
                            {t('expected_end_date')}: <span className="blue-text">{moment(order.end_date).format("YYYY/MM/DD") ?? ""}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          title="Note"
          isOpen={isOpenCreateModal}
          onClose={() => setIsOpenCreateModal(false)}
        />
        <PointModal
          title={t("add_contact")}
          isOpen={isOpenAddModal}
          detailType={true}
          type={"contact"}
          onClose={() => setIsOpenAddModal(false)}
          isCreate={false}
          onReload={reloadContactList}
          isEdit={false}
          dealId={id}
        />
        <PointModal
          title={t("add_contact_point")}
          isOpen={isOpenContactPointModal}
          detailType={true}
          onClose={() => setIsOpenContactPointModal(false)}
          isCreate={false}
          onReload={reloadContactPointList}
          isEdit={false}
          dealId={id}
        />
        <PointModal
          title={t("edit_contact_point")}
          isOpen={isOpenEditModal}
          onClose={() => setIsOpenEditModal(false)}
          fields={EDIT_CONTACT_POINT_FIELD}
          isCreate={false}
          onReload={reloadContactPointUpdateList}
          isEdit={true}
          contactId={id}
          contactPoint={selectedContactPointEdit}
        />

        {changedItemsCount > 0 && (
          <div
            className="d-flex"
            style={{
              backgroundColor: "#F6F7FA",
              height: "75px",
              position: "absolute",
              borderTop: "solid 0.8px #5F89BA",
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1,
            }}
          >
            <button
              className="button btn-create"
              onClick={() => {
                handleEditDeal()
              }}
            >
              Save
            </button>
            <button
              className="button btn-cancel"
              onClick={() => {
                setChangedItemsCount(0);
                setDealData(selectedData)
                setErrors({});
              }}
            >
              Cancel
            </button>
            <p
              className="font-bold"
              style={{ marginLeft: "15px", marginTop: "25px" }}
            >
              {changedItemsCount} item(s) was/were changed
            </p>
          </div>
        )}
        {toast.show && (
          <Toast
            message={toast.message}
            duration={3000}
            error={toast.error}
            onClose={() => {
              setToast({ show: false });
            }}
          />
        )}
        <ConfirmDetachRelationModal
          setLoading={(status) => setLoading(!!status)}
          isOpen={detachRelation.isOpen}
          values={detachRelation}
          onClose={() => setDetachRelation({ ...detachRelationInit })}
          onSuccess={(relationName, deleteIds, unit, message) => onDetachRelationSuccess(relationName, deleteIds, unit, message)}
          onError={(error) => setToast({
            show: true,
            error: true,
            message: error,
          })}
        />
        <ModalCenter
          isOpen={isOpenConfirmModal}
          title={"Confirmation"}
          onClose={() => setIsOpenConfirmModal(false)}
          useConfirm={true}
          onConfirm={({ contactsId }) => {
            navigate(`/contact/${contactsId.value}`, { state: { deal: dealData } });
          }}
          deal={dealData}
        />
        {isOpenModalDelete && (
          <ModalDelete
            isOpen={isOpenModalDelete}
            title={'Confirmation Delete Orders'}
            onClose={() => {
              setIsOpenModalDelete(false);
            }}
            name={"order"}
            useConfirm={true}
            type={'filter'}
            onConfirm={() => handleDeleteOrder(orderId)}
          />
        )}
      </div>
    </>
  );
}

export default DealDetail;